import React from "react";
import "./MembershipDetails.scss";


const FreeSubscriptionCard = ({ isNational }) => {
  return (
    <div id="member-container">
      <div className="subscription-card">
        <div className="">
          <div className="d-flex justify-content-between cpe-24 cps-24 cmt-24">
            <div>Free</div>
            <div className="text-34-400">{isNational ? "₹ 0" : "$ 0"}</div>
          </div>

          <p className="cmt-56 cps-24 cpe-24 text-14-500">
            If you’re looking for more access then buy this plan
          </p>
        </div>
      </div>
    </div>
  );
};

export default FreeSubscriptionCard;
