import Modal from 'components/Layout/Modal';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchResearchProfileData,
  GenreteResume,
  setIsProfileAIPopupOpen,
} from 'store/slices';
import './ProfileCV.scss';

import LinkCVForm from './LinkCVForm';
import LinkCVPreview from './LinkCVPreview';
import { getDataFromLocalStorage, objectToFormData } from 'utils/helpers';

const LinkProfileCVGeneraterModal = () => {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.global);
  const { isProfileAIPopupOpen } = reduxData || {};
  const [isPageLoading, setIsPageLoading] = useState(false);
  let userID = getDataFromLocalStorage('id');

  const { userDetails, researchProfile, resumeList } = useSelector((state) => ({
    userDetails: state.student.userDetails,
    rProfileData: state.global.rProfileData,
    researchProfile: state.student.researchProfile,
    resumeList: state.global.resumeList || [],
  }));
  // console.log('resumeList', resumeList);

  const fetchDetails = async () => {
    setIsPageLoading(true);
    await dispatch(fetchResearchProfileData(`user_id=${userID}`));
    setIsPageLoading(false);
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [btnGeLoading, setBtnGeLoading] = useState(false);
  
  const handelGenreteResume = async () => {
    setBtnGeLoading(true);
    const payload = {
      id: userID,
    };
    const res = await dispatch(GenreteResume(objectToFormData(payload)));
    if (res?.status === 400) {
      setBtnGeLoading(false);
    }
    setBtnGeLoading(false);
  };

  return (
    <Row className="ms-0 me-0 ps-0 pe-0">
      <Col xl={6} className="ps-0 pe-0">
        <LinkCVForm
          handelCv={handelGenreteResume}
          loadingCv={btnGeLoading}
          list={resumeList}
        />
      </Col>
      <Col xl={6} className="ps-0 pe-0">
        <LinkCVPreview
          researchProfile={researchProfile}
          userDetails={userDetails}
          list={resumeList}
        />
      </Col>
    </Row>
  );
};

export default LinkProfileCVGeneraterModal;
