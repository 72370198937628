import Card from "components/Layout/Card";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AllMentors from "./AllMentors";
import AllMentees from "./AllMentees";
import Payment from "./Payment";
import Approvals from "./Approvals/Approvals";
import Button from "components/form/Button";
import PlatformFeeDetailsPopup from "./Approvals/Approvals/PlatformFeeDetailsPopup";
import { getDataFromLocalStorage, getUserType } from "utils/helpers";
import CVDownloads from "./CVDownloads";
import "./MentorshipManagement.scss";
import { useSelector } from "react-redux";

const MentorshipManagement = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const [isFeeDetailsPopup, setIsFeeDetailsPopup] = useState(false);
  const { type } = params;
  const activeClass = "p-2 bg-new-car color-white text-15-400";
  const inActiveClass = "p-2 color-dark-blue text-15-400 pointer";
  const userData = getDataFromLocalStorage();
  const {
    user_type,
    mentor_approvals_panding = 0,
    session_approvals_panding = 0,
    resource_role,
  } = userData;

  const totalPending = mentor_approvals_panding + session_approvals_panding;
  const isShowCount = user_type === "6" && resource_role === "1";

  const { update: updateAccess } =
    userDetails?.role_data?.find(
      (item) => item?.permission_name === "Mentorship Management"
    )?.sub_permission || {};

  const userType = getUserType();

  return (
    <div id="mentorship-management-component">
      <Card className="d-flex align-items-center justify-content-between p-1 unset-br mb-3 flex-wrap">
        <div className="d-flex align-items-center flex-wrap gap-2">
          <div
            className={type === "all-mentors" ? activeClass : inActiveClass}
            onClick={() => {
              navigate(`/${userType}/mentorship-management/all-mentors`);
            }}
          >
            All Mentors
          </div>
          <div
            className={type === "all-mentees" ? activeClass : inActiveClass}
            onClick={() => {
              navigate(`/${userType}/mentorship-management/all-mentees`);
            }}
          >
            All Mentees
          </div>
          {["0", "1"].includes(user_type) && (
            <div
              className={type === "payment" ? activeClass : inActiveClass}
              onClick={() => {
                navigate(`/${userType}/mentorship-management/payment`);
              }}
            >
              Payment
            </div>
          )}
          <div
            className={`${
              type === "approval" ? activeClass : inActiveClass
            } position-relative`}
            onClick={() => {
              navigate(`/${userType}/mentorship-management/approval`);
            }}
          >
            Approvals
            {isShowCount && totalPending !== 0 && type !== "approval" && (
              <span className="pending-count">{totalPending}</span>
            )}
          </div>
          {["0", "1"].includes(user_type) && (
            <div
              className={type === "cv-downloads" ? activeClass : inActiveClass}
              onClick={() => {
                navigate(`/${userType}/mentorship-management/cv-downloads`);
              }}
            >
              CV Downloads
            </div>
          )}
        </div>
        {["0", "1"].includes(user_type) && updateAccess === "1" && (
          <Button
            btnStyle=" mw-115 h-45 color-dark-navy-blue light-blue-outline text-nowrap"
            text="Update Platform Fee"
            onClick={() => {
              setIsFeeDetailsPopup(true);
            }}
          />
        )}
      </Card>
      {type === "all-mentors" && <AllMentors />}
      {type === "all-mentees" && <AllMentees />}
      {type === "payment" && <Payment />}
      {type === "approval" && <Approvals />}
      {type === "cv-downloads" && <CVDownloads />}
      {isFeeDetailsPopup && (
        <PlatformFeeDetailsPopup setIsFeeDetailsPopup={setIsFeeDetailsPopup} />
      )}
    </div>
  );
};

export default MentorshipManagement;
