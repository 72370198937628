import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import DateTimePicker from "components/form/DateTimePicker";
import TextArea from "components/form/TextArea";
import TextInput from "components/form/TextInput";
import React from "react";
// Keynote speaker, session speaker notification
const FifthStepOfFollowUps = ({ props, isEdit }) => {
  const { handleChange, values, setFieldValue } = props;
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    featured_speakers,
    register_webinar,
    // pre_conference_speaker_name,
    // date_time,
    // view_speaker_profile,
    // register_conference,
    // your_name,
    // your_title,
    // your_contact_information,
    // your_website,
  } = values;

  const addSpeaker = () => {
    setFieldValue("featured_speakers", [
      ...featured_speakers,
      {
        speaker_name: "",
        speaker_credentials: "",
        speaker_achievements: "",
        speaker_sessionTopic: "",
      },
    ]);
  };

  // Function to remove a speaker by index
  const removeSpeaker = (index) => {
    const newSpeakers = featured_speakers?.filter((_, i) => i !== index);
    setFieldValue("featured_speakers", newSpeakers);
  };
  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
            disabled={!isEdit}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
            disabled={!isEdit}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="conference_date"
            onChange={handleChange}
            value={conference_date}
            disabled={!isEdit}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Venue
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_location"
            onChange={handleChange}
            value={conference_location}
            disabled={!isEdit}
          />
        </div>
      </div>

      <div className="speakers-container">
        <div className="d-flex align-items-center gap-3">
          <h5>Speakers</h5>
          <div className="d-flex">
            <Button
              isSquare
              text="+ Add Another"
              btnStyle="primary-light"
              className={"h-35 "}
              onClick={addSpeaker}
              disabled={!isEdit}
            />
          </div>
        </div>
        {featured_speakers?.map((speaker, index) => (
          <div key={index} className="speaker-fields">
            <div className="d-flex align-items-center flex-wrap cmb-19">
              <div className="color-black-olive text-14-400-17 col-lg-6">
                Speaker Name {index + 1}
              </div>
              <div className="col-lg-6">
                <TextInput
                  id={`featured_speakers[${index}].speaker_name`}
                  onChange={handleChange}
                  value={speaker.speaker_name}
                  disabled={!isEdit}
                />
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap cmb-19">
              <div className="color-black-olive text-14-400-17 col-lg-6">
                Speaker Credentials {index + 1}
              </div>
              <div className="col-lg-6">
                <TextInput
                  id={`featured_speakers[${index}].speaker_credentials`}
                  onChange={handleChange}
                  value={speaker.speaker_credentials}
                  disabled={!isEdit}
                />
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap cmb-19">
              <div className="color-black-olive text-14-400-17 col-lg-6">
                Speaker Achievements {index + 1}
              </div>
              <div className="col-lg-6">
                <TextArea
                  id={`featured_speakers[${index}].speaker_achievements`}
                  onChange={handleChange}
                  value={speaker.speaker_achievements}
                  disabled={!isEdit}
                />
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap cmb-19">
              <div className="color-black-olive text-14-400-17 col-lg-6">
                Speaker Session Topic {index + 1}
              </div>
              <div className="col-lg-6">
                <TextInput
                  id={`featured_speakers[${index}].speaker_sessionTopic`}
                  onChange={handleChange}
                  value={speaker.speaker_sessionTopic}
                  disabled={!isEdit}
                />
              </div>
            </div>
            {featured_speakers?.length > 1 &&
              featured_speakers?.length - 1 === index && (
                <div className="d-flex">
                  <Button
                    isSquare
                    text="Delete"
                    btnStyle="primary-gray"
                    icon={<i className="bi bi-trash me-2" />}
                    className="cpt-5 cpb-5 cps-10 cpe-10 h-35"
                    onClick={() => {
                      removeSpeaker(index);
                    }}
                    disabled={!isEdit}
                  />
                </div>
              )}
          </div>
        ))}
      </div>

      {/* <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Webinar Speaker
        </div>
        <div className="col-lg-6">
          <TextInput
            id="pre_conference_speaker_name"
            onChange={handleChange}
            value={pre_conference_speaker_name}
            disabled={!isEdit}
          />
        </div>
      </div> */}
      {/* <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Webinar Date and Time
        </div>
        <div className="col-lg-6">
          <DateTimePicker
            id="date_time"
            onChange={handleChange}
            value={date_time}
            disabled={!isEdit}
          />
        </div>
      </div> */}

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Register for the Webinar
        </div>
        <div className="col-lg-6">
          <TextInput
            id="register_webinar"
            onChange={handleChange}
            value={register_webinar}
            disabled={!isEdit}
          />
        </div>
      </div>
      {/* <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          View Speaker Profiles
        </div>
        <div className="col-lg-6">
          <TextInput
            id="view_speaker_profile"
            onChange={handleChange}
            value={view_speaker_profile}
            disabled={!isEdit}
          />
        </div>
      </div> */}
      {/* <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Register for the Conference
        </div>
        <div className="col-lg-6">
          <TextInput
            id="register_conference"
            onChange={handleChange}
            value={register_conference}
            disabled={!isEdit}
          />
        </div>
      </div> */}

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_name"
            onChange={handleChange}
            value={your_name}
            disabled={!isEdit}
          />
        </div>
      </div> */}
      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_title"
            onChange={handleChange}
            value={your_title}
            disabled={!isEdit}
          />
        </div>
      </div> */}

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
            disabled={!isEdit}
          />
        </div>
      </div> */}

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
            disabled={!isEdit}
          />
        </div>
      </div> */}
    </>
  );
};

export default FifthStepOfFollowUps;
