import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { throwError } from "store/slices";
import { getDataFromLocalStorage } from "utils/helpers";
// import "./FileUpload.scss";

const FileUploadInput = ({
  error,
  onChange,
  id,
  fileText,
  fileType,
  disabled,
  label,
  isRequired,
  labelClass,
  acceptType,
  text,
}) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");

  const getBase64 = (file, res) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      res(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handelOnChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      let fileName = file["name"];
      let fileType = file["type"]?.split("/")?.pop();
      let isVideo = ["mp4"].includes(fileType);
      let maxVideoSize =
        getDataFromLocalStorage("user_type") === "0" ? 100000000 : 30000000;
      let maxFileSize = 10000000;
      let isInvalidaFileSize = isVideo
        ? file?.size > maxVideoSize
        : file?.size > maxFileSize;
      if (acceptType) {
        if (!acceptType.includes(fileType)) {
          dispatch(
            throwError({
              message: "Invalid file selected",
            })
          );
          onChange({
            target: { id: id, value: "", fileName: "" },
          });
          return;
        }
      }
      if (isInvalidaFileSize) {
        dispatch(
          throwError({
            message: "File size is too large.",
          })
        );
        onChange({
          target: { id: id, value: "", fileName: "" },
        });
        return;
      }
      getBase64(file, (result) => {
        setFileName(fileName);
        onChange({
          target: { id: id, value: result, fileName: fileName, file: file },
        });
      });
    }
  };
  useEffect(() => {
    setFileName(fileText);
  }, [fileText]);

  const acceptFileType = (fileType) => {
    let returnValue = "";
    switch (fileType) {
      case "image":
        returnValue = "image/png, image/jpeg, image/jpg";
        break;
      case "file":
        returnValue = "";
        break;
      case "all":
        returnValue = "";
        break;

      default:
        returnValue = "";
        break;
    }
    return returnValue;
  };
  const inputFileType = acceptFileType(fileType || "");
  return (
    
    <input
      id={id}
      name="Select File"
      type="file"
      onChange={handelOnChange}
      accept={inputFileType}
      style={{ display: "none" }}
    />
    
  );
};
export default FileUploadInput;
