import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import Card from "components/Layout/Card";
import DeletePopup from "components/Layout/DeletePopup";
import ExportButton from "components/Layout/ExportButton";
import FilterDropdown from "components/Layout/FilterDropdown";
import Table from "components/Layout/Table";
import moment from "moment";
import AddMember from "pages/Common/ProfileManagement/AddMember";
import ProfileDetail from "pages/Common/ProfileManagement/ProfileDetail";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteMembers,
  exportUserReports,
  getAllUserTypeReportData,
  setIsEditDataOfProfile,
  setIsEditUserProfile,
} from "store/slices";
import { icons, membershipType } from "utils/constants";
import {
  getDataFromLocalStorage,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import ReplayMessageBlock from "./ReplayMessageBlock";

const expireWithinOptions = [
  {
    id: 1,
    label: "Within 1 Week",
    name: "one_week",
  },
  {
    id: 2,
    label: "Within 1 Month",
    name: "one_month",
  },
  {
    id: 3,
    label: "Within 3 Month",
    name: "three_month",
  },
  {
    id: 4,
    label: "Above 3 Month",
    name: "above_three_months",
  },
];
const expiredOptions = [
  {
    id: 1,
    label: "Last 1 Week",
    name: "one_week",
  },
  {
    id: 2,
    label: "Last 1 Month",
    name: "one_month",
  },
  {
    id: 3,
    label: "Last 3 Month",
    name: "three_month",
  },
  {
    id: 4,
    label: "Above 3 Month",
    name: "above_three_months",
  },
];

const getClassNames = (tabType, expireWithin) => {
  if (tabType === "0") {
    if (expireWithin?.includes("Week")) {
      return "bg-4646 color-white";
    } else if (["1 Month", "2 Month"]?.includes(expireWithin)) {
      return "bg-9500 color-white";
    } else if (!["1 Month", "2 Month"]?.includes(expireWithin)) {
      return "bg-d1d1 color-black";
    } else {
      return "";
    }
  } else {
    if (expireWithin?.includes("Week")) {
      return "bg-9500 color-white";
    } else {
      return "bg-4646 color-white";
    }
  }
};

const getDisplayText = (displayValue, expiryTab) => {
  if (!displayValue) return "";

  if (displayValue === "Above 3months") {
    return displayValue;
  }

  if (displayValue === "0 Week") {
    return "This Week";
  }

  const prefix = expiryTab === "0" ? "Within" : "Last";
  return `${prefix} ${displayValue}`;
};

const CommonData = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));

  const { type } = params;
  const { membershipList } = useSelector((state) => ({
    membershipList: state.global.membershipList,
  }));
  const getId = (pType) => {
    switch (pType) {
      case "student":
        return "5";
      case "professional":
        return "2";
      case "institutional":
        return "3";
      default:
        return null;
    }
  };
  let newFilterList = membershipList?.filter(
    (o) => o?.membership_id === getId(type)
  );

  let newFilterListForExpiry = membershipList?.filter(
    (o) =>
      o?.membership_id !== "4" &&
      o?.name !== "Professional-Free" &&
      o?.name !== "Student-Free"
  );

  const userType = getDataFromLocalStorage("user_type");
  const [expiryTab, setExpiryTab] = useState("0");
  const [isDeatils, setDeatils] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userid, setUserid] = useState(null);
  const [isAddMember, setAddMember] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [searchPayload, setSearchPayload] = useState({
    name: "",
    membership_id_or_type: "",
    email: "",
    phoneno: "",
    join_date: "",
    valid_date: "",
    country: "",
    expire_within: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 20,
    membership_plan_ids: "",
    is_non_allocated_resource: "",
    user_type: membershipType.find((o) => o.type === type)?.id,
    expire_tab: "go_to_expire",
  });
  const [filterDates, setFilterDates] = useState({
    start_date: "",
    end_date: "",
  });

  let newDates = {
    start_date: searchPayload?.start_date
      ? searchPayload?.start_date
      : "" || filterDates?.start_date
      ? moment(filterDates?.start_date).format("YYYY-MM-DD")
      : "",
    end_date: searchPayload?.end_date
      ? searchPayload?.end_date
      : "" || filterDates?.end_date
      ? moment(filterDates?.end_date).format("YYYY-MM-DD")
      : "",
  };

  const getProfiles = async (obj) => {
    let forData = "";
    let modifiedObj = { ...obj };

    if (
      obj?.join_date !== "custom" &&
      obj?.valid_date !== "custom" &&
      obj?.start_date &&
      obj?.end_date
    ) {
      delete modifiedObj.start_date;
      delete modifiedObj.end_date;
      forData = objectToFormData(modifiedObj);
    } else {
      forData = objectToFormData(obj);
    }

    const response = await dispatch(getAllUserTypeReportData(forData));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.data) {
      resList = response?.data?.data || [];
      resResultCount = response?.data?.result_count || 0;
    }
    setTableList(resList);
    setFilterData({
      ...obj,
      total: resResultCount,
    });
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  const handelChangeFilter = (val) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, membership_plan_ids: val, offset: 0 };
    setFilterData(newData);
    getProfiles(newData);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getProfiles(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getProfiles(newData);
  };

  useEffect(() => {
    // // reset filter and pagination
    setSearchPayload({
      name: "",
      membership_id_or_type: "",
      email: "",
      phoneno: "",
      join_date: "",
      valid_date: "",
      country: "",
      expire_within: "",
    });

    setFilterData({
      total: 0,
      offset: 0,
      limit: 20,
      membership_plan_ids: "",
      is_non_allocated_resource: "",
      user_type: membershipType.find((o) => o.type === type)?.id,
      expire_tab: expiryTab === "0" ? "go_to_expire" : "expired",
    });
    if (["0", "1", "6"].includes(userType)) {
      let updatedFilterData = { ...filterData, ...searchPayload };
      if (type !== "expiry") {
        delete updatedFilterData?.expire_tab;
        delete updatedFilterData?.expire_within;
      } else {
        delete updatedFilterData?.user_type;
        updatedFilterData.expire_tab =
          expiryTab === "0" ? "go_to_expire" : "expired";
        updatedFilterData.offset = 0;
        updatedFilterData.expire_within = "";
      }
      getProfiles({ ...updatedFilterData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, expiryTab]);

  const {
    create: createAccess,
    view: viewAccess,
    update: updateAccess,
    delete: deleteAccess,
  } = userDetails?.role_data?.find(
    (item) => item?.permission_name === "Reports"
  )?.sub_permission || {};

  const header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "name",
      title: "Name",
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: type === "expiry" ? newFilterListForExpiry : newFilterList,
        key: "name",
        value: "name",
      },
      searchInputName: "membership_id_or_type",
      title: (
        <>
          <div>{"Membership ID"}</div>
          <div>{"& Type"}</div>
        </>
      ),
    },
    {
      isSearch: true,
      searchInputName: "email",
      title: "Email ID",
    },
    {
      isSearch: true,
      searchInputName: "phoneno",
      title: "Phone Number",
    },
    {
      isSearch: true,
      searchInputName: "country",
      title: "Country",
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "join_date",
      title: "Join Date",
      isProfileManagement: true,
    },
    type === "expiry"
      ? {
          isSearch: true,
          isSearchDropdown: true,
          searchInputName: "expire_within",
          dropdownOptions: {
            options: expiryTab === "0" ? expireWithinOptions : expiredOptions,
            key: "name",
            value: "label",
          },
          title: (
            <span className="text-nowrap">
              {expiryTab === "0" ? "Expire Within" : "Expired"}
            </span>
          ),
        }
      : {
          isSearch: true,
          isDatePicker: true,
          searchInputName: "valid_date",
          title: "Valid Till",
          isProfileManagement: true,
        },
    {
      isSearch: false,
      searchLable: type === "expiry" ? "View/Comment" : "Edit/Delete",
      title: "Action",
    },
  ];

  const rowData = [];
  tableList?.forEach((elem, index) => {
    const {
      id,
      email,
      phone,
      memberId,
      memberType,
      join_date,
      valid_till,
      firstName,
      lastName,
      country,
      expire_within,
      expired,
      comment,
    } = elem;
    let isExpired = valid_till && moment().diff(valid_till, "days") > 0;
    const displayValue = expiryTab === "0" ? expire_within : expired;

    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span
            className="color-new-car pointer text-nowrap"
            onClick={() => {
              setDeatils(true);
              setUserDetail(id);
            }}
          >
            {`${firstName} ${lastName}`}
          </span>
        ),
      },
      {
        value: (
          <span>
            {memberId && <div>{memberId}</div>}
            <div>{memberType}</div>
          </span>
        ),
      },
      {
        value: (
          <div
            style={{
              width: "250px",
              wordBreak: "break-word",
            }}
          >
            {email}
          </div>
        ),
      },
      {
        value: phone,
      },
      {
        value: country || "",
      },
      {
        value: moment(join_date).format("DD.MM.YYYY"),
      },
      type === "expiry"
        ? {
            value: (
              <span
                className={`text-nowrap p-2 br-4 ${
                  displayValue ? getClassNames(expiryTab, displayValue) : ""
                }`}
              >
                {displayValue ? getDisplayText(displayValue, expiryTab) : ""}
              </span>
            ),
          }
        : {
            value: isExpired
              ? "Expired"
              : valid_till
              ? moment(valid_till).format("DD.MM.YYYY")
              : "",
          },
      {
        value: (
          <span className="action-icon-buttons">
            {type === "expiry" ? (
              <>
                {viewAccess === "1" && (
                  <ReplayMessageBlock
                    icon={<i className="bi bi-eye" />}
                    comment={comment}
                    viewType="view"
                    elem={elem}
                    handelSuccess={() => {
                      getProfiles({ ...filterData, ...searchPayload });
                    }}
                  />
                )}

                {createAccess === "1" && (
                  <ReplayMessageBlock
                    showMessageIndicator
                    icon={
                      <i className="bi bi-chat-right-text position-relative" />
                    }
                    viewType="message"
                    elem={elem}
                    handelSuccess={() => {
                      getProfiles({ ...filterData, ...searchPayload });
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {updateAccess === "1" && (
                  <Button
                    btnStyle="light-outline"
                    icon={<img src={icons.edit} alt="edit" />}
                    className="me-2"
                    onClick={() => {
                      setEditData(elem);
                      // setAddMember(true);
                      dispatch(setIsEditUserProfile(true));
                      dispatch(setIsEditDataOfProfile(elem));
                    }}
                    isSquare
                  />
                )}
                {["0", "1","6"].includes(userType) && deleteAccess === "1" && (
                  <Button
                    btnStyle="light-outline"
                    icon={<img src={icons.deleteIcon} alt="delete" />}
                    onClick={() => {
                      setUserid(id);
                    }}
                    isSquare
                  />
                )}
              </>
            )}
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  const activeClass = "p-3 bg-new-car color-white text-16-500";
  const inActiveClass = "p-3 color-dark-blue text-16-500 pointer";

  return (
    <>
      {userid && (
        <DeletePopup
          id={userid}
          onHide={() => {
            setUserid(null);
          }}
          handelSuccess={() => {
            setUserid(null);
            getProfiles({ ...filterData, ...searchPayload });
          }}
          handelDelete={async () => {
            let forData = objectToFormData({ userid: userid });
            const response = await dispatch(deleteMembers(forData));
            return response;
          }}
        />
      )}
      {isAddMember && (
        <AddMember
          editData={editData}
          onHide={() => {
            setAddMember(false);
            setEditData(null);
          }}
          handelSuccess={() => {
            setAddMember(false);
            getProfiles({
              ...filterData,
              ...searchPayload,
            });
          }}
        />
      )}
      {isDeatils && (
        <ProfileDetail
          userID={userDetail}
          onHide={() => {
            setDeatils(false);
          }}
        />
      )}

      {type === "expiry" && (
        <div className="mb-3">
          <Card className="">
            <div className="d-flex align-items-center flex-wrap gap-2">
              <div
                className={expiryTab === "0" ? activeClass : inActiveClass}
                onClick={() => {
                  setExpiryTab("0");
                }}
              >
                Going to Expiry
              </div>
              <div
                className={expiryTab === "1" ? activeClass : inActiveClass}
                onClick={() => {
                  setExpiryTab("1");
                }}
              >
                Expired
              </div>
            </div>
          </Card>
        </div>
      )}
      <Card className="cps-20 cpe-20 cpb-20">
        <div
          className={`justify-content-between cpt-28 d-flex align-items-center flex-wrap`}
        >
          {type === "expiry" ? (
            <div className="table-title">
              {`All ${titleCaseString(
                expiryTab === "0" ? "Going to Expiry" : "Expired"
              )}`}{" "}
              ({filterData?.total})
            </div>
          ) : (
            <div className="table-title">
              {`All ${titleCaseString(type)}`} ({filterData?.total})
            </div>
          )}

          <div className="d-flex flex-wrap gap-3 cmt-28 cpb-28">
            {userType === "0" && type !== "expiry" && (
              <div className="text-14-400 grey-border d-flex align-items-center gap-2 ps-2 pe-2">
                <CheckBox
                  type="PRIMARY-ACTIVE"
                  onClick={() => {
                    setIsLoading(true);
                    let newData = { ...filterData, ...searchPayload };
                    newData = {
                      ...newData,
                      is_non_allocated_resource:
                        filterData.is_non_allocated_resource ? "" : "1",
                    };
                    setFilterData(newData);
                    getProfiles(newData);
                  }}
                  isChecked={filterData.is_non_allocated_resource === "1"}
                />
                <div>Not Allocated Resource</div>
              </div>
            )}
            {viewAccess === "1" && (
              <ExportButton
                exportAPI={exportUserReports}
                payload={objectToFormData({
                  ...filterData,
                  ...searchPayload,
                  ...newDates,
                })}
                isFilter={
                  !(
                    (searchPayload?.start_date && searchPayload?.end_date) ||
                    searchPayload?.join_date ||
                    searchPayload?.valid_date
                  )
                }
                setFilterDates={setFilterDates}
                filterDates={filterDates}
              />
            )}
            <div>
              <FilterDropdown
                list={
                  type === "expiry" ? newFilterListForExpiry : newFilterList
                }
                handelChangeFilter={handelChangeFilter}
                isApplyButton
              />
            </div>
          </div>
        </div>
        <div className="w-100 overflow-auto">
          <Table
            isLoading={isLoading}
            header={header}
            rowData={rowData}
            filterData={filterData}
            searchPayload={searchPayload}
            searchInputChange={handelChangeSearch}
            changeOffset={handelChangePagination}
            isOtherPagination
          />
        </div>
      </Card>
    </>
  );
};

export default CommonData;
