import dashboard from 'assets/icons/logo/dashboard.png';
import activePlan from 'assets/icons/logo/active-plan.png';
import careerSupport from 'assets/icons/logo/career-support.png';
import certificate from 'assets/icons/logo/certificate.png';
import chapteres from 'assets/icons/logo/chapteres.png';
import collaboration from 'assets/icons/logo/collaboration.png';
import conferenceEvents from 'assets/icons/logo/conference-events.png';
import fund from 'assets/icons/logo/fund.png';
import help from 'assets/icons/logo/help.png';
import inbox from 'assets/icons/logo/inbox.png';
import innovation from 'assets/icons/logo/innovation.png';
import network from 'assets/icons/logo/network.png';
import nominateAward from 'assets/icons/logo/nominate-award.png';
import ourAcademies from 'assets/icons/logo/our-academies.png';
import ourProfile from 'assets/icons/logo/our-profile.png';
import corporateProfile from 'assets/icons/logo/corporateProfile.png';
import publication from 'assets/icons/logo/publication.png';
import search from 'assets/icons/logo/search.png';
import searchGray from 'assets/icons/logo/searchGray.svg';
import notification from 'assets/icons/logo/notification.png';
import calendar from 'assets/icons/logo/calendar.png';
import profile from 'assets/icons/logo/profile-management.png';
import award from 'assets/icons/logo/award-management.png';
import resource from 'assets/icons/logo/resource-management.png';
import career from 'assets/icons/logo/career-management.png';
import institutionalHelp from 'assets/icons/logo/institutionalHelp.png';
import studentMember from 'assets/icons/logo/student-member.png';
import management from 'assets/icons/logo/management.png';
import institute from 'assets/icons/logo/institute.png';
import exportIcon from 'assets/icons/logo/export.png';
import filter from 'assets/icons/logo/filter.png';
import edit from 'assets/icons/logo/edit.png';
import deleteIcon from 'assets/icons/logo/delete.png';
import leftArrow from 'assets/icons/logo/left-arrow.png';
import close from 'assets/icons/logo/close.png';
import imageUpload from 'assets/icons/logo/image-upload.png';
import branding from 'assets/icons/logo/branding.png';
import nomineeAward from 'assets/icons/logo/nomineeAward.png';
import digitalLibrary from 'assets/icons/logo/digitalLibrary.png';
import userHelp from 'assets/icons/logo/userHelp.png';
import iferpConference from 'assets/icons/logo/iferp-conference.png';
import iferpWebinar from 'assets/icons/logo/iferp-webinar.png';
import iferpNewspaper from 'assets/icons/logo/iferp-newspaper.png';
import iferpGroup from 'assets/icons/logo/iferp-group.png';
import community from 'assets/icons/logo/community.png';
import biInfoCircle from 'assets/icons/logo/biInfoCircle.png';
import logo from 'assets/icons/logo.svg';
import roundLogo from 'assets/icons/round-logo.svg';
import logout from 'assets/icons/logo/logout.png';
import goldCrown from 'assets/icons/logo/gold-crown.svg';
import paperSubmitted from 'assets/icons/logo/paperSubmitted.svg';
import paperCheck from 'assets/icons/logo/paperCheck.svg';
import paperReview from 'assets/icons/logo/paperReview.svg';
import paperAccepted from 'assets/icons/logo/paperAccepted.svg';
import paperRegister from 'assets/icons/logo/paperRegister.svg';
import jpgType from 'assets/icons/logo/jpgType.svg';
import seen from 'assets/icons/logo/seen.svg';
import time from 'assets/icons/logo/time.svg';
import lock from 'assets/icons/logo/lock.svg';
import greenCheckMark from 'assets/icons/logo/greenCheckMark.svg';
import noImage from 'assets/icons/noImage.jpg';
import unauthorized from 'assets/icons/unauthorized1.jpg';
import pdf from 'assets/icons/logo/pdf.svg';
import excel from 'assets/icons/logo/excel.svg';
import jpg from 'assets/icons/logo/jpg.svg';
import activeDetails from 'assets/icons/active-details.svg';
import rightLabel from 'assets/icons/logo/rightLabel.svg';
import checkCircle from 'assets/icons/logo/checkCircle.svg';
import lightSave from 'assets/icons/logo/lightSave.svg';
import userUashboard from 'assets/icons/user-dashboard.png';
import qrCode from 'assets/icons/temp/qrCode.png';
import save from 'assets/icons/logo/save.svg';
import studentCareer from 'assets/icons/logo/studentCareer.png';
import attchment from 'assets/icons/logo/attchment.png';
import imageInput from 'assets/icons/logo/imageInput.png';
import videoInput from 'assets/icons/logo/videoInput.png';
import comingSoon from 'assets/icons/temp/coming-soon.png';
import eventCertificate from 'assets/icons/temp/eventCertificate.svg';
import primaryEdit from 'assets/icons/logo/primaryEdit.svg';
import primaryCrown from 'assets/icons/logo/primaryCrown.svg';
import PremiumPopupCrown from 'assets/icons/logo/PremiumPopupCrown.png';
import primaryMemberCertificate from 'assets/icons/logo/primaryMemberCertificate.svg';
import primaryMembershipReciept from 'assets/icons/logo/primaryMembershipReciept.svg';
import primaryMembershipCard from 'assets/icons/logo/primaryMembershipCard.svg';
import primaryUpcommingLabel from 'assets/icons/logo/primaryUpcommingLabel.svg';
import researchItem from 'assets/icons/logo/researchItem.png';
import successTick from 'assets/icons/logo/successTick.svg';
import shareFacebook from 'assets/icons/logo/shareFacebook.svg';
import shareLinkedin from 'assets/icons/logo/shareLinkedin.svg';
import shareWhatsapp from 'assets/icons/logo/shareWhatsapp.svg';
import shareTwitter from 'assets/icons/logo/shareTwitter.svg';
import journal from 'assets/icons/temp/journal.svg';
import noInternet from 'assets/icons/temp/noInternet.jpg';
import googleLogo from 'assets/icons/google-logo.svg';
import logoutBack from 'assets/icons/temp/logoutBack.png';
import helpEmail from 'assets/icons/logo/helpEmail.svg';
import helpCall from 'assets/icons/logo/helpCall.svg';
import customerService from 'assets/icons/logo/customerService.svg';
import chatPosts from 'assets/icons/logo/chatPosts.png';
import chatNetwork from 'assets/icons/logo/chatNetwork.png';
import chatGroups from 'assets/icons/logo/chatGroups.png';
import chatMessage from 'assets/icons/logo/chatMessage.png';
import digitalLibraryImage from 'assets/icons/digitalLibraryImage.svg';
import digitalLibraryImage2 from 'assets/icons/digitalLibraryImage2.svg';
import digitalLibraryImage3 from 'assets/icons/digitalLibraryImage3.png';
import digitalLibraryNewImage from 'assets/icons/digitalLibraryNewImage.svg';
import affiliations from 'assets/icons/logo/affiliations.svg';
import researchId from 'assets/icons/researchId.svg';
import myPublication from 'assets/icons/myPublication.svg';
import journalRole from 'assets/icons/logo/journalRole.svg';
import about from 'assets/icons/logo/about.svg';
import education from 'assets/icons/logo/education.svg';
import achivment from 'assets/icons/logo/achivment.svg';
import iferpNewslatter from 'assets/icons/temp/iferp_newslatter.png';
import digitalEvent from 'assets/icons/temp/digitalEvent.png';
import trueIcon from 'assets/icons/trueIcon.svg';
import falseIcon from 'assets/icons/falseIcon.svg';
import unknownFile from 'assets/icons/logo/unknownFile.png';
import txtFile from 'assets/icons/logo/txtFile.png';
import pdfFile from 'assets/icons/logo/pdfFile.png';
import docFile from 'assets/icons/logo/docFile.png';
import xlsFile from 'assets/icons/logo/xlsFile.png';
import myCalender from 'assets/icons/logo/my-calender.svg';
import myInterest from 'assets/icons/logo/my-interest.svg';
import myCommunities from 'assets/icons/logo/my-communities.svg';
import myFollowers from 'assets/icons/logo/my-followers.svg';
import chatLogo from 'assets/icons/chatLogo.png';
import membershipCertificate from 'assets/icons/membershipCertificate.jpg';
import membershipCard from 'assets/icons/membershipCard.jpg';
import myProject from 'assets/my-project.png';
import networkWithExpert from 'assets/icons/networkWithExpert.svg';
import participantInEvent from 'assets/icons/participantInEvent.svg';
import publishPaperInJurnal from 'assets/icons/publishPaperInJurnal.svg';
import loginS1 from 'assets/icons/slide/loginS1.png';
import loginS2 from 'assets/icons/slide/loginS2.png';
import loginS3 from 'assets/icons/slide/loginS3.png';
import loginS4 from 'assets/icons/slide/loginS4.png';
import loginS5 from 'assets/icons/slide/loginS5.png';
import explore from 'assets/icons/explore.png';
import iferpMember from 'assets/icons/iferpMember.png';
import iferpPublication from 'assets/icons/iferpPublication.png';
import iferpWebinarNew from 'assets/icons/iferpWebinar.png';
import iferpConferenceNew from 'assets/icons/iferpConference.png';
import emptyPaperText from 'assets/icons/emptyPaperText.png';
import whatsapp from 'assets/icons/whatsapp.png';
import collapse from 'assets/icons/logo/collapse.png';
import djobs from 'assets/icons/djobs.png';
import dlaern from 'assets/icons/dlaern.png';
import dmentor from 'assets/icons/dmentor.png';
import dnetwork from 'assets/icons/dnetwork.png';
import dpublish from 'assets/icons/dpublish.png';
import dpresent from 'assets/icons/dpresent.png';
import TrueIcon from 'assets/icons/traced.png';
import FalseIcon from 'assets/icons/Vector.png';
import Crown from 'assets/icons/crown.png';
import arrowRight from 'assets/icons/arrowRight.png';
import sendMessage from 'assets/icons/logo/sendMessage.png';
import pending from 'assets/icons/logo/pending.png';
import accepted from 'assets/icons/logo/accepted.png';
import rejected from 'assets/icons/logo/rejected.png';
import file from 'assets/icons/logo/file.png';
import download from 'assets/icons/logo/download.png';
import welcomeBoardImage from 'assets/icons/welcomeBoardImage.jpg';
import tempVideoPoster from 'assets/icons/temp/tempVideoPoster.png';
import videoRecorder from 'assets/icons/logo/video_recorder.png';
import VideoRecordingPoster from 'assets/icons/VideoRecordingPoster.png';
import startRecord from 'assets/icons/logo/startRecord.png';
import pauseRecord from 'assets/icons/logo/pauseRecord.png';
import stopRecord from 'assets/icons/logo/startRecord.png';
import roundPlay from 'assets/icons/logo/roundPlay.png';
import shareIcon from 'assets/icons/logo/shareIcon.png';
import lightWebsite from 'assets/icons/logo/lightWebsite.png';
import lightEmail from 'assets/icons/logo/lightEmail.png';
import mentorship from 'assets/icons/logo/mentorship.png';
import searchV2 from 'assets/icons/logo/searchV2.png';
import outline_delete from 'assets/icons/logo/outline_delete.png';
import videoMeet from 'assets/icons/logo/videoMeet.png';
import clockTime from 'assets/icons/logo/clockTime.png';
import star from 'assets/icons/logo/star.png';
import rightArrow from 'assets/icons/logo/rightArrow.png';
import downArrow from 'assets/icons/logo/downArrow.png';
import filterIcon from 'assets/icons/logo/filterIcon.png';
import mentors from 'assets/icons/mentors.png';
import mentees from 'assets/icons/mentees.png';
import sessions from 'assets/icons/sessions.png';
import revenue from 'assets/icons/revenue.png';
import totalErning from 'assets/icons/totalErning.png';
import pendingPayment from 'assets/icons/pendingPayment.png';
import lastMonthEning from 'assets/icons/lastMonthEning.png';
import blueEye from 'assets/icons/logo/blueEye.png';
import pinkThumb from 'assets/icons/logo/pinkThumb.png';
import mediaVideo from 'assets/icons/logo/mediaVideo.png';
import mediaGallery from 'assets/icons/logo/mediaGallery.png';
import connectUser from 'assets/icons/logo/connectUser.png';
import primaryConnectUser from 'assets/icons/logo/primaryConnectUser.png';
import blackFollowers from 'assets/icons/logo/blackFollowers.png';
import addSquare from 'assets/icons/logo/addSquare.png';
import addRound from 'assets/icons/logo/addRound.png';
import upArrow from 'assets/icons/logo/upArrow.png';
import StarOne from 'assets/icons/temp/StarOne.png';
import primaryEditPen from 'assets/icons/logo/primaryEditPen.png';
import downloadPrimary from 'assets/icons/logo/downloadPrimary.png';
import PaymentAccount from 'assets/icons/PaymentAccount.png';
import thumbEllipse from 'assets/icons/thumbEllipse.png';
import payment_export from 'assets/icons/logo/payment_export.png';
import infoCircle from 'assets/icons/logo/infoCircle.png';
import messageChat from 'assets/icons/logo/messageChat.png';
import unFillStar from 'assets/icons/logo/unFillStar.png';
import fillStar from 'assets/icons/logo/fillStar.png';
import grayOutlineStar from 'assets/icons/logo/grayOutlineStar.png';
import rightIcon from 'assets/icons/logo/rightIcon.svg';
import rightIconWithRound from 'assets/icons/logo/rightIconWithRound.svg';
import iconRedCross from 'assets/icons/logo/iconRedCross.svg';
import verifyEmailLogo from 'assets/icons/verifyEmailLogo.svg';
import cvv from 'assets/icons/logo/cvv.svg';
import visa_icon from 'assets/icons/logo/visa_icon.png';
import jcb_payment_icon from 'assets/icons/logo/jcb_payment_icon.png';
import american_express from 'assets/icons/logo/american_express.svg';
import mastercard from 'assets/icons/logo/mastercard.png';
import discover_icon from 'assets/icons/logo/discover_icon.png';
import diners_club from 'assets/icons/logo/diners_club.png';
import maestro from 'assets/icons/logo/maestro.png';
import unionpay from 'assets/icons/logo/unionpay.png';
import stp_card_error_C from 'assets/icons/logo/stp_card_error_C.png';
import approved from 'assets/icons/approved.png';
import waitingadminApproval from 'assets/icons/waitingadminApproval.png';
import premiumCrown from 'assets/icons/premiumCrown.png';
import scProfileIcon from 'assets/icons/scProfileIcon.png';
import idProfileIcon from 'assets/icons/idProfileIcon.png';
import googleprofileIcon from 'assets/icons/googleprofileIcon.png';
import publonProfileIcon from 'assets/icons/publonProfileIcon.png';
import linkdinProfileIcon from 'assets/icons/linkdinProfileIcon.png';
import Mask from 'assets/icons/temp/Mask.png';
import userIcon from 'assets/icons/logo/userIcon.png';
import locationIcon from 'assets/icons/logo/locationIcon.png';
import fillFollowers from 'assets/icons/logo/fillFollowers.png';
import advertisment from 'assets/icons/temp/advertisment.png';
import mentorMask from 'assets/icons/temp/mentorMask.png';
import tempQR from 'assets/icons/temp/tempQR.png';
import appleIcon from 'assets/icons/appleIcon.png';
import downloadAppImage from 'assets/icons/downloadAppImage.png';
import fillClockIcon from 'assets/icons/fillClockIcon.png';
import fillFBIcon from 'assets/icons/fillFBIcon.png';
import fillMeetIcon from 'assets/icons/fillMeetIcon.png';
import masterCardImage from 'assets/icons/masterCardImage.png';
import mentorIcon from 'assets/icons/mentorIcon.png';
import playstorIcon from 'assets/icons/playstorIcon.png';
import razorPayImage from 'assets/icons/razorPayImage.png';
import rupayImage from 'assets/icons/rupayImage.png';
import speakerIcon from 'assets/icons/speakerIcon.png';
import starSlider from 'assets/icons/star.png';
import UPIImage from 'assets/icons/UPIImage.png';
import visaImage from 'assets/icons/visaImage.png';
import twitterIcon from 'assets/icons/twitterIcon.png';
import technorateLogo from 'assets/icons/technorateLogo.png';
import mobileIcon from 'assets/icons/mobileIcon.png';
import mailIcon from 'assets/icons/mailIcon.png';
import linkedinIcon from 'assets/icons/linkedinIcon.png';
import instagramIcon from 'assets/icons/instagramIcon.png';
import rightRoundArrow from 'assets/icons/rightRoundArrow.png';
import secureIcon from 'assets/icons/secureIcon.png';
import presentationPlayButton from 'assets/icons/presentationPlayButton.png';
import tempPosterPresentation from 'assets/icons/temp/tempPosterPresentation.png';
import researcherIcon from 'assets/icons/researcherIcon.png';
import vidwanIcon from 'assets/icons/vidwanIcon.png';
import maintenance from 'assets/icons/maintenance.png';
import supperOfferImage from 'assets/icons/logo/supperOfferImage.png';
import newsEdit from 'assets/icons/logo/newsEdit.png';
import redDelete from 'assets/icons/logo/redDelete.png';
import warning from 'assets/icons/logo/warning.png';
import MaskGroup from 'assets/icons/MaskGroup.png';
import MaskGroup2 from 'assets/icons/MaskGroup2.png';
import MaskGroup3 from 'assets/icons/MaskGroup3.png';
import MaskGroup4 from 'assets/icons/MaskGroup4.png';
import lp from 'assets/icons/lp.png';
import rp from 'assets/icons/rp.png';
import uploadPrimary from 'assets/icons/logo/uploadPrimary.png';
import shareTwitterNew from 'assets/icons/logo/shareTwitterNew.png';
import s1 from 'assets/icons/slide/s1.png';
import s2 from 'assets/icons/slide/s2.png';
import s3 from 'assets/icons/slide/s3.png';
import s4 from 'assets/icons/slide/s4.png';
import s5 from 'assets/icons/slide/s5.png';
import s6 from 'assets/icons/slide/s6.png';
import s7 from 'assets/icons/slide/s7.png';
import UploadPaperIcon from 'assets/icons/UploadPaperIcon.png';
import userOutline from 'assets/icons/userOutline.png';
import whatsappOutline from 'assets/icons/whatsappOutline.png';
import abstractDone from 'assets/icons/abstractDone.png';
import calendarCheck from 'assets/icons/CalendarCheck.png';
import dummyQRForPass from 'assets/icons/temp/dummyQRForPass.png';
import passBgLogo from 'assets/icons/logo/passBgLogo.png';
import RightIcon from 'assets/icons/RightIcon.png';
import addCircleOutline from 'assets/icons/add_circle_outline.png';
import minusCircle from 'assets/icons/minus-circle.png';
import gift from 'assets/icons/Gift.png';
import plus from 'assets/icons/plus.png';
import dummyPaymentReceipt from 'assets/icons/temp/dummyPaymentReceipt.png';
import scannerIcon from 'assets/icons/ScannerIcon.png';
import primaryScanner from 'assets/icons/primaryScanner.png';
import circleInfo from 'assets/icons/circleInfo.png';
import calendarEvent from 'assets/icons/calendar-2.png';
import clockEvent from 'assets/icons/clock.png';
import locationEvent from 'assets/icons/location_on.png';
import newGroups from 'assets/icons/newGroups.png';
import dashboard_live_event from 'assets/icons/dashboard_live_event.png';
import NewBook from 'assets/icons/NewBook.png';
import NewBuildings from 'assets/icons/NewBuildings.png';
import NewComputer from 'assets/icons/NewComputer.png';
import Newlocal_library from 'assets/icons/Newlocal_library.png';
import NewPeople from 'assets/icons/NewPeople.png';
import NewPresentation from 'assets/icons/NewPresentation.png';
import Ellipse from 'assets/icons/Ellipse.png';
import NewTickCircle from 'assets/icons/NewTickCircle.png';
import paginationCircleLeft from 'assets/icons/paginationCircleLeft.png';
import paginationCircleRight from 'assets/icons/paginationCircleRight.png';
import NewDashboard from 'assets/icons/sildebar/NewDashboard.png';
import NewProfile from 'assets/icons/sildebar/NewProfile.png';
import NewConferences from 'assets/icons/sildebar/NewConferences.png';
import NewPublications from 'assets/icons/sildebar/NewPublications.png';
import NewInbox from 'assets/icons/sildebar/NewInbox.png';
import NewNetworks from 'assets/icons/sildebar/NewNetworks.png';
import NewGroups from 'assets/icons/sildebar/NewGroups.png';
import NewMentorship from 'assets/icons/sildebar/NewMentorship.png';
import NewNomiAward from 'assets/icons/sildebar/NewNomiAward.png';
import NewDigitalLibrary from 'assets/icons/sildebar/NewDigitalLibrary.png';
import NewCertificate from 'assets/icons/sildebar/NewCertificate.png';
import NewAward from 'assets/icons/sildebar/NewAward.png';
import NewHelp from 'assets/icons/sildebar/NewHelp.png';
import NewLogout from 'assets/icons/sildebar/NewLogout.png';
import NewMenu from 'assets/icons/sildebar/NewMenu.png';
import NewCareers from 'assets/icons/sildebar/NewCareers.png';
import AddCircle from 'assets/icons/addCircle.png';
import BAI from 'assets/icons/BAI.png';
import bPdf from 'assets/icons/bPdf.png';
import bTxt from 'assets/icons/bTxt.png';
import bProfile from 'assets/icons/bProfile.png';
import bPhone from 'assets/icons/bPhone.png';
import bCalender from 'assets/icons/bCalender.png';
import bMail from 'assets/icons/bMail.png';
import bGlobe from 'assets/icons/bGlobe.png';
import bGender from 'assets/icons/bGender.png';
import bArea from 'assets/icons/bArea.png';
import bDisipline from 'assets/icons/bDisipline.png';
import bSkill from 'assets/icons/bSkill.png';
import bLanguague from 'assets/icons/bLanguague.png';
import bEducation from 'assets/icons/bEducation.png';
import bPublication from 'assets/icons/bPublication.png';
import bJournal from 'assets/icons/bJournal.png';
import bAchievement from 'assets/icons/bAchievement.png';
import bPersonalActive from 'assets/icons/bPersonalActive.png';
import bMents from 'assets/icons/bMents.png';
import bRole from 'assets/icons/bRole.png';
import bPub from 'assets/icons/bPub.png';
import bExperience from 'assets/icons/bExperience.png';
import bEdu from 'assets/icons/bEdu.png';
import uploadIcons from 'assets/icons/upload.png';
import Edit from 'assets/icons/Edit.png';
import PreviewInfo from 'assets/icons/previewInfo.png';
import DummyNewCertificate from 'assets/icons/temp/dummyNewCertificate.png';
import Groups from 'assets/icons/groups.png';
import PersonSearch from 'assets/icons/person_search.png';
import AiResumeBanner from "assets/icons/AiResumeBanner.png";
import UserVector from "assets/icons/UserVector.png";
import MembershipVector from "assets/icons/MembershipVector.png";
import EducationVector from "assets/icons/EducationVector.png";
import bgProfile from "assets/icons/bgProfile.png"
import profileImg from "assets/icons/profileImg.png"
import uploadIcon from "assets/icons/uploadIcon.png"
import lightEducationVector from "assets/icons/lightEducationVector.png"
import lightMembershipVector from "assets/icons/lightMembershipVector.png"
import darkUserVector from "assets/icons/darkUserVector.png"
import premiumCardIcon from "assets/icons/premiumCardIcon.png"
import TableFalseIcon from "assets/icons/TableFalseIcon.png"
import TableTrueIcon from "assets/icons/TableTrueIcon.png"
export const icons = {
  bPersonalActive,
  bMents,
  bRole,
  bPub,
  bExperience,
  bEdu,
  bProfile,
  bPhone,
  bCalender,
  bMail,
  bGlobe,
  bGender,
  bArea,
  bDisipline,
  bSkill,
  bLanguague,
  bEducation,
  bPublication,
  bJournal,
  bAchievement,
  speakerIcon,
  starSlider,
  approved,
  BAI,
  bTxt,
  bPdf,
  plus,
  waitingadminApproval,
  dashboard,
  activePlan,
  careerSupport,
  certificate,
  chapteres,
  collaboration,
  verifyEmailLogo,
  conferenceEvents,
  fund,
  help,
  inbox,
  innovation,
  network,
  nominateAward,
  ourAcademies,
  ourProfile,
  corporateProfile,
  publication,
  search,
  searchGray,
  notification,
  calendar,
  studentMember,
  iferpConference,
  iferpWebinar,
  iferpNewspaper,
  iferpGroup,
  community,
  management,
  institute,
  logo,
  logout,
  goldCrown,
  profile,
  award,
  resource,
  career,
  institutionalHelp,
  exportIcon,
  filter,
  edit,
  deleteIcon,
  leftArrow,
  close,
  imageUpload,
  paperSubmitted,
  paperCheck,
  paperReview,
  paperAccepted,
  paperRegister,
  roundLogo,
  jpgType,
  branding,
  seen,
  time,
  lock,
  greenCheckMark,
  noImage,
  unauthorized,
  pdf,
  excel,
  jpg,
  activeDetails,
  rightLabel,
  checkCircle,
  userUashboard,
  save,
  studentCareer,
  nomineeAward,
  digitalLibrary,
  userHelp,
  attchment,
  imageInput,
  videoInput,
  comingSoon,
  primaryEdit,
  primaryCrown,
  primaryMemberCertificate,
  primaryMembershipCard,
  primaryUpcommingLabel,
  eventCertificate,
  researchItem,
  successTick,
  shareFacebook,
  shareLinkedin,
  shareWhatsapp,
  shareTwitter,
  journal,
  noInternet,
  googleLogo,
  primaryMembershipReciept,
  logoutBack,
  helpEmail,
  helpCall,
  customerService,
  chatPosts,
  chatNetwork,
  chatGroups,
  chatMessage,
  digitalLibraryImage,
  digitalLibraryImage2,
  digitalLibraryImage3,
  digitalLibraryNewImage,
  lightSave,
  affiliations,
  researchId,
  myPublication,
  journalRole,
  about,
  education,
  achivment,
  iferpNewslatter,
  digitalEvent,
  trueIcon,
  falseIcon,
  unknownFile,
  txtFile,
  pdfFile,
  docFile,
  xlsFile,
  myCalender,
  myInterest,
  myCommunities,
  myFollowers,
  chatLogo,
  membershipCertificate,
  membershipCard,
  qrCode,
  myProject,
  networkWithExpert,
  participantInEvent,
  publishPaperInJurnal,
  loginS1,
  loginS2,
  loginS3,
  loginS4,
  loginS5,
  explore,
  iferpMember,
  iferpPublication,
  iferpWebinarNew,
  iferpConferenceNew,
  emptyPaperText,
  whatsapp,
  collapse,
  djobs,
  dpresent,
  dlaern,
  dmentor,
  dnetwork,
  dpublish,
  TrueIcon,
  FalseIcon,
  Crown,
  arrowRight,
  sendMessage,
  pending,
  accepted,
  rejected,
  file,
  download,
  welcomeBoardImage,
  tempVideoPoster,
  videoRecorder,
  VideoRecordingPoster,
  startRecord,
  pauseRecord,
  stopRecord,
  roundPlay,
  shareIcon,
  lightWebsite,
  lightEmail,
  mentorship,
  searchV2,
  outline_delete,
  videoMeet,
  clockTime,
  star,
  rightArrow,
  downArrow,
  filterIcon,
  mentors,
  mentees,
  revenue,
  sessions,
  totalErning,
  pendingPayment,
  lastMonthEning,
  blackFollowers,
  blueEye,
  pinkThumb,
  mediaVideo,
  mediaGallery,
  connectUser,
  upArrow,
  primaryConnectUser,
  StarOne,
  addSquare,
  addRound,
  primaryEditPen,
  downloadPrimary,
  PaymentAccount,
  thumbEllipse,
  payment_export,
  infoCircle,
  messageChat,
  unFillStar,
  grayOutlineStar,
  fillStar,
  rightIcon,
  rightIconWithRound,
  iconRedCross,
  cvv,
  visa_icon,
  jcb_payment_icon,
  american_express,
  mastercard,
  discover_icon,
  diners_club,
  maestro,
  unionpay,
  stp_card_error_C,
  biInfoCircle,
  premiumCrown,
  scProfileIcon,
  idProfileIcon,
  googleprofileIcon,
  publonProfileIcon,
  linkdinProfileIcon,
  Mask,
  userIcon,
  locationIcon,
  fillFollowers,
  advertisment,
  tempQR,
  mentorMask,
  appleIcon,
  UPIImage,
  starSlider,
  speakerIcon,
  rupayImage,
  razorPayImage,
  playstorIcon,
  mentorIcon,
  masterCardImage,
  fillMeetIcon,
  fillFBIcon,
  fillClockIcon,
  downloadAppImage,
  visaImage,
  twitterIcon,
  technorateLogo,
  mobileIcon,
  mailIcon,
  linkedinIcon,
  instagramIcon,
  rightRoundArrow,
  secureIcon,
  presentationPlayButton,
  tempPosterPresentation,
  researcherIcon,
  vidwanIcon,
  maintenance,
  supperOfferImage,
  redDelete,
  newsEdit,
  warning,
  MaskGroup,
  MaskGroup2,
  MaskGroup3,
  MaskGroup4,
  lp,
  rp,
  uploadPrimary,
  shareTwitterNew,
  PremiumPopupCrown,
  s1,
  s2,
  s3,
  s4,
  s5,
  s6,
  s7,
  UploadPaperIcon,
  userOutline,
  whatsappOutline,
  abstractDone,
  calendarCheck,
  dummyQRForPass,
  passBgLogo,
  RightIcon,
  addCircleOutline,
  minusCircle,
  gift,
  dummyPaymentReceipt,
  scannerIcon,
  primaryScanner,
  circleInfo,
  calendarEvent,
  clockEvent,
  locationEvent,
  newGroups,
  dashboard_live_event,
  NewBook,
  NewBuildings,
  NewComputer,
  Newlocal_library,
  NewPeople,
  NewPresentation,
  Ellipse,
  NewTickCircle,
  paginationCircleLeft,
  paginationCircleRight,
  NewDashboard,
  NewProfile,
  NewConferences,
  NewPublications,
  NewInbox,
  NewNetworks,
  NewGroups,
  NewMentorship,
  NewNomiAward,
  NewDigitalLibrary,
  NewCertificate,
  NewAward,
  NewHelp,
  NewLogout,
  NewMenu,
  NewCareers,
  AddCircle,
  uploadIcons,
  Edit,
  PreviewInfo,
  DummyNewCertificate,
  Groups,
  PersonSearch,
  AiResumeBanner,
  UserVector,
  MembershipVector,
  EducationVector,
  bgProfile,
  profileImg,
  uploadIcon,
  lightEducationVector,
  lightMembershipVector,
  darkUserVector,
  premiumCardIcon,
  TableFalseIcon,
  TableTrueIcon
};
