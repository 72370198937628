import Button from 'components/form/Button';
import TextArea from 'components/form/TextArea';
import Modal from 'components/Layout/Modal';
import React, { useEffect, useState } from 'react';
import Label from 'components/form/Label';
import { icons } from 'utils/constants';
import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import html2pdf from 'html2pdf.js';
import { useDispatch } from 'react-redux';
import { throwError, throwSuccess } from 'store/slices';
import Loader from 'components/Layout/Loader';
import './NewCertificateGeneratePopup.scss';

const sampleData = {
  name: 'John Dow',
  date: '25th - 26th November,2023',
  certificate_number: 'IFERP2025_123_ICMDE_1234',
  memberid: 'TEST12345',
  institute: 'Parvathaneni Brahmayya Siddhartha College of Arts & Science',
  paper_title:
    'Stephanie Lorel ipsum Lorel ipsum Lorel ipsum Lorel ipsum Lorel ipsum ',
  conference:
    '44th World Conference on Applied Science, Engineering & Technology (44th WCASET-2023)',
  location: 'India',
  mode: 'Hybrid',
};

const NewCertificateGeneratePopup = ({
  onHide,
  handleAPI,
  handleSuccess,
  setHtmlContentData,
  htmlContentData,
}) => {
  const dispatch = useDispatch();

  const [htmlContent, setHtmlContent] = useState(htmlContentData);
  // const [htmlContentData, setHtmlContentData] = useState("");

  const [isForm, setIsForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('htmlContent', htmlContentData);
  }, [htmlContentData]);

  const isVal = true;

  const initialValues = {
    name: isVal ? sampleData.name : '',
    date: isVal ? sampleData.date : '',
    certificate_number: isVal ? sampleData.certificate_number : '',
    memberid: isVal ? sampleData.memberid : '',
    institute: isVal ? sampleData.institute : '',
    paper_title: isVal ? sampleData.paper_title : '',
    conference: isVal ? sampleData.conference : '',
    location: isVal ? sampleData.location : '',
    mode: isVal ? sampleData.mode : '',
  };

  const handleSubmit = async () => {
    setIsFormLoading(true);
    const response = await handleAPI(htmlContentData,htmlContent);
    if (response?.status === 200) {
      handleSuccess();
      setHtmlContent('');
      setHtmlContentData('');
      localStorage.removeItem('htmlContent');
    }
    setIsFormLoading(false);
  };

  const handleTextareaChange = (e) => {
    const newHtml = e.target.value;
    setHtmlContentData(newHtml);
    setHtmlContent(newHtml);
    localStorage.setItem('htmlContent', newHtml);
  };

  const generateHtml = (values) => {
        // let updatedHtml = initialHtmlTemplate;
    let updatedHtml = localStorage.getItem('htmlContent') || htmlContent;
    if (updatedHtml) {
      Object.keys(values)?.forEach((key) => {
        const placeholder = `{{${key}}}`;
        updatedHtml = updatedHtml?.replaceAll(placeholder, values[key] || '');
      });
      setHtmlContent(updatedHtml);
    }
  };

  const handleDownload = async () => {
    if (!htmlContent) return;
    try {
      setIsLoading(true);
      const container = document.createElement('div');
      container.innerHTML = htmlContent;

      // Options for html2pdf
      const options = {
        margin: 10,
        filename: 'sample_certificate.pdf',
        html2canvas: {
          scale: 2,
          useCORS: true,
          logging: false,
          letterRendering: true,
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'landscape',
        },
      };

      // Generate the PDF and handle the promise rejection
      await html2pdf()
        .set(options)
        .from(container)
        .save()
        .then(() => {
          dispatch(throwSuccess('Downloaded successfully!'));
        })
        .catch((err) => {
          dispatch(throwError({ message: err }));
        });
    } catch (error) {
      dispatch(throwError({ message: error }));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreview = async () => {
    if (!htmlContent) return;

    try {
      setIsPreviewLoading(true);
      const container = document.createElement('div');
      container.innerHTML = htmlContent;

      // Options for html2pdf
      const options = {
        margin: 10,
        filename: 'sample_certificate.pdf',
        html2canvas: {
          scale: 2,
          useCORS: true,
          logging: false,
          letterRendering: true,
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'landscape',
        },
      };

      const pdfBlob = await html2pdf()
        .set(options)
        .from(container)
        .output('blob');

      const previewUrl = URL.createObjectURL(pdfBlob);
      setPdfPreviewUrl(previewUrl);
    } catch (error) {
      dispatch(throwError({ message: error }));
    } finally {
      setIsPreviewLoading(false);
    }
  };



  useEffect(() => {
    if (isForm && isVal) generateHtml(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForm, isVal]);

  useEffect(() => {
    const storedHtml = localStorage.getItem('htmlContent');
    if (storedHtml) {
      setHtmlContent(storedHtml); // Set the initial value
    }
  }, []);

  return (
    <>
      {pdfPreviewUrl && (
        <Modal
          fullscreen
          largeClose
          onHide={() => {
            setPdfPreviewUrl('');
          }}
        >
          <iframe
            frameBorder="0"
            className="w-100"
            src={`${pdfPreviewUrl}#toolbar=0&navpanes=0`}
            title="description"
            style={{
              width: '100%',
              height: '99%',
            }}
          />
        </Modal>
      )}
      <Modal
        size="lg"
        width={'1320px'}
        onHide={() => {
          onHide();
          setHtmlContent('');
          localStorage.removeItem('htmlContent');
        }}
        titleBesideClose={'Research Paper Presentation Certificate'}
        titleBesideCloseClassName={'cps-15'}
        isTitleBorder
        isTitleVerticalCenter
        className={'new-certificate-generate-popup'}
      >
        <div className="cps-32 cpt-32 cpe-32 cpb-32">
          <div className="new-certificate-container cmb-28">
            <div className="left-section">
              {isForm ? (
                <Formik initialValues={initialValues} enableReinitialize>
                  {(props) => {
                    const { values, handleChange } = props;
                    const {
                      name,
                      date,
                      certificate_number,
                      memberid,
                      institute,
                      paper_title,
                      conference,
                      location,
                      mode,
                    } = values;
                    return (
                      <div className="form row">
                        <div className="col-md-6 cmb-20">
                          <TextInput
                            label={'Name'}
                            id="name"
                            value={name}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6 cmb-20">
                          <TextInput
                            label={'Date'}
                            id="date"
                            value={date}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6 cmb-20">
                          <TextInput
                            label={'Certificate Number'}
                            id="certificate_number"
                            value={certificate_number}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6 cmb-20">
                          <TextInput
                            label={'Membership ID'}
                            id="memberid"
                            value={memberid}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-md-12 cmb-20">
                          <TextInput
                            label={'Institution'}
                            id="institute"
                            value={institute}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-12 cmb-20">
                          <TextInput
                            label={'Paper Title'}
                            id="paper_title"
                            value={paper_title}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-12 cmb-20">
                          <TextInput
                            label={'Conference Name'}
                            id="conference"
                            value={conference}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-md-6 cmb-20">
                          <TextInput
                            label={'Location'}
                            id="location"
                            value={location}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6 cmb-20">
                          <TextInput
                            label={'Conference Mode'}
                            id="mode"
                            value={mode}
                            onChange={(e) => {
                              handleChange(e);
                              generateHtml({
                                ...values,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              ) : (
                <TextArea
                  label={'HTML Content'}
                  rows={25}
                  isRequired
                  value={htmlContentData}
                  placeholder={'Write your HTML,CSS code here...'}
                  onChange={handleTextareaChange}
                />
              )}
            </div>
            <div className="right-section">
              <Label label={'Preview'} />
              {htmlContent ? (
                <div className="dynamic-preview-section d-flex align-items-center justify-content-center flex-column">
                  <div
                    className="preview-output"
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  />
                  {isForm && (
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className="d-flex align-items-center gap-2 text-20-400-25 color-new-car pointer"
                        onClick={handlePreview}
                      >
                        <i className="bi bi-eye" /> Full Preview
                        {isPreviewLoading && (
                          <div>
                            <Loader size={'sm'} />
                          </div>
                        )}
                      </div>
                      <div className="previw-download-button-border"></div>
                      <div
                        className="d-flex align-items-center gap-2 text-20-400-25 color-new-car pointer"
                        onClick={handleDownload}
                      >
                        <i className="bi bi-download" /> Download Certificate{' '}
                        {isLoading && (
                          <div>
                            <Loader size={'sm'} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="preview-container d-flex align-items-center justify-content-center flex-column">
                    <img
                      src={icons?.PreviewInfo}
                      alt="info"
                      className="cmb-24"
                    />
                    <div className="color-6866 text-24-500-30">
                      Write code to Preview
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              text="Back"
              btnStyle="light-outline"
              className="h-35 cps-20 cpe-20"
              onClick={() => {
                onHide();
                setIsForm(false);
                localStorage.removeItem('htmlContent');
              }}
            />
            <Button
              text={`${isForm ? 'Submit' : 'Proceed Next'}`}
              btnStyle="primary-dark"
              className="h-35 cps-20 cpe-20"
              btnLoading={isForm && isFormLoading}
              onClick={() => {
                if (isForm) {
                  handleSubmit();
                } else {
                  setIsForm(!isForm);
                }
              }}
              disabled={!htmlContent}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewCertificateGeneratePopup;
