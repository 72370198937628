import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import Button from 'components/form/Button';
import Dropdown from 'components/form/Dropdown';
import FileUpload from 'components/form/FileUpload';
import Location from 'components/form/Location';
import TextInput from 'components/form/TextInput';
import * as Yup from 'yup';
import {
  addResearchId,
  editResearchProfile,
  fetchProfile,
  fetchResearchProfileData,
  // GenreteResume,
  getUniversity,
} from 'store/slices';
import { useDispatch, useSelector } from 'react-redux';
import { limit, researchType } from 'utils/constants';
import {
  getDataFromLocalStorage,
  getFilenameFromUrl,
  handleCampaignLog,
  objectToFormData,
} from 'utils/helpers';
import { cloneDeep, isEqual, lowerCase, omit, some } from 'lodash';
const PersonalForm = ({
  onNext,
  fetchDetails,
  handelCv,
  loadingCv,
  credit,
}) => {
  const dispatch = useDispatch();
  const userId = getDataFromLocalStorage('id');

  const { userDetails, researchProfile } = useSelector((state) => ({
    userDetails: state.student.userDetails,
    researchProfile: state.student.researchProfile,
  }));

  const {
    id,
    first_name,
    last_name,
    profile_photo_path,
    personal_details = {},
    educational_details = {},
    professional_details = {},
  } = userDetails;

  const { research_id } = researchProfile || {};

  const { country, state } = personal_details;
  const { designation } = professional_details;
  const { ug_university, pg_university, phd_university } = educational_details;
  const universityID = phd_university || pg_university || ug_university;
  const [btnLoading, setBtnLoading] = useState(false);

  const [universityData, setUniversityData] = useState({
    list: [],
    newList: [],
    name: '',
    total: 0,
    offset: 0,
    limit: limit,
    isLoading: true,
  });
  const [uTimer, setUTimer] = useState('');
  const isFieldEmpty = false;

  const initialValues = {
    first_name: first_name || '',
    last_name: last_name || '',
    designation: designation || '',
    university_id: universityID || '',
    country_id: country || '',
    state_id: state || '',
    profile_photo_path: profile_photo_path || '',
    profilePhotoPathName: '',
    researchIds:
      research_id?.length === 0
        ? [
            {
              id: '',
              name: '',
              number: '',
            },
          ]
        : research_id,
  };

  const validationSchema = Yup.object().shape({
    university_id: Yup.string().required('University is required.'),
    country_id: Yup.string().required('Country is required.'),
    state_id: Yup.string().required('State/Province is required.'),
    researchIds: Yup.array(
      Yup.object({
        name: Yup.string().required('ID name is required.'),
        number: Yup.string().required('ID number/link is required.'),
      })
    ),
  });

  const handelSave = async (values) => {
    const isFormChanged =
      JSON.stringify(values) !== JSON.stringify(initialValues);

    // if (!isFormChanged) {
    //   onNext();
    //   return;
    // }
    setBtnLoading(true);
    const payloadOne = {};
    const payloadTwo = {};
    let successOne = false;
    let successTwo = false;
    for (const key in values) {
      if (key === 'researchIds') {
        payloadTwo[key] = values[key];
      } else {
        payloadOne[key] = values[key];
      }
    }
    if (payloadOne && payloadTwo) {
      const newPayloadOne = objectToFormData(payloadOne);
      const responseOne = await dispatch(editResearchProfile(newPayloadOne));
      if (responseOne?.status === 200) {
        successOne = true;
      }
      const newPayloadTwo = {
        research_id: JSON.stringify(payloadTwo.researchIds),
      };
      const responseTwo = await dispatch(addResearchId(newPayloadTwo));
      if (responseTwo?.status === 200) {
        successTwo = true;
      }
    }
    if (successOne && successTwo) {
      if (localStorage.getItem('isCampaign', true)) {
        handleCampaignLog({ user_id: id, type: 'update' }, dispatch);
      }
      dispatch(fetchProfile());
    }
    fetchDetails();
    // handelCv();
    onNext();
    setBtnLoading(false);
  };

  // useEffect(() => {
  //   if (loadingCv) {
  //
  //   }
  // }, [loadingCv]);

  const fetchUniversityList = async (obj, isReset) => {
    const queryParams = new URLSearchParams(
      omit(obj, ['list', 'newList', 'total', 'isLoading'])
    ).toString();
    let response = await dispatch(getUniversity(queryParams));
    setUniversityData((prev) => {
      let resData = response?.data?.universities || [];
      let listData = isReset ? resData : [...prev.list, ...resData];
      return {
        ...prev,
        list: listData,
        total: response?.data?.result_count || 0,
        isLoading: false,
      };
    });
  };

  useEffect(() => {
    fetchUniversityList(universityData, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('universityData', universityData);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSave}
    >
      {(props) => {
        const {
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
        } = props;
        const isAddMore = some(values.researchIds, (o) => !o.name || !o.number);

        return (
          <form
            onSubmit={handleSubmit}
            className=""
            style={{ maxHeight: '600px' }}
          >
            <div className="row">
              <div className="text-16-500 color-1717 cmb-10">Headline Info</div>

              <div className="col-md-6 cmb-22">
                <Dropdown
                  label="University*"
                  labelClass="cmb-4 color-black-olive text-14-400"
                  placeholder="Select University"
                  optionValue="name"
                  id="university_id"
                  value={+values.university_id}
                  error={errors.university_id}
                  options={universityData?.list}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 cmb-22">
                <Location
                  type="country"
                  data={{
                    label: 'Country*',
                    id: 'country_id',
                    placeholder: 'Country*',
                    value: values.country_id,
                    error: errors.country_id,
                    onChange: handleChange,
                  }}
                />
              </div>
              <div className="col-md-6 cmb-30">
                <Location
                  type="state"
                  data={{
                    id: 'state_id',
                    label: 'State/Province*',
                    placeholder: 'State/Province*',
                    value: values.state_id,
                    error: errors.state_id,
                    countryId: values.country_id,
                    disabled: !values.country_id,
                    onChange: (e) => {
                      setFieldValue('state_name', e?.target?.data?.state);
                      handleChange(e);
                    },
                  }}
                />
              </div>
              <div className="col-md-6 cmb-22">
                <FileUpload
                  isUpload={true}
                  labelClass="cmb-4 color-black-olive text-14-400"
                  label="Profile Image"
                  id="profile_photo_path"
                  onChange={(e) => {
                    const id = e.target.id;
                    const value = e.target.value;
                    const fileName = e.target.fileName;
                    setFieldValue('profilePhotoPathName', fileName);
                    setFieldValue(id, value);
                  }}
                  fileType="image"
                  error={errors.profile_photo_path}
                  fileText={
                    getFilenameFromUrl(
                      values.profilePhotoPathName || values.profile_photo_path
                    ) || 'Profile Photo'
                  }
                />
              </div>
              <FieldArray
                name="researchIds"
                render={(arrayHelpers) => (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-16-500 color-1717 cmb-10">
                        My Research ID’s
                      </div>

                      {/* <div className="mb-3">
                        <Button
                          text="ADD Another ID"
                          btnStyle="primary-outline-car"
                          onClick={() =>
                            arrayHelpers.push({
                              id: "",
                              name: "",
                              number: "",
                            })
                          }
                        />
                      </div> */}

                      {/* {values.researchIds.length > 0 && ( */}
                      <Button
                        isSquare
                        text="+ Add Another ID"
                        btnStyle="primary-outline-car"
                        disabled={isAddMore}
                        onClick={() => {
                          setFieldValue('researchIds', [
                            ...values.researchIds,
                            {
                              id: '',
                              name: '',
                              number: '',
                            },
                          ]);
                        }}
                      />
                      {/* )} */}
                    </div>
                    {values?.researchIds?.map((elem, index) => {
                      const { name, number } = elem;
                      const { name: errName, number: errNumber } =
                        errors?.researchIds?.[index] || {};
                      let newID = name ? [{ id: name, label: name }] : [];
                      researchType.forEach((elm) => {
                        let thisExist = values.researchIds?.find(
                          (o) => o.name === elm?.id
                        );
                        if (!thisExist) {
                          newID.push(elm);
                        }
                      });
                      return (
                        <React.Fragment key={index}>
                          <div className="col-md-6 cmb-22">
                            <Dropdown
                              label={`ID Name ${index + 1}*`}
                              placeholder="Select id name"
                              options={newID}
                              id={`researchIds[${index}][name]`}
                              value={name}
                              error={errName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 cmb-22">
                            <TextInput
                              label={`ID Number/Link ${index + 1}*`}
                              placeholder="Enter ID Number/Link"
                              onChange={handleChange}
                              value={number}
                              error={errNumber}
                              id={`researchIds[${index}][number]`}
                            />
                          </div>
                          <div
                            className={`d-flex gap-3 ${
                              values.researchIds.length - 1 === index
                                ? ''
                                : ' cmb-22'
                            }`}
                          >
                            {values?.researchIds.length > 1 && (
                              <Button
                                isSquare
                                text="Delete"
                                btnStyle="primary-gray"
                                icon={<i className="bi bi-trash me-2" />}
                                className="cpt-5 cpb-5 cps-10 cpe-10 h-35"
                                onClick={() => {
                                  const listArray = cloneDeep(
                                    values?.researchIds
                                  );
                                  listArray.splice(index, 1);
                                  setFieldValue('researchIds', listArray);
                                }}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              />
              {/* {credit && ( */}
              <div className="text-14-500 color-dark-gray d-flex justify-content-end cmb-15">
                Total AI credits :
                <span className="text-16-700 cms-4 color-new-car">
                  {' '}
                  {credit || 0}
                </span>
              </div>
              {/* )} */}

              <div className="d-flex justify-content-end gap-2 mt-10">
                <Button
                  text="Save & Continue"
                  btnStyle="primary-dark"
                  className="cps-10 cpe-10"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   handelCv();
                  // }}
                  onClick={handleSubmit}
                  btnLoading={loadingCv || btnLoading}
                  disabled={loadingCv || btnLoading}
                />
                {/* <Button
                  text="Save"
                  className="btn-primary cps-25 cpe-25"
                  onClick={handleSubmit}
                  btnLoading={btnLoading}
                  disabled={btnLoading}
                /> */}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PersonalForm;
