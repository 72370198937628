import React, { useState } from "react";
import MentorApprovals from "./MentorApprovals";
import SessionApprovals from "./SessionApprovals";
import { getDataFromLocalStorage } from "utils/helpers";
import RejectionRequests from "./RejectionRequests/RejectionRequests";
import RejectedRequests from "./RejectedRequests";
import "./approvals.scss";

const Approvals = () => {
  const [type, setType] = useState("mentor-approvals-overview");
  const activeClass = "p-2 pb-1 color-new-car text-16-500 me-4 bb-new-car";
  const inActiveClass = "p-2 pb-1 color-black-olive text-16-500 me-4 pointer";
  const userData = getDataFromLocalStorage();
  const {
    user_type,
    resource_role,
    mentor_approvals_panding,
    session_approvals_panding,
  } = userData;
  const isShowCount = user_type === "6" && resource_role === "1";

  return (
    <div>
      <div id="approvals-continer" className="d-flex align-items-center mb-2">
        <div
          className={`${
            type === "mentor-approvals-overview" ? activeClass : inActiveClass
          } position-relative`}
          onClick={() => {
            setType("mentor-approvals-overview");
          }}
        >
          Mentor Approvals
          {isShowCount && mentor_approvals_panding && (
            <span className="mentor-pending-count">
              {mentor_approvals_panding}
            </span>
          )}
        </div>
        <div
          className={`${
            type === "session-approvals-overview" ? activeClass : inActiveClass
          } position-relative`}
          onClick={() => {
            setType("session-approvals-overview");
          }}
        >
          Session Approvals
          {isShowCount && session_approvals_panding && (
            <span className="session-pending-count">
              {session_approvals_panding}
            </span>
          )}
        </div>
        {["0", "1"].includes(user_type) && (
          <div
            className={
              type === "rejection-approvals-overview"
                ? activeClass
                : inActiveClass
            }
            onClick={() => {
              setType("rejection-approvals-overview");
            }}
          >
            Rejection Requests
          </div>
        )}
        {user_type === "6" && (
          <div
            className={
              type === "rejected-approvals-overview"
                ? activeClass
                : inActiveClass
            }
            onClick={() => {
              setType("rejected-approvals-overview");
            }}
          >
            Rejected Requests
          </div>
        )}
      </div>
      {type === "mentor-approvals-overview" && <MentorApprovals />}
      {type === "session-approvals-overview" && <SessionApprovals />}
      {type === "rejection-approvals-overview" && <RejectionRequests />}
      {type === "rejected-approvals-overview" && <RejectedRequests />}
    </div>
  );
};

export default Approvals;
