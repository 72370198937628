import Button from "components/form/Button";
import TextInput from "components/form/TextInput";
import ToggleSwitch from "components/form/ToggleSwitch";
import Loader from "components/Layout/Loader";
import { Formik } from "formik";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  throwError,
  throwSuccess,
  updateEventPriceCategories,
} from "store/slices";
import { objectToFormData, titleCaseString } from "utils/helpers";

const EventPriceCategory = ({
  isLoading,
  eventCategoriesList,
  handleSuccess,
}) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const [newEventCategories, setNewEventCategories] = useState([]);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const initialValues = eventCategoriesList?.reduce((acc, item, index) => {
    acc[`name${index}`] = item?.name || "";
    acc[`status${index}`] = item?.status === "1";
    return acc;
  }, {});

  const handleAddEventCategory = (setFieldValue) => {
    const newCategory = { id: "", name: "", status: false };
    setNewEventCategories((prev) => [...prev, newCategory]);
    setFieldValue(`newName${newEventCategories?.length}`, "");
    setFieldValue(`newStatus${newEventCategories?.length}`, false);
  };

  const handleUpdateEventCategory = async (value) => {
    setIsEventLoading(true);
    // const updatedCategories = eventCategoriesList?.map((item,index)=>{
    //     console.log("item",item)
    //     console.log("value",value)
    //     console.log("ans",value[`status${index}`])

    //     return{}
    // })

    const updatedCategories = eventCategoriesList?.map((item, index) => ({
      id: item?.id || "",
      name: titleCaseString(value[`name${index}`]),
      status: value[`status${index}`] ? "1" : "0",
    }));

    const newFormattedCategories = newEventCategories?.map((_, newIndex) => ({
      id: "",
      name: titleCaseString(value[`newName${newIndex}`]),
      status: value[`newStatus${newIndex}`] ? "1" : "0",
    }));

    const payload = [...updatedCategories, ...newFormattedCategories];
    const newPayload = {
      categories: JSON.stringify(payload),
      category_type: "1",
    };

    const response = await dispatch(
      updateEventPriceCategories(objectToFormData(newPayload))
    );

    if (response?.status === 200) {
      setNewEventCategories([]);
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.message }));
    }

    setIsEventLoading(false);
  };
  const {
    // view: viewAccess,
    create: createAccess,
    update: updateAccess,
    // delete: deleteAccess,
  } =
    userDetails?.role_data?.find((item) => item?.permission_name === "Settings")
      ?.sub_permission || {};

  return isLoading ? (
    <div className="center-flex cpt-50 cpb-50">
      <Loader size="md" />
    </div>
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleUpdateEventCategory}
    >
      {(props) => {
        const { values, handleChange, setFieldValue, handleSubmit } = props;
        return (
          <form>
            <div className="row cmb-24">
              {eventCategoriesList?.map((_, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="col-md-4 cmb-24">
                      <TextInput
                        label={`Pricing Category ${i + 1}`}
                        id={`name${i}`}
                        onChange={handleChange}
                        value={values[`name${i}`]}
                        disabled={!values[`status${i}`]}
                      />
                    </div>
                    <div className="col-md-2 cmb-24">
                      <div className="d-flex align-items-center h-100 cpt-25">
                        <ToggleSwitch
                          isChecked={values[`status${i}`]}
                          onChange={(e) =>
                            setFieldValue(`status${i}`, e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}

              {newEventCategories?.map((_, index) => {
                const finalIndex = eventCategoriesList?.length + index + 1;

                return (
                  <React.Fragment key={`new-${index}`}>
                    <div className="col-md-4">
                      <TextInput
                        label={`New Pricing Category ${finalIndex}`}
                        id={`newName${index}`}
                        onChange={handleChange}
                        value={values[`newName${index}`]}
                      />
                    </div>
                    <div className="col-md-2">
                      <div className="d-flex align-items-center h-100 cpt-25">
                        <ToggleSwitch
                          isChecked={values[`newStatus${index}`]}
                          onChange={(e) =>
                            setFieldValue(`newStatus${index}`, e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            <div className="d-flex align-items-center gap-3">
              {updateAccess === "1" && (
                <Button
                  text={"Update"}
                  btnStyle={"primary-dark"}
                  className={"h-35 cps-15 cpe-12"}
                  onClick={handleSubmit}
                  btnLoading={isEventLoading}
                  disabled={isEqual(initialValues, values)}
                />
              )}
              {createAccess === "1" && (
                <Button
                  text={" + Add Category"}
                  btnStyle={"primary-outline"}
                  className={"h-35 cps-15 cpe-12"}
                  onClick={() => {
                    handleAddEventCategory(setFieldValue);
                  }}
                />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EventPriceCategory;
