import Button from 'components/form/Button';
import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import { cloneDeep, some } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addJournalRoles, GenreteResume } from 'store/slices';
import {
  getDataFromLocalStorage,
  handleCampaignLog,
  objectToFormData,
} from 'utils/helpers';
import * as Yup from 'yup';

const RoleForm = ({
  onNext,
  onPrevious,
  fetchDetails,
  handelCv,
  loadingCv,
  credit,
}) => {
  const dispatch = useDispatch();
  const isFieldEmpty = false;

  const { researchProfile } = useSelector((state) => ({
    researchProfile: state.student.researchProfile,
  }));
  const { current_journal_roles } = researchProfile || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const id = getDataFromLocalStorage('id');

  const handelSave = async (values) => {
    const isFormChanged =
      JSON.stringify(values) !== JSON.stringify(initialValues);

    // if (!isFormChanged) {
    //   onNext();
    //   return;
    // }
    setBtnLoading(true);
    const payload = { journal_roles: JSON.stringify(values.journal_roles) };
    const response = await dispatch(addJournalRoles(payload));
    if (response?.status === 200) {
      if (localStorage.getItem('isCampaign', true)) {
        handleCampaignLog({ user_id: id, type: 'update' }, dispatch);
      }
      fetchDetails();
      //await handelCv();
    }
    onNext();
    setBtnLoading(false);
  };

  const [clickCount, setClickCount] = useState(0);
  const [loading, setLoading] = useState(false);

  //   const handleClick = async (e, handleSubmit) => {
  //    e.preventDefault();
  //    e.stopPropagation();

  //    if (clickCount === 0) {
  //      setClickCount(1);
  //      handleSubmit();
  //    }

  //    else if (clickCount === 1) {
  //      setClickCount(0);
  //     await handelCv();
  //      onNext()
  //    }
  //  };

  const validationSchema = Yup.object().shape({
    journal_roles: Yup.array(
      Yup.object({
        role: Yup.string().required('Role is required.'),
        journals: Yup.string().required('Journals is required.'),
      })
    ),
  });

  const initialValues = {
    journal_roles:
      current_journal_roles?.length === 0
        ? [
            {
              id: '',
              role: '',
              journals: '',
            },
          ]
        : current_journal_roles,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handelSave}
      validationSchema={validationSchema}
    >
      {(props) => {
        const { values, errors, handleChange, handleSubmit, setFieldValue } =
          props;

        const isAddMoreJournal = some(
          values?.journal_roles,
          (o) => !o?.role || !o?.journals
        );

        return (
          <form>
            <div className="d-flex justify-content-end cmb-5">
              <Button
                isSquare
                text="+ Add Another Role"
                btnStyle="primary-outline-car"
                className="h-35 text-14-500"
                disabled={isAddMoreJournal}
                onClick={() => {
                  setFieldValue('journal_roles', [
                    ...values.journal_roles,
                    { id: '', role: '', journals: '' },
                  ]);
                }}
              />
            </div>
            <div className="row">
              {values?.journal_roles?.map((elem, index) => {
                const { role, journals } = elem;
                const { role: errRole, journals: errJournals } =
                  errors?.journal_roles?.[index] || {};

                return (
                  <React.Fragment key={index}>
                    <div>
                      <div className="fb-center">
                        <div className="mb-2 text-15-500">
                          Publication {index + 1}
                        </div>
                      </div>
                    </div>
                    <div className="cmb-22 col-md-6">
                      <TextInput
                        id={`journal_roles[${index}][role]`}
                        label="Role*"
                        placeholder="Enter a role (e.g., Guest Editor)"
                        onChange={handleChange}
                        value={role}
                        error={errRole}
                      />
                    </div>
                    <div className="cmb-22 col-md-6">
                      <TextInput
                        label="Journals*"
                        placeholder="Enter Journal where you have this role"
                        id={`journal_roles[${index}][journals]`}
                        value={journals}
                        error={errJournals}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={`d-flex gap-3 ${' cmb-22'}`}>
                      {values?.journal_roles.length > 1 && (
                        <div className="">
                          <Button
                            isSquare
                            text="Delete"
                            btnStyle="primary-gray"
                            icon={<i className="bi bi-trash me-2" />}
                            className="cpt-5 cpb-5 cps-10 cpe-10"
                            onClick={() => {
                              const listArray = cloneDeep(
                                values?.journal_roles
                              );
                              listArray.splice(index, 1);
                              setFieldValue('journal_roles', listArray);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}

              {/* {credit && ( */}
              <div className="text-14-400 color-dark-gray d-flex justify-content-end cmb-15">
                Total AI credits :
                <span className="text-16-500 cms-4"> {credit || 0}</span>
              </div>
              {/* )} */}

              <div className="d-flex justify-content-between gap-4">
                <button
                  className="btn btn-outline-primary"
                  onClick={onPrevious}
                >
                  Previous
                </button>

                <div className="d-flex gap-2">
                  {/* <Button
                
                text="Genrete AI Resume"
                btnStyle="primary-dark"
                className="cps-10 cpe-10"
                onClick={(e)=>{
                  e.preventDefault();  
    e.stopPropagation();
                  handelCv()
                }}
                btnLoading={loadingCv}
                disabled={loadingCv}
              />
        <Button text='Save' className="btn-primary cps-25 cpe-25"  onClick={handleSubmit} 
       btnLoading={btnLoading} disabled={btnLoading}  /> */}

                  <Button
                    text="Save & Continue"
                    btnStyle="primary-dark"
                    className="cps-10 cpe-10"
                    onClick={handleSubmit} // On click, trigger handleClick
                    btnLoading={loadingCv || loading}
                    disabled={loadingCv || btnLoading}
                  />
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default RoleForm;
