import React from "react";
import FirstStepOfFollowUps from "./RegisterTemplatesForm/FirstStepOfFollowUps";
import SecondStepOfFollowUps from "./RegisterTemplatesForm/SecondStepOfFollowUps";
import ThirdStepOfFollowUps from "./RegisterTemplatesForm/ThirdStepOfFollowUps";
import FourthStepOfFollowUps from "./RegisterTemplatesForm/FourthStepOfFollowUps";
import FifthStepOfFollowUps from "./RegisterTemplatesForm/FifthStepOfFollowUps";
import SixthStepOfFollowUps from "./RegisterTemplatesForm/SixthStepOfFollowUps";
import SevenStepOfFollowUps from "./RegisterTemplatesForm/SevenStepOfFollowUps";
import EigthStepOfFollowUps from "./RegisterTemplatesForm/EigthStepOfFollowUps";
import { useSelector } from "react-redux";

const RegistrationFollowUpsTemplatesForm = ({ props }) => {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const {
    // view: viewAccess,
    update: updateAccess,
    // delete: deleteAccess,
    create: createAccess,
  } =
    userDetails?.role_data?.find(
      (item) => item?.permission_name === "Event Management"
    )?.sub_permission || {};
  const { values } = props;
  const isEdit = updateAccess === "1" || createAccess === "1";

  const getFormComponent = (type) => {
    switch (type) {
      case "Session name inclusion notification":
        return <FirstStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Coupon or Voucher follow ups/early bird deadline":
        return <SecondStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Publication Details Few slots left":
        return <ThirdStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Session-Based Publication Opportunities and Conference Outcomes at [Conference Name]":
        return <FourthStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Keynote speaker, session speaker notification":
        return <FifthStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Agenda Page and Slot Allotment notification":
        return <SixthStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Hall Ticket, Zoom link and Confirmation":
        return <SevenStepOfFollowUps props={props} isEdit={isEdit} />;
      case "Full Paper Follow-Up Email":
        return <EigthStepOfFollowUps props={props} isEdit={isEdit} />;

      default:
        return (
          <div className="center-flex">
            Please select the value from the dropdown
          </div>
        );
    }
  };
  return <div className="row">{getFormComponent(values?.followUps)}</div>;
};

export default RegistrationFollowUpsTemplatesForm;
