export const limit = 10;

// export const mimeType = "video/mp4";
// export const fileName = "MyRecordedVideo.mp4";

export const paySuccess =
  "Congratulations, Your payment has been successfully processed. We confirm your purchase.";
export const payError =
  "Unfortunately, we couldn't process your payment. We regret the inconvenience. Kindly try again later, contact us for assistance.";

export const chatPath = "chat";
export const profilePath = "Profile";
export const BrochurePath = "Event/Brochure";
export const CoverImagePath = "Event/CoverImage";
export const posterPath = "Event/Poster";
export const conferencePath = "Event/Conference";
export const agendaPath = "Event/Agenda";
export const certificatePath = "Certificate";
export const chapterMemberPath = "ChapterMember";
export const journalsPath = "Journals";
export const journalsPaperPath = "Journals/Submitted-Papers";
export const journalAcceptanceLetterPath = "RegistrationAcceptanceLetter";
export const journalPlagiarismDocumentPath = "PlagiarismDocument";
export const publicationAssistancePath = "Event/PublicationAssistance";
export const eventAbstarctPath = "Event/Abstract";
export const eventAcceptanceLetterPath = "Event/RegistrationAcceptanceLetter";
export const careerPath = "Career";
export const networkPath = "Network";
export const eventPlagiarismDocumentPath = "Event/PlagiarismDocument";
export const reportPath = "Report";
export const figuresPath = "Figures";
export const speakerUploadedFile = "speakerattachment";
export const welcomeVideo = "DocumentVideo";
export const welcomeMessage = "DocumentImage";
export const speakerPost = "speakerPost";
export const commonFile = "commonFile";
export const advertisement = "Advertisement";
export const CVPath = "User/cv";
export const paymentAbstractPath = "Paymnet/PaymentProof";
export const paymentInvoice = "Paymnet/Invoice";
export const registrationPass = "passes";
export const reviewReport ="Event/PaperReviewReport"
export const tempEventAbstarctPath = "Event/TempAbstract";
export const newUserCertificate = "UserCertificate"

export const awardTypeOptions = [
  {
    value: "Best Paper Award",
  },
  {
    value: "Best Paper Presentation Award",
  },
  {
    value: "Women Excellence Award",
  },
  {
    value: "Young Researchers Award",
  },
  {
    value: "Career Contribution Award",
  },
  {
    value: "Student Contribution Award",
  },
  {
    value: "Outstanding Chapter Award",
  },
  {
    value: "IFERP Fellowship",
  },
  {
    value: "IFERP Distinguished Member Award",
  },
  {
    value: "IFERP Senior Member Award",
  },
  {
    value: "Outstanding Contribution to IFERP Award",
  },
  {
    value: "Ashok Jain Awards of Academic",
  },
  {
    value: "Research & Higher Education",
  },
  {
    value: "Smt. Sneha Lata Dash Awards for Scientific Excellence",
  },
];

export const memberType = [
  { value: "Chairperson" },
  {
    value: "Co - Chairperson",
  },
  {
    value: "General Secretary",
  },
  {
    value: "Executive Member (Membership Management)",
  },
  {
    value: "Executive Member (Guest Lecture & FDP)",
  },
  {
    value: "Executive Member (Chapter Activities)",
  },
  {
    value: "Executive Member (Newsletter - Treasurer)",
  },
  {
    value: "Executive Member (Women Centric Activities)",
  },
  {
    value: "Secretary",
  },
  {
    value: "Professional Ambassador",
  },
  {
    value: "Student Ambassador",
  },
  {
    value: "Advisory Board Members",
  },
  {
    value: "Executive Committee Member (Scientific Conferences & Events)",
  },
];

export const eventMode = [
  { value: "Physical" },
  {
    value: "Virtual",
  },
  // {
  //   value: "Hybrid",
  // },
];

export const virtualPlatform = [
  { value: "Zoom" },
  {
    value: "Google Meet",
  },
];
export const eventType = [
  { value: "Event" },
  {
    value: "Conference",
  },
];
export const journalType = [
  { url: "scopus-indexed-journals", value: "SCOPUS Indexed Journals" },
  {
    url: "web-of-science-journals",
    value: "Web of Science Journals",
  },
  {
    url: "ugc-journals",
    value: "UGC Journals",
  },
  {
    url: "google-scholar-journals",
    value: "Google Scholar Journals",
  },
];
export const asistanceType = [
  {
    id: "Artwork & Formatting - $ 80",
    value: "Artwork & Formatting",
    amount: 80,
  },
  {
    id: "Journal Selection - $ 100",
    value: "Journal Selection",
    amount: 100,
  },
  {
    id: "Editing - $ 90",
    value: "Editing",
    amount: 90,
  },
  {
    id: "Journal Submission - $ 120",
    value: "Journal Submission",
    amount: 120,
  },
  {
    id: "Plagiarism Check - $ 89",
    value: "Plagiarism Check",
    amount: 89,
  },
];
export const statusType = [
  { id: "0", label: "Pending" },
  { id: "1", label: "Accepted" },
  { id: "2", label: "Rejected" },
];
export const membershipType = [
  {
    id: "0",
    title: "Admin",
    description: "",
    type: "admin",
  },
  {
    id: "1",
    title: "Super Admin",
    description: "",
    type: "super-admin",
  },
  {
    id: "2",
    title: "Professional Member",
    description: "Academicians, Researchers, Corporate individuals",
    type: "professional",
  },
  {
    id: "5",
    title: "Student Member",
    description: "UG, PG Students",
    type: "student",
  },
  {
    id: "3",
    title: "Institutional Member",
    description: "Universities/Colleges/Institutions",
    type: "institutional",
  },
  {
    id: "4",
    title: "Corporate Member",
    description: "Corporates/Organizations",
    type: "corporate",
  },
  {
    id: "6",
    title: "Resource Member",
    description: "UG, PG Students",
    type: "resource",
  },
  {
    id: "7",
    title: "Accounts",
    description: "",
    type: "accounts",
  },
];
export const monthNames = [
  { label: "January", id: "01" },
  { label: "February", id: "02" },
  { label: "March", id: "03" },
  { label: "April", id: "04" },
  { label: "May", id: "05" },
  { label: "June", id: "06" },
  { label: "July", id: "07" },
  { label: "August", id: "08" },
  { label: "September", id: "09" },
  { label: "October", id: "10" },
  { label: "November", id: "11" },
  { label: "December", id: "12" },
];
export const resourceType = [
  {
    id: "1",
    label: "Membership",
  },
  {
    id: "2",
    label: "Conference Co-ordinator",
  },
];

export const researchType = [
  { id: "Researcher ID", label: "Researcher ID" },
  { id: "Vidwan-ID", label: "Vidwan-ID" },
  { id: "Orcid - ID", label: "Orcid - ID" },
  { id: "Scopus Author ID", label: "Scopus Author ID" },
  { id: "Google Scholar", label: "Google Scholar" },
  { id: "Publons ID", label: "Publons ID" },
  {
    id: "Linked-in-Profile",
    label: "Linked-in-Profile",
  },
];

export const postAs = [
  {
    id: "2",
    label:
      "Both as public and private (everyone can access, also save the post as private where you can access later)",
  },
  {
    id: "0",
    label: "Only as public post (Everyone can access and read)",
  },
  {
    id: "1",
    label:
      "Only as private file (Save private file as backup where only can access)",
  },
];

export const certificateField = [
  {
    id: "user_name",
    label: "Name (First name and Last Name)",
    exText: "John Doe",
    // exText: "Chetandra Pratap Singh Chauhan",
  },
  {
    id: "first_name",
    label: "First Name",
    exText: "John",
  },
  {
    id: "last_name",
    label: "Last Name",
    exText: "Doe",
  },
  {
    id: "start_date",
    label: "From Date",
    exText: "21-07-2023",
  },
  {
    id: "expire_date",
    label: "To Date",
    exText: "15-10-2023",
  },
  {
    id: "date_of_issue",
    label: "Date Of Issue",
    exText: "10-12-2023",
  },
  {
    id: "nationality",
    label: "Nationality",
    exText: "India",
  },
  {
    id: "membership_id",
    label: "Membership ID",
    exText: "PROF-0707",
  },
  {
    id: "member_type",
    label: "Member Type",
    exText: "Proffetional",
  },
  {
    id: "institution_or_company",
    label: "Institution Name Or Company Name",
    exText: "Aditya College of Engineering",
  },
  {
    id: "cert_no",
    label: "Certificate Number",
    exText: "IFERP2023_2307_ICRAT_202354",
  },
  {
    id: "paper_title",
    label: "Paper Name",
    exText:
      "International Conference on Digital Transformation A strategic approach towards Sustainable Development - (ICDTSD-2022)",
  },
];

export const speechCategory = [
  { value: "Session Speaker", id: "0" },
  { value: "Keynote Speaker", id: "1" },
];
export const eventName = [{ value: "demo event" }, { value: "testing event" }];

export const menteesStatus = [
  { value: "Upcoming - Accepted", id: { status: 0, is_approve: 1 } },
  { value: "Upcoming - Pending", id: { status: 0, is_approve: 0 } },
  { value: "Completed", id: { status: 1, is_approve: "" } },
];

export const myseduleStatus = [
  { value: "Upcoming - Accepted", id: { status: 0, is_approve: 1 } },
  { value: "Upcoming - Pending", id: { status: 0, is_approve: 0 } },
  { value: "Completed", id: { status: 1, is_approve: "" } },
  { value: "Rejected", id: { status: 0, is_approve: 2 } },
  { value: "Show All", id: { status: "", is_approve: "" } },
];

export const paymentStatus = [
  { id: "1", label: "Completed" },
  { id: "2", label: "Refunded" },
];

export const searchOptions = [
  { value: "Mentor Name", id: "mentor_name" },
  { value: "Session Name", id: "session_name" },
];

export const timeDurations = [
  { label: "30 Mins", id: "30", unit: "M" },
  { label: "60 Mins", id: "1", unit: "H" },
];

export const startTimeList = [
  { value: "01:00 AM", id: "01:00" },
  { value: "02:00 AM", id: "02:00" },
  { value: "03:00 AM", id: "03:00" },
  { value: "04:00 AM", id: "04:00" },
  { value: "05:00 AM", id: "05:00" },
  { value: "06:00 AM", id: "06:00" },
  { value: "07:00 AM", id: "07:00" },
  { value: "08:00 AM", id: "08:00" },
  { value: "09:00 AM", id: "09:00" },
  { value: "10:00 AM", id: "10:00" },
  { value: "11:00 AM", id: "11:00" },
  { value: "12:00 PM", id: "12:00" },
  { value: "13:00 PM", id: "13:00" },
  { value: "14:00 PM", id: "14:00" },
  { value: "15:00 PM", id: "15:00" },
  { value: "16:00 PM", id: "16:00" },
  { value: "17:00 PM", id: "17:00" },
  { value: "18:00 PM", id: "18:00" },
  { value: "19:00 PM", id: "19:00" },
  { value: "20:00 PM", id: "20:00" },
  { value: "21:00 PM", id: "21:00" },
  { value: "22:00 PM", id: "22:00" },
  { value: "23:00 PM", id: "23:00" },
  { value: "00:00 AM", id: "00:00" },
];
export const endTimeList = [
  { value: "01:00 AM", id: "01:00" },
  { value: "02:00 AM", id: "02:00" },
  { value: "03:00 AM", id: "03:00" },
  { value: "04:00 AM", id: "04:00" },
  { value: "05:00 AM", id: "05:00" },
  { value: "06:00 AM", id: "06:00" },
  { value: "07:00 AM", id: "07:00" },
  { value: "08:00 AM", id: "08:00" },
  { value: "09:00 AM", id: "09:00" },
  { value: "10:00 AM", id: "10:00" },
  { value: "11:00 AM", id: "11:00" },
  { value: "12:00 PM", id: "12:00" },
  { value: "13:00 PM", id: "13:00" },
  { value: "14:00 PM", id: "14:00" },
  { value: "15:00 PM", id: "15:00" },
  { value: "16:00 PM", id: "16:00" },
  { value: "17:00 PM", id: "17:00" },
  { value: "18:00 PM", id: "18:00" },
  { value: "19:00 PM", id: "19:00" },
  { value: "20:00 PM", id: "20:00" },
  { value: "21:00 PM", id: "21:00" },
  { value: "22:00 PM", id: "22:00" },
  { value: "23:00 PM", id: "23:00" },
  { value: "00:00 AM", id: "00:00" },
];

export const bankAccountType = [
  { value: "Savings", id: "saving" },
  { value: "Current", id: "current" },
];

export const userAlertPaymentMessage =
  "IFERP doesn’t share your bank account details with anyone ";

export const moduleData = [
  //professional
  { route: "/professional/dashboard", moduleName: "Dashboard" },
  {
    route: "/professional/dashboard/live-events",
    moduleName: "Dashboard",
    subModuleName: "Upcoming Even/Live Event",
  },
  //renew
  {
    route: "/professional/renew",
    moduleName: "Renew Membership",
  },
  //cc-avenue
  {
    route: "/member/cc-avenue-payment",
    moduleName: "CC Avenue Payment",
  },
  //my details
  {
    route: "/professional/my-details",
    moduleName: "My Details",
  },
  //change password
  {
    route: "/professional/change-password",
    moduleName: "Change Password",
  },
  //My Profile
  {
    route: "/professional/my-profile",
    moduleName: "My Profile",
  },
  {
    route: "/professional/my-profile/research-profile",
    moduleName: "Research Curriculum Vitae",
  },
  {
    route: "/professional/my-profile/my-membership",
    moduleName: "My Membership",
  },
  {
    route: "/professional/my-profile/my-events",
    moduleName: "My Events",
  },
  {
    route: "/professional/my-profile/my-projects",
    moduleName: "My Projects/Tasks",
  },
  //Conference & Events
  {
    route: "/professional/conferences-and-events",
    moduleName: "Conference & Events",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/conference",
    moduleName: "Iferp Events",
    subModuleName: "Conference",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/webinar",
    moduleName: "Iferp Events",
    subModuleName: "Webinar",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/workshop",
    moduleName: "Iferp Events",
    subModuleName: "Workshop",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/guest-lecture",
    moduleName: "Iferp Events",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/faculty-development-program",
    moduleName: "Iferp Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/competitions",
    moduleName: "Iferp Events",
    subModuleName: "Competitions",
  },
  {
    route:
      "/professional/conferences-and-events/iferp-events/event-list/hackathon",
    moduleName: "Iferp Events",
    subModuleName: "Hackathon",
  },

  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/conference",
    moduleName: "Institutional Events",
    subModuleName: "Conference",
  },
  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/webinar",
    moduleName: "Institutional Events",
    subModuleName: "Webinar",
  },
  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/workshop",
    moduleName: "Institutional Events",
    subModuleName: "Workshop",
  },
  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/guest-lecture",
    moduleName: "Institutional Events",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/faculty-development-program",
    moduleName: "Institutional Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/competitions",
    moduleName: "Institutional Events",
    subModuleName: "Competitions",
  },
  {
    route:
      "/professional/conferences-and-events/institutional-events/event-list/hackathon",
    moduleName: "Institutional Events",
    subModuleName: "Hackathon",
  },
  {
    route:
      "/professional/conferences-and-events/event-details/179/conference-details",
    moduleName: "Iferp Events",
    subModuleName: "Conference Details",
  },
  {
    route: "/professional/conferences-and-events/event-details/179/community",
    moduleName: "Iferp Events",
    subModuleName: "Community",
  },
  {
    route:
      "/professional/conferences-and-events/conference/179/abstract-submission/add-abstract-submission",
    moduleName: "Conference Details",
    subModuleName: "Abstract Submission",
  },
  //Publication
  {
    route: "/professional/publications",
    moduleName: "Publication",
  },
  {
    route: "/professional/publications/scopus-indexed-journals",
    moduleName: "Publication",
    subModuleName: "SCOPUS Indexed Journals",
  },
  {
    route: "/professional/publications/web-of-science-journals",
    moduleName: "Publication",
    subModuleName: "Web of Science Journals",
  },
  {
    route: "/professional/publications/ugc-journals",
    moduleName: "Publication",
    subModuleName: "Ugc Journals",
  },
  {
    route: "/professional/publications/google-scholar-journals",
    moduleName: "Publication",
    subModuleName: "Google Scholar Journals",
  },
  {
    route: "/professional/publications/submit-paper",
    moduleName: "Publication",
    subModuleName: "Submit Paper",
  },
  {
    route: "/professional/publications/scopus-indexed-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/professional/publications/web-of-science-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/professional/publications/ugc-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/professional/publications/google-scholar-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  //Inbox & Notification
  {
    route: "/professional/inbox-notifications",
    moduleName: "Inbox & Notification",
  },
  {
    route: "/professional/inbox-notifications/inbox",
    moduleName: "Inbox & Notification",
    subModuleName: "Inbox",
  },
  {
    route: "/professional/inbox-notifications/compose",
    moduleName: "Inbox & Notification",
    subModuleName: "Compose",
  },
  {
    route: "/professional/inbox-notifications/sent-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Sent Messages",
  },
  {
    route: "/professional/inbox-notifications/starred-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Starred Messages",
  },
  {
    route: "/professional/inbox-notifications/notifications",
    moduleName: "Inbox & Notification",
    subModuleName: "Notifications",
  },
  // My Post & Network
  {
    route: "/professional/network-management/network",
    moduleName: "My Post & Network",
  },
  {
    route: "/professional/network-management/network/posts/discover-posts",
    moduleName: "My Post & Network",
    subModuleName: "Discover Posts",
  },
  {
    route: "/professional/network-management/network/posts/my-posts",
    moduleName: "My Post & Network",
    subModuleName: "My Posts",
  },
  {
    route:
      "/professional/network-management/network/posts/our-institutional-posts",
    moduleName: "My Post & Network",
    subModuleName: "Our Institutional Posts",
  },
  {
    route: "/professional/network-management/network/posts/saved-posts",
    moduleName: "My Post & Network",
    subModuleName: "Saved Posts",
  },
  {
    route: "/professional/network-management/network/posts/pending-ownership",
    moduleName: "My Post & Network",
    subModuleName: "Pending Ownership",
  },
  {
    route: "/professional/network-management/network/post/post-details/",
    moduleName: "My Post & Network",
    subModuleName: "Post Details",
  },
  {
    route: "/professional/network-management/network/network/new-requests",
    moduleName: "My Post & Network",
    subModuleName: "New Requests",
  },
  {
    route: "/professional/network-management/network/network/sent-requests",
    moduleName: "My Post & Network",
    subModuleName: "Sent Requests",
  },
  {
    route: "/professional/network-management/network/network/follower",
    moduleName: "My Post & Network",
    subModuleName: "Follower",
  },
  {
    route: "/professional/network-management/network/network/following",
    moduleName: "My Post & Network",
    subModuleName: "Following",
  },
  {
    route: "/professional/network-management/network/groups/discover-groups",
    moduleName: "My Post & Network",
    subModuleName: "Discover Groups",
  },
  {
    route: "/professional/network-management/network/groups/my-groups",
    moduleName: "My Post & Network",
    subModuleName: "My Groups",
  },
  {
    route: "/professional/network-management/network/chat/message",
    moduleName: "My Post & Network",
    subModuleName: "Chat",
  },
  //Chapters & Groups
  {
    route: "/professional/chapters-groups",
    moduleName: "Chapters & Groups",
  },
  {
    route: "/professional/chapters-groups/chapters",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapters",
  },
  {
    route: "/professional/chapters-groups/chapters/29/event-people",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapter Events",
  },
  {
    route: "/professional/chapters-groups/event-details/174/conference-details",
    moduleName: "Chapters & Groups",
    subModuleName: "Conference Details",
  },
  {
    route:
      "/professional/chapters-groups/webinar/174/abstract-submission/add-abstract-submission",
    moduleName: "Chapters & Groups",
    subModuleName: "Abstract Submission",
  },
  {
    route: "/professional/chapters-groups/sig-groups",
    moduleName: "Chapters & Groups",
    subModuleName: "Special Interest Community",
  },
  {
    route: "/professional/chapters-groups/sig-groups/3/conference",
    moduleName: "Special Interest Community",
    subModuleName: "Conference",
  },
  {
    route: "/professional/chapters-groups/sig-groups/3/webinar",
    moduleName: "Special Interest Community",
    subModuleName: "Webinar",
  },
  {
    route: "/professional/chapters-groups/sig-groups/3/workshop",
    moduleName: "Special Interest Community",
    subModuleName: "Workshop",
  },
  {
    route: "/professional/chapters-groups/sig-groups/3/guest-lecture",
    moduleName: "Special Interest Community",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/professional/chapters-groups/sig-groups/3/faculty-development-program",
    moduleName: "Special Interest Community",
    subModuleName: "Faculty Development Program",
  },
  {
    route: "/professional/chapters-groups/sig-groups/3/competitions",
    moduleName: "Special Interest Community",
    subModuleName: "Competitions",
  },
  {
    route: "/professional/chapters-groups/sig-groups/3/hackathon",
    moduleName: "Special Interest Community",
    subModuleName: "Hackathon",
  },

  // career
  {
    route: "/professional/career-support/careers",
    moduleName: "Career Supports",
  },
  {
    route: "/professional/career-support/1/events",
    moduleName: "Career Supports",
    subModuleName: "Industrial Visits",
  },
  {
    route: "/professional/career-support/3/events",
    moduleName: "Career Supports",
    subModuleName: "Internship Support",
  },
  {
    route: "/professional/career-support/5/5",
    moduleName: "Career Supports",
    subModuleName: "Apply For Patent Filing Assistance",
  },
  {
    route: "/professional/career-support/6/6",
    moduleName: "Career Supports",
    subModuleName: "Become A Keynote Speaker",
  },
  {
    route: "/professional/career-support/7/7",
    moduleName: "Career Supports",
    subModuleName: "Apply For Committee Member",
  },
  {
    route: "/professional/career-support/8/8",
    moduleName: "Career Supports",
    subModuleName: "Editorial Board Member/Reviewer",
  },
  {
    route: "/professional/career-support/9/9",
    moduleName: "Career Supports",
    subModuleName: "Become A Session Chair",
  },
  {
    route: "/professional/career-support/10/10",
    moduleName: "Career Supports",
    subModuleName: "Opportunity For Book Writing",
  },
  {
    route: "/professional/career-support/11/11",
    moduleName: "Career Supports",
    subModuleName: "Proposal Writing Assistance for DST/SERB/AICTE",
  },
  {
    route: "/professional/career-support/12/12",
    moduleName: "Career Supports",
    subModuleName: "People In The News",
  },
  {
    route: "/professional/career-support/13/13",
    moduleName: "Career Supports",
    subModuleName: "Research Enhancement",
  },
  {
    route: "/professional/career-support/15/15",
    moduleName: "Career Supports",
    subModuleName: "Funds & Grants",
  },
  {
    route: "/professional/career-support/applied-career-support",
    moduleName: "Career Supports",
    subModuleName: "Applied Career Support",
  },
  {
    route:
      "/professional/career-support/applied-career-support/keynote-speaker",
    moduleName: "Career Supports",
    subModuleName: "My Speaker Applications",
  },
  {
    route:
      "/professional/career-support/applied-career-support/keynote-speaker/speaker-applications/419",
    moduleName: "Career Supports",
    subModuleName: "My Speaker Applications Details",
  },
  {
    route:
      "/professional/career-support/applied-career-support/keynote-speaker/invitation-received/589",
    moduleName: "Career Supports",
    subModuleName: "Invitations Received Details",
  },
  //mentor
  {
    route: "/professional/mentorship/mentee",
    moduleName: "Mentorship",
  },
  {
    route: "/professional/mentorship/mentee/all-mentors",
    moduleName: "Mentorship",
    subModuleName: "Mentee Profile",
  },
  {
    route: "/professional/mentorship/mentee/my-submissions",
    moduleName: "Mentorship",
    subModuleName: "My Meetings",
  },
  {
    route: "/professional/mentorship/mentee/book-session/",
    moduleName: "Mentorship",
    subModuleName: "Session Booking",
  },
  {
    route: "/professional/mentorship/mentee/cardInformation/",
    moduleName: "Mentorship",
    subModuleName: "Session Booking Payment",
  },
  {
    route: "/professional/mentorship/mentor",
    moduleName: "Mentorship",
    subModuleName: "Mentor Profile",
  },
  {
    route: "/professional/mentorship/mentor/add-new-session",
    moduleName: "Mentor Profile",
    subModuleName: "Add Session",
  },
  {
    route: "/professional/mentorship/mentor/details/",
    moduleName: "Mentor Profile",
    subModuleName: "Session Details",
  },
  {
    route: "/professional/mentorship/mentor/edit-new-session",
    moduleName: "Mentor Profile",
    subModuleName: "Edit Session",
  },
  //nominate for award
  {
    route: "/professional/nominate-for-award",
    moduleName: "Nominate For Award",
  },
  //iferp digital library
  {
    route: "/professional/digital-library",
    moduleName: "IFERP Digital Library",
  },
  // certificate & rewards
  {
    route: "/professional/certificates-and-rewards",
    moduleName: "Certificate & Rewards",
  },
  //awards winners
  {
    route: "/professional/award-winners",
    moduleName: "Awards Winners",
  },
  //help & support
  {
    route: "/professional/help",
    moduleName: "Help & Support",
  },
  {
    route: "/professional/help/help-and-support",
    moduleName: "Help & Support",
    subModuleName: "Need Help",
  },
  {
    route: "/professional/help/my-personal-executive",
    moduleName: "Help & Support",
    subModuleName: "My Personal Customer Service Executive",
  },

  //student
  { route: "/student/dashboard", moduleName: "Dashboard" },
  {
    route: "/student/dashboard/live-events",
    moduleName: "Dashboard",
    subModuleName: "Upcoming Even/Live Event",
  },
  //renew
  {
    route: "/student/renew",
    moduleName: "Renew Membership",
  },
  //my details
  {
    route: "/student/my-details",
    moduleName: "My Details",
  },
  //change password
  {
    route: "/student/change-password",
    moduleName: "Change Password",
  },
  //My Profile
  {
    route: "/student/my-profile",
    moduleName: "My Profile",
  },
  {
    route: "/student/my-profile/research-profile",
    moduleName: "Research Curriculum Vitae",
  },
  {
    route: "/student/my-profile/my-membership",
    moduleName: "My Membership",
  },
  {
    route: "/student/my-profile/my-events",
    moduleName: "My Events",
  },
  {
    route: "/student/my-profile/my-projects",
    moduleName: "My Projects/Tasks",
  },
  //Conference & Events
  {
    route: "/student/conferences-and-events",
    moduleName: "Conference & Events",
  },
  {
    route: "/student/conferences-and-events/iferp-events/event-list/conference",
    moduleName: "Iferp Events",
    subModuleName: "Conference",
  },
  {
    route: "/student/conferences-and-events/iferp-events/event-list/webinar",
    moduleName: "Iferp Events",
    subModuleName: "Webinar",
  },
  {
    route: "/student/conferences-and-events/iferp-events/event-list/workshop",
    moduleName: "Iferp Events",
    subModuleName: "Workshop",
  },
  {
    route:
      "/student/conferences-and-events/iferp-events/event-list/guest-lecture",
    moduleName: "Iferp Events",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/student/conferences-and-events/iferp-events/event-list/faculty-development-program",
    moduleName: "Iferp Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route:
      "/student/conferences-and-events/iferp-events/event-list/competitions",
    moduleName: "Iferp Events",
    subModuleName: "Competitions",
  },
  {
    route: "/student/conferences-and-events/iferp-events/event-list/hackathon",
    moduleName: "Iferp Events",
    subModuleName: "Hackathon",
  },

  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/conference",
    moduleName: "Institutional Events",
    subModuleName: "Conference",
  },
  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/webinar",
    moduleName: "Institutional Events",
    subModuleName: "Webinar",
  },
  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/workshop",
    moduleName: "Institutional Events",
    subModuleName: "Workshop",
  },
  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/guest-lecture",
    moduleName: "Institutional Events",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/faculty-development-program",
    moduleName: "Institutional Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/competitions",
    moduleName: "Institutional Events",
    subModuleName: "Competitions",
  },
  {
    route:
      "/student/conferences-and-events/institutional-events/event-list/hackathon",
    moduleName: "Institutional Events",
    subModuleName: "Hackathon",
  },
  {
    route:
      "/student/conferences-and-events/event-details/179/conference-details",
    moduleName: "Iferp Events",
    subModuleName: "Conference Details",
  },
  {
    route: "/student/conferences-and-events/event-details/179/community",
    moduleName: "Iferp Events",
    subModuleName: "Community",
  },
  {
    route:
      "/student/conferences-and-events/conference/179/abstract-submission/add-abstract-submission",
    moduleName: "Conference Details",
    subModuleName: "Abstract Submission",
  },
  //Publication
  {
    route: "/student/publications",
    moduleName: "Publication",
  },
  {
    route: "/student/publications/scopus-indexed-journals",
    moduleName: "Publication",
    subModuleName: "SCOPUS Indexed Journals",
  },
  {
    route: "/student/publications/web-of-science-journals",
    moduleName: "Publication",
    subModuleName: "Web of Science Journals",
  },
  {
    route: "/student/publications/ugc-journals",
    moduleName: "Publication",
    subModuleName: "Ugc Journals",
  },
  {
    route: "/student/publications/google-scholar-journals",
    moduleName: "Publication",
    subModuleName: "Google Scholar Journals",
  },
  {
    route: "/student/publications/submit-paper",
    moduleName: "Publication",
    subModuleName: "Submit Paper",
  },
  {
    route: "/student/publications/scopus-indexed-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/student/publications/web-of-science-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/student/publications/ugc-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/student/publications/google-scholar-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/student/publications/publicationAssistance",
    moduleName: "Publication",
    subModuleName: "Publication Assistance",
  },
  //Inbox & Notification
  {
    route: "/student/inbox-notifications",
    moduleName: "Inbox & Notification",
  },
  {
    route: "/student/inbox-notifications/inbox",
    moduleName: "Inbox & Notification",
    subModuleName: "Inbox",
  },
  {
    route: "/student/inbox-notifications/compose",
    moduleName: "Inbox & Notification",
    subModuleName: "Compose",
  },
  {
    route: "/student/inbox-notifications/sent-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Sent Messages",
  },
  {
    route: "/student/inbox-notifications/starred-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Starred Messages",
  },
  {
    route: "/student/inbox-notifications/notifications",
    moduleName: "Inbox & Notification",
    subModuleName: "Notifications",
  },
  // My Post & Network
  {
    route: "/student/network-management/network",
    moduleName: "My Post & Network",
  },
  {
    route: "/student/network-management/network/posts/discover-posts",
    moduleName: "My Post & Network",
    subModuleName: "Discover Posts",
  },
  {
    route: "/student/network-management/network/posts/my-posts",
    moduleName: "My Post & Network",
    subModuleName: "My Posts",
  },
  {
    route: "/student/network-management/network/posts/our-institutional-posts",
    moduleName: "My Post & Network",
    subModuleName: "Our Institutional Posts",
  },
  {
    route: "/student/network-management/network/posts/saved-posts",
    moduleName: "My Post & Network",
    subModuleName: "Saved Posts",
  },
  {
    route: "/student/network-management/network/posts/pending-ownership",
    moduleName: "My Post & Network",
    subModuleName: "Pending Ownership",
  },
  {
    route: "/student/network-management/network/post/post-details/",
    moduleName: "My Post & Network",
    subModuleName: "Post Details",
  },
  {
    route: "/student/network-management/network/network/new-requests",
    moduleName: "My Post & Network",
    subModuleName: "New Requests",
  },
  {
    route: "/student/network-management/network/network/sent-requests",
    moduleName: "My Post & Network",
    subModuleName: "Sent Requests",
  },
  {
    route: "/student/network-management/network/network/follower",
    moduleName: "My Post & Network",
    subModuleName: "Follower",
  },
  {
    route: "/student/network-management/network/network/following",
    moduleName: "My Post & Network",
    subModuleName: "Following",
  },
  {
    route: "/student/network-management/network/groups/discover-groups",
    moduleName: "My Post & Network",
    subModuleName: "Discover Groups",
  },
  {
    route: "/student/network-management/network/groups/my-groups",
    moduleName: "My Post & Network",
    subModuleName: "My Groups",
  },
  {
    route: "/student/network-management/network/chat/message",
    moduleName: "My Post & Network",
    subModuleName: "Chat",
  },
  //Chapters & Groups
  {
    route: "/student/chapters-groups",
    moduleName: "Chapters & Groups",
  },
  {
    route: "/student/chapters-groups/chapters",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapters",
  },
  {
    route: "/student/chapters-groups/chapters/29/event-people",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapter Events",
  },
  {
    route: "/student/chapters-groups/event-details/174/conference-details",
    moduleName: "Chapters & Groups",
    subModuleName: "Conference Details",
  },
  {
    route:
      "/student/chapters-groups/webinar/174/abstract-submission/add-abstract-submission",
    moduleName: "Chapters & Groups",
    subModuleName: "Abstract Submission",
  },
  {
    route: "/student/chapters-groups/sig-groups",
    moduleName: "Chapters & Groups",
    subModuleName: "Special Interest Community",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/conference",
    moduleName: "Special Interest Community",
    subModuleName: "Conference",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/webinar",
    moduleName: "Special Interest Community",
    subModuleName: "Webinar",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/workshop",
    moduleName: "Special Interest Community",
    subModuleName: "Workshop",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/guest-lecture",
    moduleName: "Special Interest Community",
    subModuleName: "Guest Lecture",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/faculty-development-program",
    moduleName: "Special Interest Community",
    subModuleName: "Faculty Development Program",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/competitions",
    moduleName: "Special Interest Community",
    subModuleName: "Competitions",
  },
  {
    route: "/student/chapters-groups/sig-groups/3/hackathon",
    moduleName: "Special Interest Community",
    subModuleName: "Hackathon",
  },

  // career
  {
    route: "/student/career-support/careers",
    moduleName: "Career Supports",
  },
  {
    route: "/student/career-support/1/events",
    moduleName: "Career Supports",
    subModuleName: "Industrial Visits",
  },
  {
    route: "/student/career-support/3/events",
    moduleName: "Career Supports",
    subModuleName: "Internship Support",
  },
  {
    route: "/student/career-support/5/5",
    moduleName: "Career Supports",
    subModuleName: "Apply For Patent Filing Assistance",
  },
  {
    route: "/student/career-support/6/6",
    moduleName: "Career Supports",
    subModuleName: "Become A Keynote Speaker",
  },
  {
    route: "/student/career-support/7/7",
    moduleName: "Career Supports",
    subModuleName: "Apply For Committee Member",
  },
  {
    route: "/student/career-support/8/8",
    moduleName: "Career Supports",
    subModuleName: "Editorial Board Member/Reviewer",
  },
  {
    route: "/student/career-support/9/9",
    moduleName: "Career Supports",
    subModuleName: "Become A Session Chair",
  },
  {
    route: "/student/career-support/10/10",
    moduleName: "Career Supports",
    subModuleName: "Opportunity For Book Writing",
  },
  {
    route: "/student/career-support/11/11",
    moduleName: "Career Supports",
    subModuleName: "Proposal Writing Assistance for DST/SERB/AICTE",
  },
  {
    route: "/student/career-support/12/12",
    moduleName: "Career Supports",
    subModuleName: "People In The News",
  },
  {
    route: "/student/career-support/13/13",
    moduleName: "Career Supports",
    subModuleName: "Research Enhancement",
  },
  {
    route: "/student/career-support/15/15",
    moduleName: "Career Supports",
    subModuleName: "Funds & Grants",
  },
  {
    route: "/student/career-support/applied-career-support",
    moduleName: "Career Supports",
    subModuleName: "Applied Career Support",
  },
  {
    route: "/student/career-support/applied-career-support/keynote-speaker",
    moduleName: "Career Supports",
    subModuleName: "My Speaker Applications",
  },
  {
    route:
      "/student/career-support/applied-career-support/keynote-speaker/speaker-applications/419",
    moduleName: "Career Supports",
    subModuleName: "My Speaker Applications Details",
  },
  {
    route:
      "/student/career-support/applied-career-support/keynote-speaker/invitation-received/589",
    moduleName: "Career Supports",
    subModuleName: "Invitations Received Details",
  },
  //mentor
  {
    route: "/student/mentorship/mentee",
    moduleName: "Mentorship",
  },
  {
    route: "/student/mentorship/mentee/all-mentors",
    moduleName: "Mentorship",
    subModuleName: "All Mentors",
  },
  {
    route: "/student/mentorship/mentee/my-submissions",
    moduleName: "Mentorship",
    subModuleName: "My Meetings",
  },
  {
    route: "/student/mentorship/mentee/book-session/",
    moduleName: "Mentorship",
    subModuleName: "Session Booking",
  },
  {
    route: "/student/mentorship/mentee/cardInformation/",
    moduleName: "Mentorship",
    subModuleName: "Session Booking Payment",
  },

  //nominate for award
  {
    route: "/student/nominate-for-award",
    moduleName: "Nominate For Award",
  },
  //iferp digital library
  {
    route: "/student/digital-library",
    moduleName: "IFERP Digital Library",
  },
  // certificate & rewards
  {
    route: "/student/certificates-and-rewards",
    moduleName: "Certificate & Rewards",
  },
  //awards winners
  {
    route: "/student/award-winners",
    moduleName: "Awards Winners",
  },
  //help & support
  {
    route: "/student/help",
    moduleName: "Help & Support",
  },
  {
    route: "/student/help/help-and-support",
    moduleName: "Help & Support",
    subModuleName: "Need Help",
  },
  {
    route: "/student/help/my-personal-executive",
    moduleName: "Help & Support",
    subModuleName: "My Personal Customer Service Executive",
  },

  //institutional
  { route: "/institutional/dashboard", moduleName: "Dashboard" },
  {
    route: "/institutional/dashboard/live-events",
    moduleName: "Dashboard",
    subModuleName: "Upcoming Even/Live Event",
  },
  //renew
  {
    route: "/institutional/renew",
    moduleName: "Renew Membership",
  },
  //my details
  {
    route: "/institutional/my-details",
    moduleName: "My Details",
  },
  //change password
  {
    route: "/institutional/change-password",
    moduleName: "Change Password",
  },
  //Our Profile

  {
    route: "/institutional/my-profile/my-membership",
    moduleName: "Our Profile",
    subModuleName: "My Membership",
  },
  {
    route: "/institutional/my-profile/my-events",
    moduleName: "Our Profile",
    subModuleName: "Our Events",
  },
  {
    route: "/institutional/my-profile/our-performance-report",
    moduleName: "Our Profile",
    subModuleName: "Our Performance Report",
  },

  //activity plan
  {
    route: "/institutional/activity-plan",
    moduleName: "Activity Plan",
  },
  {
    route: "/institutional/activity-plan/iferp-plan",
    moduleName: "Activity Plan",
    subModuleName: "IFERP Plan",
  },
  {
    route: "/institutional/activity-plan/activity-plan/iferp-plan/quarter1",
    moduleName: "Activity Plan",
    subModuleName: "IFERP Quarter 1 Plan",
  },
  {
    route: "/institutional/activity-plan/event-details/168/conference-details",
    moduleName: "IFERP Quarter 1 Plan",
    subModuleName: "Conference Details",
  },
  {
    route: "/institutional/activity-plan/event-details/168/agenda",
    moduleName: "IFERP Quarter 1 Plan",
    subModuleName: "Agenda",
  },
  {
    route: "/institutional/activity-plan/event-details/168/community",
    moduleName: "IFERP Quarter 1 Plan",
    subModuleName: "Community",
  },
  {
    route: "/institutional/activity-plan/event-details/168/conference-details",
    moduleName: "IFERP Quarter 2 Plan",
    subModuleName: "Conference Details",
  },
  {
    route: "/institutional/activity-plan/event-details/168/agenda",
    moduleName: "IFERP Quarter 2 Plan",
    subModuleName: "Agenda",
  },
  {
    route: "/institutional/activity-plan/event-details/168/community",
    moduleName: "IFERP Quarter 2 Plan",
    subModuleName: "Community",
  },
  {
    route: "/institutional/activity-plan/event-details/168/conference-details",
    moduleName: "IFERP Quarter 3 Plan",
    subModuleName: "Conference Details",
  },
  {
    route: "/institutional/activity-plan/event-details/168/agenda",
    moduleName: "IFERP Quarter 3 Plan",
    subModuleName: "Agenda",
  },
  {
    route: "/institutional/activity-plan/event-details/168/community",
    moduleName: "IFERP Quarter 3 Plan",
    subModuleName: "Community",
  },
  {
    route: "/institutional/activity-plan/event-details/168/conference-details",
    moduleName: "IFERP Quarter 4 Plan",
    subModuleName: "Conference Details",
  },
  {
    route: "/institutional/activity-plan/event-details/168/agenda",
    moduleName: "IFERP Quarter 4 Plan",
    subModuleName: "Agenda",
  },
  {
    route: "/institutional/activity-plan/event-details/168/community",
    moduleName: "IFERP Quarter 4 Plan",
    subModuleName: "Community",
  },

  {
    route: "/institutional/activity-plan/institutional-plan",
    moduleName: "Activity Plan",
    subModuleName: "Institutional Plan",
  },
  {
    route: "/institutional/activity-plan/institutional-plan/add-event",
    moduleName: "Institutional Plan",
    subModuleName: "Add Event",
  },

  {
    route: "/institutional/activity-plan/activity-reports",
    moduleName: "Activity Plan",
    subModuleName: "Activity Reports",
  },

  //our academicians
  {
    route: "/institutional/our-academicians",
    moduleName: "Our Academicians",
  },
  {
    route: "/institutional/our-academicians/student-members",
    moduleName: "Our Academicians",
    subModuleName: "Student Members",
  },
  {
    route: "/institutional/our-academicians/faculty-members",
    moduleName: "Our Academicians",
    subModuleName: "Faculty Members",
  },

  //Conference & Events
  {
    route: "/institutional/conferences-and-events",
    moduleName: "Conference & Events",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/conference",
    moduleName: "Iferp Events",
    subModuleName: "Conference",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/webinar",
    moduleName: "Iferp Events",
    subModuleName: "Webinar",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/workshop",
    moduleName: "Iferp Events",
    subModuleName: "Workshop",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/guest-lecture",
    moduleName: "Iferp Events",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/faculty-development-program",
    moduleName: "Iferp Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/competitions",
    moduleName: "Iferp Events",
    subModuleName: "Competitions",
  },
  {
    route:
      "/institutional/conferences-and-events/iferp-events/event-list/hackathon",
    moduleName: "Iferp Events",
    subModuleName: "Hackathon",
  },

  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/conference",
    moduleName: "Institutional Events",
    subModuleName: "Conference",
  },
  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/webinar",
    moduleName: "Institutional Events",
    subModuleName: "Webinar",
  },
  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/workshop",
    moduleName: "Institutional Events",
    subModuleName: "Workshop",
  },
  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/guest-lecture",
    moduleName: "Institutional Events",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/faculty-development-program",
    moduleName: "Institutional Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/competitions",
    moduleName: "Institutional Events",
    subModuleName: "Competitions",
  },
  {
    route:
      "/institutional/conferences-and-events/institutional-events/event-list/hackathon",
    moduleName: "Institutional Events",
    subModuleName: "Hackathon",
  },
  {
    route:
      "/institutional/conferences-and-events/event-details/179/conference-details",
    moduleName: "Iferp Events",
    subModuleName: "Conference Details",
  },
  {
    route: "/institutional/conferences-and-events/event-details/179/community",
    moduleName: "Iferp Events",
    subModuleName: "Community",
  },
  {
    route:
      "/institutional/conferences-and-events/conference/179/abstract-submission/add-abstract-submission",
    moduleName: "Conference Details",
    subModuleName: "Abstract Submission",
  },
  //Publication
  {
    route: "/institutional/publications",
    moduleName: "Publication",
  },
  {
    route: "/institutional/publications/scopus-indexed-journals",
    moduleName: "Publication",
    subModuleName: "SCOPUS Indexed Journals",
  },
  {
    route: "/institutional/publications/web-of-science-journals",
    moduleName: "Publication",
    subModuleName: "Web of Science Journals",
  },
  {
    route: "/institutional/publications/ugc-journals",
    moduleName: "Publication",
    subModuleName: "Ugc Journals",
  },
  {
    route: "/institutional/publications/google-scholar-journals",
    moduleName: "Publication",
    subModuleName: "Google Scholar Journals",
  },
  {
    route: "/institutional/publications/submit-paper",
    moduleName: "Publication",
    subModuleName: "Submit Paper",
  },
  {
    route: "/institutional/publications/scopus-indexed-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/institutional/publications/web-of-science-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/institutional/publications/ugc-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/institutional/publications/google-scholar-journals/submit-paper/",
    moduleName: "Publication",
    subModuleName: "Submit Paper For Journal",
  },
  {
    route: "/institutional/publications/publicationAssistance",
    moduleName: "Publication",
    subModuleName: "Publication Assistance",
  },
  //Inbox & Notification
  {
    route: "/institutional/inbox-notifications",
    moduleName: "Inbox & Notification",
  },
  {
    route: "/institutional/inbox-notifications/inbox",
    moduleName: "Inbox & Notification",
    subModuleName: "Inbox",
  },
  {
    route: "/institutional/inbox-notifications/compose",
    moduleName: "Inbox & Notification",
    subModuleName: "Compose",
  },
  {
    route: "/institutional/inbox-notifications/sent-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Sent Messages",
  },
  {
    route: "/institutional/inbox-notifications/starred-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Starred Messages",
  },
  {
    route: "/institutional/inbox-notifications/notifications",
    moduleName: "Inbox & Notification",
    subModuleName: "Notifications",
  },
  // My Post & Network
  {
    route: "/institutional/network-management/network",
    moduleName: "My Post & Network",
  },
  {
    route: "/institutional/network-management/network/posts/discover-posts",
    moduleName: "My Post & Network",
    subModuleName: "Discover Posts",
  },
  {
    route: "/institutional/network-management/network/posts/my-posts",
    moduleName: "My Post & Network",
    subModuleName: "My Posts",
  },
  {
    route:
      "/institutional/network-management/network/posts/our-institutional-posts",
    moduleName: "My Post & Network",
    subModuleName: "Our Institutional Posts",
  },
  {
    route: "/institutional/network-management/network/posts/saved-posts",
    moduleName: "My Post & Network",
    subModuleName: "Saved Posts",
  },
  {
    route: "/institutional/network-management/network/posts/pending-ownership",
    moduleName: "My Post & Network",
    subModuleName: "Pending Ownership",
  },
  {
    route: "/institutional/network-management/network/post/post-details/",
    moduleName: "My Post & Network",
    subModuleName: "Post Details",
  },
  {
    route: "/institutional/network-management/network/network/new-requests",
    moduleName: "My Post & Network",
    subModuleName: "New Requests",
  },
  {
    route: "/institutional/network-management/network/network/sent-requests",
    moduleName: "My Post & Network",
    subModuleName: "Sent Requests",
  },
  {
    route: "/institutional/network-management/network/network/follower",
    moduleName: "My Post & Network",
    subModuleName: "Follower",
  },
  {
    route: "/institutional/network-management/network/network/following",
    moduleName: "My Post & Network",
    subModuleName: "Following",
  },
  {
    route: "/institutional/network-management/network/groups/discover-groups",
    moduleName: "My Post & Network",
    subModuleName: "Discover Groups",
  },
  {
    route: "/institutional/network-management/network/groups/my-groups",
    moduleName: "My Post & Network",
    subModuleName: "My Groups",
  },
  {
    route: "/institutional/network-management/network/chat/message",
    moduleName: "My Post & Network",
    subModuleName: "Chat",
  },
  //innovation ambassador
  {
    route: "/institutional/innovation-ambassador",
    moduleName: "Innovation Ambassador",
  },
  {
    route: "/institutional/innovation-ambassador/student-ambassadors",
    moduleName: "Innovation Ambassador",
    subModuleName: "Student Ambassadors",
  },
  {
    route: "/institutional/innovation-ambassador/faculty-ambassadors",
    moduleName: "Innovation Ambassador",
    subModuleName: "Faculty Ambassadors",
  },
  //Chapters & Groups
  {
    route: "/institutional/chapters-groups",
    moduleName: "Chapters & Groups",
  },
  {
    route: "/institutional/chapters-groups/chapters",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapters",
  },
  {
    route: "/institutional/chapters-groups/chapters/29/event-people",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapter Events",
  },
  {
    route:
      "/institutional/chapters-groups/event-details/174/conference-details",
    moduleName: "Chapters & Groups",
    subModuleName: "Conference Details",
  },
  {
    route:
      "/institutional/chapters-groups/webinar/174/abstract-submission/add-abstract-submission",
    moduleName: "Chapters & Groups",
    subModuleName: "Abstract Submission",
  },
  {
    route: "/institutional/chapters-groups/sig-groups",
    moduleName: "Chapters & Groups",
    subModuleName: "Special Interest Community",
  },
  {
    route: "/institutional/chapters-groups/sig-groups/3/conference",
    moduleName: "Special Interest Community",
    subModuleName: "Conference",
  },
  {
    route: "/institutional/chapters-groups/sig-groups/3/webinar",
    moduleName: "Special Interest Community",
    subModuleName: "Webinar",
  },
  {
    route: "/institutional/chapters-groups/sig-groups/3/workshop",
    moduleName: "Special Interest Community",
    subModuleName: "Workshop",
  },
  {
    route: "/institutional/chapters-groups/sig-groups/3/guest-lecture",
    moduleName: "Special Interest Community",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/institutional/chapters-groups/sig-groups/3/faculty-development-program",
    moduleName: "Special Interest Community",
    subModuleName: "Faculty Development Program",
  },
  {
    route: "/institutional/chapters-groups/sig-groups/3/competitions",
    moduleName: "Special Interest Community",
    subModuleName: "Competitions",
  },
  {
    route: "/institutional/chapters-groups/sig-groups/3/hackathon",
    moduleName: "Special Interest Community",
    subModuleName: "Hackathon",
  },

  // career
  {
    route: "/institutional/career-support/careers",
    moduleName: "Career Supports",
  },
  {
    route: "/institutional/career-support/1/events",
    moduleName: "Career Supports",
    subModuleName: "Industrial Visits",
  },
  {
    route: "/institutional/career-support/2/events",
    moduleName: "Career Supports",
    subModuleName: "Placement Training",
  },
  {
    route: "/institutional/career-support/3/events",
    moduleName: "Career Supports",
    subModuleName: "Internship Support",
  },
  {
    route: "/institutional/career-support/4/events",
    moduleName: "Career Supports",
    subModuleName: "IFERP Placement Drives",
  },
  {
    route: "/institutional/career-support/5/events/members",
    moduleName: "Career Supports",
    subModuleName: "Patent Filing Assistance",
  },
  {
    route: "/institutional/career-support/6/events/members",
    moduleName: "Career Supports",
    subModuleName: "Become A Keynote Speaker",
  },

  {
    route: "/institutional/career-support/14/14",
    moduleName: "Career Supports",
    subModuleName: "Center of Excellence (COE)",
  },
  {
    route: "/institutional/career-support/applied-career-support",
    moduleName: "Career Supports",
    subModuleName: "Applied Career Support",
  },
  {
    route:
      "/institutional/career-support/applied-career-support/keynote-speaker",
    moduleName: "Career Supports",
    subModuleName: "My Speaker Applications",
  },
  {
    route:
      "/institutional/career-support/applied-career-support/keynote-speaker/speaker-applications/419",
    moduleName: "Career Supports",
    subModuleName: "My Speaker Applications Details",
  },
  {
    route:
      "/institutional/career-support/applied-career-support/keynote-speaker/invitation-received/589",
    moduleName: "Career Supports",
    subModuleName: "Invitations Received Details",
  },

  //nominate for award
  {
    route: "/institutional/nominate-for-award-list",
    moduleName: "Nominate For Award",
  },

  // certificate & rewards
  {
    route: "/institutional/certificates-and-rewards-list",
    moduleName: "Certificate & Rewards",
  },
  //collaboration
  {
    route: "/institutional/help/collaboration",
    moduleName: "Collaboration",
  },
  //funds and grants
  {
    route: "/institutional/help/funds-and-grants",
    moduleName: "Funds And Grants",
  },
  {
    route: "/institutional/help/funds-and-grants/accepted-proposals",
    moduleName: "Funds And Grants",
    subModuleName: "Accepted Proposals",
  },
  {
    route: "/institutional/help/funds-and-grants/rejected-proposals",
    moduleName: "Funds And Grants",
    subModuleName: "Rejected Proposals",
  },
  {
    route: "/institutional/help/funds-and-grants/pending-requests",
    moduleName: "Funds And Grants",
    subModuleName: "Pending Requests",
  },
  //help & support
  {
    route: "/institutional/help/help-and-support",
    moduleName: "Help & Support",
  },
  //corporate
  { route: "/corporate/dashboard", moduleName: "Dashboard" },
  {
    route: "/corporate/dashboard/network/post/post-details/",
    moduleName: "Dashboard",
    subModuleName: "Post Detail",
  },
  //renew
  {
    route: "/corporate/renew",
    moduleName: "Renew Membership",
  },
  //my details
  {
    route: "/corporate/my-details",
    moduleName: "My Details",
  },
  //change password
  {
    route: "/corporate/change-password",
    moduleName: "Change Password",
  },
  //Our Profile

  {
    route: "/corporate/my-profile/my-membership",
    moduleName: "Our Profile",
    subModuleName: "My Membership",
  },
  {
    route: "/corporate/my-profile/my-events",
    moduleName: "Our Profile",
    subModuleName: "Our Events",
  },
  {
    route: "/corporate/my-profile/our-employees",
    moduleName: "Our Profile",
    subModuleName: "Our Employees",
  },

  //Conference & Events
  {
    route: "/corporate/conferences-and-events",
    moduleName: "Conference & Events",
  },
  {
    route: "/corporate/events/event-list/conference",
    moduleName: "Conference & Events",
    subModuleName: "Conference",
  },
  {
    route: "/corporate/events/event-list/webinar",
    moduleName: "Conference & Events",
    subModuleName: "Webinar",
  },
  {
    route: "/corporate/events/event-list/workshop",
    moduleName: "Conference & Events",
    subModuleName: "Workshop",
  },
  {
    route: "/corporate/events/event-list/guest-lecture",
    moduleName: "Conference & Events",
    subModuleName: "Guest Lecture",
  },
  {
    route: "/corporate/events/event-list/faculty-development-program",
    moduleName: "Conference & Events",
    subModuleName: "Faculty Development Program",
  },
  {
    route: "/corporate/events/event-list/competitions",
    moduleName: "Conference & Events",
    subModuleName: "Competitions",
  },
  {
    route: "/corporate/events/event-list/hackathon",
    moduleName: "Conference & Events",
    subModuleName: "Hackathon",
  },

  {
    route:
      "/corporate/conferences-and-events/event-details/179/conference-details",
    moduleName: "Iferp Events",
    subModuleName: "Conference Details",
  },
  {
    route: "/corporate/conferences-and-events/event-details/179/community",
    moduleName: "Iferp Events",
    subModuleName: "Community",
  },
  {
    route:
      "/corporate/conferences-and-events/conference/179/abstract-submission/add-abstract-submission",
    moduleName: "Conference Details",
    subModuleName: "Abstract Submission",
  },
  {
    route: "/corporate/events/submitted-abstract",
    moduleName: "Conference & Events",
    subModuleName: "Submitted Abstracts",
  },
  //Publication
  {
    route: "/corporate/publications",
    moduleName: "Publication",
  },
  {
    route: "/corporate/publications/scopus-indexed-journals",
    moduleName: "Publication",
    subModuleName: "SCOPUS Indexed Journals",
  },
  {
    route: "/corporate/publications/web-of-science-journals",
    moduleName: "Publication",
    subModuleName: "Web of Science Journals",
  },
  {
    route: "/corporate/publications/ugc-journals",
    moduleName: "Publication",
    subModuleName: "Ugc Journals",
  },
  {
    route: "/corporate/publications/google-scholar-journals",
    moduleName: "Publication",
    subModuleName: "Google Scholar Journals",
  },
  //branding & publicity
  {
    route: "/corporate/branding-and-publicity",
    moduleName: "Branding & Publicity",
  },
  {
    route: "/corporate/branding-and-publicity/1",
    moduleName: "Branding & Publicity",
    subModuleName: "Exhibition",
  },
  {
    route: "/corporate/branding-and-publicity/2",
    moduleName: "Branding & Publicity",
    subModuleName: "Brand Promotion",
  },
  {
    route: "/corporate/branding-and-publicity/3",
    moduleName: "Branding & Publicity",
    subModuleName: "Conduct Survey",
  },
  {
    route: "/corporate/branding-and-publicity/4",
    moduleName: "Branding & Publicity",
    subModuleName: "CSR Activity",
  },

  //Inbox & Notification
  {
    route: "/corporate/inbox-notifications",
    moduleName: "Inbox & Notification",
  },
  {
    route: "/corporate/inbox-notifications/inbox",
    moduleName: "Inbox & Notification",
    subModuleName: "Inbox",
  },
  {
    route: "/corporate/inbox-notifications/compose",
    moduleName: "Inbox & Notification",
    subModuleName: "Compose",
  },
  {
    route: "/corporate/inbox-notifications/sent-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Sent Messages",
  },
  {
    route: "/corporate/inbox-notifications/starred-messages",
    moduleName: "Inbox & Notification",
    subModuleName: "Starred Messages",
  },
  {
    route: "/corporate/inbox-notifications/notifications",
    moduleName: "Inbox & Notification",
    subModuleName: "Notifications",
  },
  // My Post & Network
  {
    route: "/corporate/network-management/network",
    moduleName: "My Post & Network",
  },
  {
    route: "/corporate/network-management/network/posts/discover-posts",
    moduleName: "My Post & Network",
    subModuleName: "Discover Posts",
  },
  {
    route: "/corporate/network-management/network/posts/my-posts",
    moduleName: "My Post & Network",
    subModuleName: "My Posts",
  },
  {
    route:
      "/corporate/network-management/network/posts/our-institutional-posts",
    moduleName: "My Post & Network",
    subModuleName: "Our Institutional Posts",
  },
  {
    route: "/corporate/network-management/network/posts/saved-posts",
    moduleName: "My Post & Network",
    subModuleName: "Saved Posts",
  },
  {
    route: "/corporate/network-management/network/posts/pending-ownership",
    moduleName: "My Post & Network",
    subModuleName: "Pending Ownership",
  },
  {
    route: "/corporate/network-management/network/post/post-details/",
    moduleName: "My Post & Network",
    subModuleName: "Post Details",
  },
  {
    route: "/corporate/network-management/network/network/new-requests",
    moduleName: "My Post & Network",
    subModuleName: "New Requests",
  },
  {
    route: "/corporate/network-management/network/network/sent-requests",
    moduleName: "My Post & Network",
    subModuleName: "Sent Requests",
  },
  {
    route: "/corporate/network-management/network/network/follower",
    moduleName: "My Post & Network",
    subModuleName: "Follower",
  },
  {
    route: "/corporate/network-management/network/network/following",
    moduleName: "My Post & Network",
    subModuleName: "Following",
  },
  {
    route: "/corporate/network-management/network/groups/discover-groups",
    moduleName: "My Post & Network",
    subModuleName: "Discover Groups",
  },
  {
    route: "/corporate/network-management/network/groups/my-groups",
    moduleName: "My Post & Network",
    subModuleName: "My Groups",
  },
  {
    route: "/corporate/network-management/network/chat/message",
    moduleName: "My Post & Network",
    subModuleName: "Chat",
  },

  //Chapters & Groups
  {
    route: "/corporate/chapters-groups",
    moduleName: "Chapters & Groups",
  },
  {
    route: "/corporate/chapters-groups/chapters",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapters",
  },
  {
    route: "/corporate/chapters-groups/chapters/29/event-people",
    moduleName: "Chapters & Groups",
    subModuleName: "Chapter Events",
  },
  {
    route: "/corporate/chapters-groups/event-details/174/conference-details",
    moduleName: "Chapters & Groups",
    subModuleName: "Conference Details",
  },
  {
    route:
      "/corporate/chapters-groups/webinar/174/abstract-submission/add-abstract-submission",
    moduleName: "Chapters & Groups",
    subModuleName: "Abstract Submission",
  },
  {
    route: "/corporate/chapters-groups/sig-groups",
    moduleName: "Chapters & Groups",
    subModuleName: "Special Interest Community",
  },
  {
    route: "/corporate/chapters-groups/sig-groups/3/conference",
    moduleName: "Special Interest Community",
    subModuleName: "Conference",
  },
  {
    route: "/corporate/chapters-groups/sig-groups/3/webinar",
    moduleName: "Special Interest Community",
    subModuleName: "Webinar",
  },
  {
    route: "/corporate/chapters-groups/sig-groups/3/workshop",
    moduleName: "Special Interest Community",
    subModuleName: "Workshop",
  },
  {
    route: "/corporate/chapters-groups/sig-groups/3/guest-lecture",
    moduleName: "Special Interest Community",
    subModuleName: "Guest Lecture",
  },
  {
    route:
      "/corporate/chapters-groups/sig-groups/3/faculty-development-program",
    moduleName: "Special Interest Community",
    subModuleName: "Faculty Development Program",
  },
  {
    route: "/corporate/chapters-groups/sig-groups/3/competitions",
    moduleName: "Special Interest Community",
    subModuleName: "Competitions",
  },
  {
    route: "/corporate/chapters-groups/sig-groups/3/hackathon",
    moduleName: "Special Interest Community",
    subModuleName: "Hackathon",
  },

  // talent development
  {
    route: "/corporate/career-support",
    moduleName: "Talent Development",
  },
  {
    route: "/corporate/career-support/careers",
    moduleName: "Talent Development",
    subModuleName: "Career Support",
  },
  {
    route: "/corporate/career-support/14/14",
    moduleName: "Talent Development",
    subModuleName: "Center of Excellence (COE)",
  },

  {
    route: "/corporate/career-support/applied-career-support",
    moduleName: "Talent Development",
    subModuleName: "Applied Career Support",
  },
  {
    route: "/corporate/career-support/applied-career-support/keynote-speaker",
    moduleName: "Talent Development",
    subModuleName: "My Speaker Applications",
  },
  {
    route:
      "/corporate/career-support/applied-career-support/keynote-speaker/speaker-applications/419",
    moduleName: "Talent Development",
    subModuleName: "My Speaker Applications Details",
  },
  {
    route:
      "/corporate/career-support/applied-career-support/keynote-speaker/invitation-received/589",
    moduleName: "Talent Development",
    subModuleName: "Invitations Received Details",
  },

  // certificate & rewards
  {
    route: "/corporate/certificates-and-rewards-list",
    moduleName: "Certificate & Rewards",
  },
  // award winners
  {
    route: "/corporate/award-winners",
    moduleName: "Awards Winners",
  },
  //nominate for award
  {
    route: "/corporate/nominate-for-award-list",
    moduleName: "Nominate For Award",
  },
  // iferp digital library
  {
    route: "/corporate/digital-library",
    moduleName: "IFERP Digital Library",
  },
  //help & support
  {
    route: "/corporate/help/help-and-support",
    moduleName: "Help & Support",
  },
];

export const errorMsgForMobile = (length = 10) => {
  return {
    min: `Phone number must be at least ${length} digits.`,
    max: `Phone number must not exceed ${length} digits.`,
  };
};

export const reportSubMenu = [
  {
    id: 1,
    label: "Student",
    type: "student",
    icon: "bi bi-people-fill",
  },
  {
    id: 2,
    label: "Professional",
    type: "professional",
    icon: "bi bi-mortarboard-fill",
  },
  {
    id: 3,
    label: "Institutional",
    type: "institutional",
    icon: "bi bi-buildings-fill",
  },
  {
    id: 4,
    label: "Expiry",
    type: "expiry",
    icon: "bi bi-hourglass",
  },
];

export const speakerCategoryOptions = [
  { name: "Keynote Speaker", id: 1 },
  { name: "Session Speaker", id: 2 },
  { name: "Guest of Honor", id: 3 },
  { name: "Panel Discussion", id: 4 },
];
export const participationMode = [
  { name: "Physical", id: 1 },
  { name: "Virtual", id: 2 },
];
