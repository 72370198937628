import DatePicker from "components/form/DatePicker";
import TextInput from "components/form/TextInput";
import React from "react";
// Publication Details Few slots left
const ThirdStepOfFollowUps = ({ props, isEdit }) => {
  const { handleChange, values } = props;
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    registration_link,
    // your_name,
    // your_title,
    // your_contact_information,
    // your_website,
  } = values;
  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
            disabled={!isEdit}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
            disabled={!isEdit}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="conference_date"
            onChange={handleChange}
            value={conference_date}
            disabled={!isEdit}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Venue
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_location"
            onChange={handleChange}
            value={conference_location}
            disabled={!isEdit}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Registration Link
        </div>
        <div className="col-lg-6">
          <TextInput
            id="registration_link"
            onChange={handleChange}
            value={registration_link}
            disabled={!isEdit}
          />
        </div>
      </div>

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_name"
            onChange={handleChange}
            value={your_name}
            disabled={!isEdit}
          />
        </div>
      </div> */}
      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_title"
            onChange={handleChange}
            value={your_title}
            disabled={!isEdit}
          />
        </div>
      </div> */}

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
            disabled={!isEdit}
          />
        </div>
      </div> */}

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
            disabled={!isEdit}
          />
        </div>
      </div> */}
    </>
  );
};

export default ThirdStepOfFollowUps;
