import Button from "components/form/Button";
import Location from "components/form/Location";
import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import Table from "components/Layout/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportAllReports,
  exportUserReports,
  getAllReportsData,
  throwError,
  throwSuccess,
} from "store/slices";
import { objectToFormData, titleCaseString } from "utils/helpers";

const AllReports = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));

  const [tableLoading, settableLoading] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    country: "",
  });

  const fetchAllReportsData = async (obj) => {
    const response = await dispatch(getAllReportsData(objectToFormData(obj)));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.data) {
      resList = response?.data?.data?.county_wise_member || [];
      resResultCount = response?.data?.count || 0;
      // const tableListWithSort = orderBy(
      //   resList?.map((o) => {
      //     return {
      //       ...o,
      //       totalMember: o?.professional_members + o?.student_members,
      //     };
      //   }),
      //   "totalMember",
      //   "desc"
      // );
      setTableList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    }
    settableLoading(false);
  };

  const handelChangePagination = (offset) => {
    settableLoading(true);
    let newData = { ...filterData };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    fetchAllReportsData(newData);
  };

  const handleDownload = async (payload) => {
    setDownloadLoading(payload?.country_code);
    const response = await dispatch(
      exportUserReports(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
    } else {
      dispatch(throwError(response?.message));
    }
    setDownloadLoading(false);
  };

  const handleView = async (payload) => {
    if (payload) {
      window.open(
        `${window.location.href}/country-wise?country_code=${payload?.country_code}`,
        "_blank"
      );
    }
  };

  useEffect(() => {
    fetchAllReportsData(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const {
    // create: createAccess,
    view: viewAccess,
    // update: updateAccess,
    // delete: deleteAccess,
  } =
    userDetails?.role_data?.find((item) => item?.permission_name === "Reports")
      ?.sub_permission || {};

  const header = [
    {
      isSearch: false,
      title: "S.No",
    },
    {
      isSearch: false,
      title: "Country",
    },
    {
      isSearch: false,
      title: "Student",
    },
    {
      isSearch: false,
      title: "Professional",
    },
    {
      isSearch: false,
      title: "Action",
    },
  ];
  const rowData = [];
  tableList?.forEach((elem, index) => {
    const {
      country_name,
      professional_members,
      student_members,
      country_code,
    } = elem;
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(country_name)}</span>
        ),
      },
      {
        value: <span>{student_members}</span>,
      },
      {
        value: <span>{professional_members}</span>,
      },
      {
        value: (
          <span className="action-icon-buttons">
            {viewAccess === "1" && (
              <>
                <Button
                  btnStyle="unset-primary"
                  icon={<i className="bi bi-eye" />}
                  className="me-2"
                  onClick={() => {
                    handleView({ country_code: country_code });
                  }}
                />

                <Button
                  btnStyle="unset-primary"
                  icon={<i className="bi bi-download" />}
                  className="me-2"
                  onClick={() => {
                    handleDownload({ country_code: country_code });
                  }}
                  btnLoading={downloadLoading === country_code}
                />
              </>
            )}
          </span>
        ),
      },
    ];

    rowData.push({ data: obj });
  });
  const handelChange = (e) => {
    settableLoading(true);
    const id = e.target.id;
    const value = e.target.value;
    const countryName = e.target.data ? e.target.data.country : "";
    const newData = { ...filterData, [id]: countryName };
    setCountryName(value);
    setFilterData(newData);
    fetchAllReportsData(newData);
  };
  return (
    <Card className="cpt-10 cpb-10 cps-20 cpe-20 cmt-100">
      <div className="d-flex align-items-center justify-content-between cmb-20">
        <div>All Reports ({filterData?.total})</div>
        <div className="d-flex  gap-3">
          <Location
            type="country"
            data={{
              id: "country",
              placeholder: "Country",
              value: countryName,
              onChange: handelChange,
              isClearable: true,
            }}
          />
          {viewAccess === "1" && (
            <ExportButton
              exportAPI={exportAllReports}
              payload={objectToFormData({
                ...filterData,
              })}
              newHeight={"h-45"}
            />
          )}
        </div>
      </div>
      <div className="w-100 overflow-auto">
        <Table
          isLoading={tableLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          changeOffset={handelChangePagination}
          isOtherPagination
        />
      </div>
    </Card>
  );
};

export default AllReports;
