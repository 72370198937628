import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/Layout/Modal';
import { fetchUserDetails, getAllTopicsList } from 'store/slices';
import { icons } from 'utils/constants';

import EducationDetails from './EducationDetails';
import MembershipDetails from './MembershipDetails';
import { getUserType, titleCaseString } from 'utils/helpers';
import './NewPopupRegister.scss';
import PersonalDetails from './PersonalDetails';

const NewPopupRegister = () => {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const { registration_status } = userDetails;
  const [type, setType] = useState('');
  const fetchUserData = async () => {
    const response = await dispatch(fetchUserDetails());
    let newData = {};
    if (response?.data) {
      newData = response?.data;
    }
    setUserDetails(newData);
    return response;
  };

  const fetchTopicList = async () => {
    await dispatch(getAllTopicsList());
  };
  const subTitle = {
    'personal-details': 'Crafting Your Unique Identity',
    'education-details': 'Shaping Your Academic Journey',
    'membership-details': 'Choose Your Path to Success',
  };

  const arrayOption = [
    {
      id: 1,
      title: 'Personal Details',
      type: 'personal-details',
      isActive: +registration_status > 0,
    },
    {
      id: 2,
      title: 'Education Details',
      type: 'education-details',
      isActive: +registration_status > 1,
    },
    {
      id: 3,
      title: 'Membership Details',
      type: 'membership-details',
      isActive: false,
    },
  ];

  useEffect(() => {
    if (
      localStorage.paymentIntent ||
      userDetails?.registration_status === '2'
    ) {
      setType('membership-details');
    } else if (userDetails?.registration_status === '0') {
      setType('personal-details');
    } else if (userDetails?.registration_status === '1') {
      setType('education-details');
    } else {
      setType('personal-details');
    }
  }, [userDetails]);

  useEffect(() => {
    fetchUserData();
    fetchTopicList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userType = getUserType();

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log('✌️windowWidth --->', windowWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  return (
    <>
      {type && (
        <Modal
          size="lg"
          width="1500px"
          className="register-popup"
          hideClose
          isRegister
        >
          <div id="popup-register-container">
            {/* <div className="text-24-600 color-raisin-black text-center mb-3">
              {titleCaseString(userType)} Member Registration
            </div>
            <div className="text-16-500 color-subtitle-navy text-center mb-4">
              {subTitle[type]}
            </div>
            <div className="details-list cmb-30">
              {arrayOption.map((elem, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="details-block">
                      {elem.isActive ? (
                        <img
                          src={icons.activeDetails}
                          alt="active"
                          className="active-selection"
                        />
                      ) : (
                        <div className="number-block">{elem.id}</div>
                      )}
                      <div
                        className={`text-18-400 ${
                          elem.isActive ? "color-new-car" : "color-black-olive"
                        }`}
                        // onClick={() => {
                        //   setType(elem.type);
                        // }}
                      >
                        {elem.title}
                      </div>
                    </div>
                    {arrayOption.length - 1 !== index && (
                      <div
                        className={`border-saprator ${
                          elem.isActive ? "active-border" : ""
                        }`}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div> */}

            <div class="row">
              {windowWidth >= 1040 ? (
                <div className="col-3">
                  <div className="left-side d-flex flex-column justify-content-between align-items-center bg-light border-4  rounded-4 ">
                    <div>
                      <div className="main-logo cpt-24">
                        <img className="img-logo1" src={icons.logo} alt="img" />
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="step-container">
                          {/* Step 1 */}
                          <div className="step-1 d-flex align-items-center position-relative mb-4">
                            <div
                              className={`step-icon ${
                                type === 'personal-details' ? 'active' : ''
                              }`}
                            >
                              <img
                                src={
                                  type !== 'personal-details'
                                    ? icons.darkUserVector
                                    : icons.UserVector
                                }
                                alt="active"
                                className="active-selection"
                                style={{ height: '22px', width: '22px' }}
                              />
                            </div>
                            <div className="step-text cms-14">
                              <span className="text-12-400">Step 1</span>
                              <h6 className="text-muted">
                                Personal <br /> Details
                              </h6>
                            </div>
                          </div>

                          {/* Step 2 */}
                          <div className="step-2 d-flex align-items-center position-relative mb-4">
                            <div className="step-line"></div>
                            <div
                              className={`step-icon ${
                                type === 'education-details' ? 'active' : ''
                              }`}
                            >
                              <img
                                src={
                                  type !== 'education-details'
                                    ? icons.EducationVector
                                    : icons.lightEducationVector
                                }
                                alt="active"
                                className="active-selection"
                                style={{ height: '24px', width: '18px' }}
                              />
                            </div>
                            <div className="step-text cms-14">
                              <span className="text-12-400">Step 2</span>
                              <h6 className="text-muted">
                                Education <br /> Details
                              </h6>
                            </div>
                          </div>

                          {/* Step 3 */}
                          <div className="step-3 d-flex align-items-center position-relative">
                            <div className="step-line"></div>
                            <div
                              className={`step-icon ${
                                type === 'membership-details' ? 'active' : ''
                              }`}
                            >
                              <img
                                src={
                                  type !== 'membership-details'
                                    ? icons.MembershipVector
                                    : icons.lightMembershipVector
                                }
                                alt="active"
                                className="active-selection"
                                style={{ height: '22px', width: '18px' }}
                              />
                            </div>
                            <div className="step-text cms-14">
                              <span className="text-12-400">Step 3</span>
                              <h6 className="text-muted">
                                Membership <br /> Details
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 text-center position-relative card-container cmb-5">
                      <img
                        src={icons.AiResumeBanner}
                        alt="AI Resume"
                        className="img-fluid rounded border"
                        style={{ width: '290px' }}
                      />
                      <div className="position-absolute bottom-0 start-0 translate-x-50 text-white text-center mb-2">
                        <p className="text-start m-3 text-23-600 text-gilroy">
                          Boost Your Job Search with an AI-Enhanced Resume!
                        </p>
                        <button className="custom-btn m-1">
                          Create My AI Resume
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-24-600 color-raisin-black text-center mb-3">
                    {titleCaseString(userType)} Member Registration
                  </div>
                  <div className="text-16-500 color-subtitle-navy text-center mb-4">
                    {subTitle[type]}
                  </div>
                  <div className="details-list cmb-30">
                    {arrayOption.map((elem, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="details-block">
                            {elem.isActive ? (
                              <img
                                src={icons.activeDetails}
                                alt="active"
                                className="active-selection"
                              />
                            ) : (
                              <div className="number-block">{elem.id}</div>
                            )}
                            <div
                              className={`text-18-400 ${
                                elem.isActive
                                  ? 'color-new-car'
                                  : 'color-black-olive'
                              }`}
                              // onClick={() => {
                              //   setType(elem.type);
                              // }}
                            >
                              {elem.title}
                            </div>
                          </div>
                          {arrayOption.length - 1 !== index && (
                            <div
                              className={`border-saprator ${
                                elem.isActive ? 'active-border' : ''
                              }`}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              )}

              <div className={windowWidth >= 1040 ? 'col-9' : 'col-12'}>
                <div className="text-26-400 text-right text-gilroy cpt-24">
                  {titleCaseString(userType)} Member Registration
                </div>
                <div className="text-16-500  text-right mb-4 cpt-10">
                  {subTitle[type]}
                </div>

                <div id="register-popup-body-block" className="cpb-10">
                  {type === 'personal-details' && (
                    <PersonalDetails
                      setType={setType}
                      userDetails={userDetails}
                      fetchUserData={fetchUserData}
                    />
                  )}
                  {type === 'education-details' && (
                    <EducationDetails
                      setType={setType}
                      userDetails={userDetails}
                      fetchUserData={fetchUserData}
                    />
                  )}
                  {type === 'membership-details' && (
                    <MembershipDetails
                      setType={setType}
                      userDetails={userDetails}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NewPopupRegister;
