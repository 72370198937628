import React from "react";
import { icons } from "utils/constants";
import "./MembershipDetails.scss";

const PremiumSubscriptionCard = ({
  isNational,
  premiumBenefitAmount,
  usdAmount,
}) => {
  return (
    <div id="member-container">
      <div className="dark-subscription-card">
        <div className="">
          <div className="d-flex justify-content-between cpe-24 cps-24 cmt-24">
            <div className="d-flex">
              <img src={icons.premiumCardIcon} className="w-26 h-26" />
              <p className="cps-2">Premium</p>
            </div>
            <div className="text-34-400">
              {isNational ? `₹ ${premiumBenefitAmount} ` : `$ ${usdAmount} `}
            </div>
          </div>

          <p className="cmt-18 cps-24 cpe-24 text-14-500">
            If you’re looking for more access then buy this plan
          </p>
          <p className="cmt-19 cps-24 cpe-24 text-14-500">* Inclusive of Tax</p>
        </div>
      </div>
    </div>
  );
};

export default PremiumSubscriptionCard;
