import React, { useEffect, useState } from 'react';
import { icons } from 'utils/constants';

// import "../ProfileCV.scss";
import PersonalForm from './CVGeneratorForm/PersonalForm';
import BioAndEducation from './CVGeneratorForm/BioAndEducation';

import Publications from './CVGeneratorForm/PublicationsForm';
import RoleForm from './CVGeneratorForm/RoleForm';
import Achievements from './CVGeneratorForm/Achievements';
import Experience from './CVGeneratorForm/ExperienceForm';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, fetchResearchProfileData } from 'store/slices';
import { getDataFromLocalStorage } from 'utils/helpers';

const LinkCVForm = ({ handelCv, loadingCv, list }) => {
  const [activeTab, setActiveTab] = useState('bio');
  useEffect(() => {
    setActiveTab('personal');
  }, []);
  const handleNext = () => {
    switch (activeTab) {
      case 'personal':
        setActiveTab('bio');
        break;
      case 'bio':
        setActiveTab('experience');
        break;
      case 'experience':
        setActiveTab('publications');
        break;
      case 'publications':
        setActiveTab('roles');
        break;
      case 'roles':
        setActiveTab('achievements');
        break;
      default:
        break;
    }
  };

  const handlePrevious = () => {
    switch (activeTab) {
      case 'bio':
        setActiveTab('personal');
        break;
      case 'experience':
        setActiveTab('bio');
        break;
      case 'publications':
        setActiveTab('experience');
        break;
      case 'roles':
        setActiveTab('publications');
        break;
      case 'achievements':
        setActiveTab('roles');
        break;
      default:
        break;
    }
  };

  const dispatch = useDispatch();

  let userID = getDataFromLocalStorage('id');
  const fetchDetails = async () => {
    await dispatch(fetchResearchProfileData(`user_id=${userID}`, false));
    await dispatch(fetchProfile());
  };

  const renderForm = () => {
    switch (activeTab) {
      case 'personal':
        return (
          <PersonalForm
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            loadingCv={loadingCv}
            credit={list?.total_credits}
          />
        );
      case 'bio':
        return (
          <BioAndEducation
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            credit={list?.total_credits}
            loadingCv={loadingCv}
          />
        );
      case 'experience':
        return (
          <Experience
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            credit={list?.total_credits}
            loadingCv={loadingCv}
          />
        );
      case 'publications':
        return (
          <Publications
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            credit={list?.total_credits}
            loadingCv={loadingCv}
          />
        );
      case 'roles':
        return (
          <RoleForm
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            credit={list?.total_credits}
            loadingCv={loadingCv}
          />
        );
      case 'achievements':
        return (
          <Achievements
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            credit={list?.total_credits}
            loadingCv={loadingCv}
          />
        );
      default:
        return (
          <PersonalForm
            onNext={handleNext}
            onPrevious={handlePrevious}
            fetchDetails={fetchDetails}
            handelCv={handelCv}
            credit={list?.total_credits}
            loadingCv={loadingCv}
          />
        );
    }
  };

  return (
    <div className="h-100vh overflow-auto iferp-scroll bg-white cps-25 cpe-25 cpt-32 cpb-32">
      <div className="cv-tabs">
        {[
          {
            key: 'personal',
            label: 'Personal Detail',
            icon: icons.bPersonalActive,
          },
          { key: 'bio', label: 'Bio/Education', icon: icons.bEdu },
          { key: 'experience', label: 'Experience', icon: icons.bExperience },
          { key: 'publications', label: 'Publications', icon: icons.bPub },
          { key: 'roles', label: 'Current Roles', icon: icons.bRole },
          { key: 'achievements', label: 'Achievements', icon: icons.bMents },
        ].map((tab) => (
          <div
            key={tab.key}
            className={`cv-tab f-center flex-column ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            <div className="tab-icon f-center">
              <img alt="" src={tab.icon} className="fit-image" loading="lazy" />
            </div>
            <span className="text-nowrap">{tab.label}</span>
          </div>
        ))}
      </div>

      <div className="form-container">{renderForm()}</div>
    </div>
  );
};

export default LinkCVForm;
