import React from "react";
import { useParams } from "react-router-dom";
import CommonData from "./CommonData";
import DashboardOverview from "../Dashboard/DashboardOverview";
import { getDataFromLocalStorage } from "utils/helpers";
import ResourceDashboardOverView from "pages/Resource/Dashboard/ResourceDashboardOverView";

const Reports = () => {
  const params = useParams();
  const { type } = params;
  const userType = getDataFromLocalStorage("user_type");
  return (
    <div>
      {type === "overall-report" && (
        <>
          {["0", "1"].includes(userType) && <DashboardOverview isReport />}
          {userType === "6" && <ResourceDashboardOverView isReport />}
        </>
      )}
      {type === "student" && <CommonData />}
      {type === "professional" && <CommonData />}
      {type === "institutional" && <CommonData />}
      {type === "expiry" && <CommonData />}
    </div>
  );
};

export default Reports;
