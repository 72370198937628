import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchJournals } from "store/slices";
import { journalsPath } from "utils/constants";
import { combineArrayS3, getUserType, objectToFormData } from "utils/helpers";

const NewJournals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [journals, setJournals] = useState([]);

  const getJournals = async () => {
    let forData = objectToFormData();
    const response = await dispatch(fetchJournals(forData));
    let journalList = response?.data?.users || [];
    let newList = await combineArrayS3(journalList, "logo", journalsPath);
    setJournals(newList);
  };

  useEffect(() => {
    getJournals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userType = getUserType();

  
  return (
    <div className="">
      <div className="bb cpb-16">
        <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
          <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
            Journals
          </div>
          {journals?.length > 3 && (
            <div
              className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border"
            }`}
              onClick={() => {
                navigate(`/${userType}/publications`);
              }}
            >
              <span className="d-flex gap-2 align-items-center">
                View All
                <i className="bi bi-chevron-right" />
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="cps-24 cpe-21 cpt-24 cpb-15">
        {journals?.length === 0 ? (
          <div className="center-flex pt-5 pb-5">No Journals Found</div>
        ) : (
          journals?.slice(0, 2)?.map((elem, index) => {
            return (
              <div
                className={`${
                  journals?.length - 1 !== index ? "cmb-20" : ""
                } cps-14 cpe-14 cpt-14 cpb-14 five-box-border`}
                key={index}
              >
                <div className="cmb-14 text-14-400-24 color-new-car text-dots-journal">
                  {elem?.name}
                </div>
                <div className="journal-block">
                  <div className="image-block">
                    <img
                      src={elem?.s3File}
                      alt="journal"
                      className="fit-image fill"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <div className="text-14-400-24 color-1717 cmb-7">
                      <b>ISSN:</b> {`${elem?.issn}`}
                    </div>
                    <div className="text-14-300-24 color-1717">
                      {elem?.type}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {journals?.length > 3 && (
        <div
          className="cpb-19 text-14-500-18 color-7cff pointer d-flex align-item-center justify-content-center gap-2"
          onClick={() => {
            navigate(`/${userType}/publications`);
          }}
        >
          View All Journal
          <i className="bi bi-chevron-right" />
        </div>
      )}
    </div>
  );
};

export default NewJournals;
