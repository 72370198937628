import TextInput from 'components/form/TextInput';
import Card from 'components/Layout/Card';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  dialCode,
  errorMsgForMobile,
  eventMode,
  icons,
  tempEventAbstarctPath,
} from 'utils/constants';
import TextEditor from 'components/form/TextEditor';
import CreatableDropdown from 'components/form/CreatableDropdown';
import UserDropdown from 'components/form/UserDropdown';
import UploadInput from 'components/form/UploadInput';
import Dropdown from 'components/form/Dropdown';
import Label from 'components/form/Label';
import Location from 'components/form/Location';
import Button from 'components/form/Button';
import { isEqual, map, union, unionBy } from 'lodash';
import RadioInput from 'components/form/RadioInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEventSubmitAbstract,
  fetchUserEventDetails,
  getKeywords,
  throwError,
  throwSuccess,
  uploadPaperBeforeSubmission,
} from 'store/slices';
import {
  convertString,
  generatePreSignedUrl,
  getCountryCodeName,
  getDataFromLocalStorage,
  getPhoneNumberLength,
  numberOnlyFromInput,
  objectToFormData,
  titleCaseString,
} from 'utils/helpers';
import AddAuthorPopup from 'components/Layout/AddAuthorPopup';
import AddCoAuthorPopup from 'components/Layout/AddCoAuthorPopup';
import NewUserDropdown from 'components/V2ForNewAbstractFlow/NewUserDropdown';
import NewAbstractBasicDetails from './NewAbstractBasicDetails';
import AbstractSubmitSuccessPopup from 'components/V2ForNewAbstractFlow/AbstractSubmitSuccessPopup';
import Loader from 'components/Layout/Loader';

const NewAbstractSubmission = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventData } = useSelector((state) => ({
    eventData: state.global.eventData,
  }));
  const userData = getDataFromLocalStorage();
  const [showAddAuthorPopup, setShowAddAuthorPopup] = useState(false);
  const [showAddCoAuthorPopup, setShowAddCoAuthorPopup] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [keywordsList, setKeywordsList] = useState({
    existing: [],
    custom: [],
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [typedInvitedAuthor, setTypedInvitedAuthor] = useState('');
  const [typedInvitedCoAuthor, setTypedInvitedCoAuthor] = useState('');
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [previewFile, setPreviewFile] = useState(null);

  const initialValues = {
    paper_title: '',
    paper_abstract: '',
    paper_keywords: '',
    authors: '',
    authors_email: '',
    co_authors: [],
    abstract_path: '',
    abstractPathFileName: '',
    presentation_type: '',
    submission_type: '',
    source: '',
    email_id: '',
    country: '',
    contact_number: '',
    whatsapp_number: '',
    country_code: getCountryCodeName(userData?.country_code || 'IN') || 'IN',
    mobile_no_length: getPhoneNumberLength(userData?.country_code) || 10,
  };

  const handleUploadBeforeSubmission = async (fileData) => {
    const formData = objectToFormData({ abstract_path: fileData });
    const response = await dispatch(uploadPaperBeforeSubmission(formData));
    if (response?.status === 200) {
      setPreviewFile(response?.data?.abstract_path);
      dispatch(throwSuccess(response?.message));
    } else {
      dispatch(throwError({ message: response?.message }));
    }
    setIsPreviewLoading(false);
  };

  const handleViewPreview = async (previewFile) => {
    if (previewFile) {
      const res = await generatePreSignedUrl(
        previewFile,
        tempEventAbstarctPath
      );
      const fileEx = previewFile?.split('.')?.pop();

      if (fileEx !== 'pdf') {
        const googleDocsUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
          res
        )}&embedded=true`;
        window.open(googleDocsUrl, '_blank');
      } else {
        window.open(res, '_blank');
      }
    }
  };

  const validationSchema = Yup.object().shape({
    paper_title: Yup.string().required('Paper title is required.'),
    paper_abstract: Yup.string().required('Abstract is required.'),
    paper_keywords: Yup.string().test(
      'paper_keywords-length',
      'You must enter at least 3 and up to 10 Keywords',
      function (value) {
        const keywordArray = value
          ? value.split(',').filter((kw) => kw.trim() !== '')
          : [];
        return keywordArray.length >= 3 && keywordArray.length <= 10;
      }
    ),
    authors: Yup.lazy((_, obj) => {
      const { is_email } = obj?.parent;
      if (is_email) {
        return Yup.string();
      } else {
        return Yup.string().required('Author is required.');
      }
    }),
    authors_email: Yup.lazy((_, obj) => {
      const { is_email } = obj?.parent;
      if (is_email) {
        return Yup.string()
          .required('Author email is required.')
          .email('Email must be a valid email');
      } else {
        return Yup.string();
      }
    }),
    abstract_path: Yup.string().required('File is required.'),
    presentation_type: Yup.string().required('Presentation type is required.'),
    submission_type: Yup.string().required('Select any submission type.'),
    email_id: Yup.string()
      .email('Email must be a valid email')
      .required('Email is required.'),
    country: Yup.string().required('Country is required.'),
    contact_number: Yup.lazy((_, obj) => {
      const { country_code, mobile_no_length } = obj?.parent || {};
      if (country_code) {
        return Yup.string()
          .required('Contact number is required.')
          .min(mobile_no_length, errorMsgForMobile(mobile_no_length)?.min)
          .max(mobile_no_length, errorMsgForMobile(mobile_no_length)?.max);
      } else {
        return Yup.string();
      }
    }),
  });

  const fetchKeywords = async () => {
    const response = await dispatch(getKeywords());
    let listArray = response?.data || [];
    setKeywordsList((prev) => {
      return {
        ...prev,
        existing: map(listArray, (elm) => {
          return { id: elm?.keywords, label: elm?.keywords };
        }),
      };
    });
  };

  const handleSave = async (values) => {
    setBtnLoading(true);

    let forData = objectToFormData({
      ...values,
      event_id: params?.eventId,
      user_id: getDataFromLocalStorage('id'),
      co_authors: JSON.stringify(values?.co_authors),
    });
    const response = await dispatch(addEventSubmitAbstract(forData));
    if (response?.status === 200) {
      setIsSuccess(response?.data?.abstract_id);
    }
    setBtnLoading(false);
  };

  const fetchEventDetails = async () => {
    await dispatch(
      fetchUserEventDetails({
        event_id: params?.eventId,
        user_id: getDataFromLocalStorage('id'),
      })
    );
  };

  useEffect(() => {
    fetchEventDetails();
    fetchKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isSuccess ? (
    <AbstractSubmitSuccessPopup
      isSuccess={isSuccess}
      onHide={() => {
        setIsSuccess(false);
      }}
    />
  ) : (
    <div className="cps-30 cpe-30 cpt-30 cpb-30">
      <div className="d-flex gap-2 cmb-30">
        <span
          className="d-flex"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={icons.leftArrow} alt="back" className="h-21 me-3 pointer" />
        </span>
        <div className="text-20-500 color-black-olive">Abstract Submission</div>
      </div>

      <div className="row">
        <NewAbstractBasicDetails eventData={eventData} />
        <div className="col-md-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                setFieldValue,
                resetForm,
                handleSubmit,
                setValues,
              } = props;

              const { authors_email, author_name, is_email, co_authors } =
                values;
              const { authors_email: err_authors_email, authors: err_authors } =
                errors;
              return (
                <form>
                  {showAddAuthorPopup && (
                    <AddAuthorPopup
                      id="invite_author_details"
                      setAuthorData={(e) => {
                        const isExist = co_authors?.some(
                          (o) => o.email === e?.value?.author_email_id
                        );
                        if (isExist) {
                          dispatch(
                            throwError({
                              message: 'Email already exist in co-author.',
                            })
                          );
                          return;
                        }
                        setValues({
                          ...values,
                          authors: '',
                          author_name: e?.value?.author_name || '',
                          authors_email: e?.value?.author_email_id || '',
                          country_code: e?.value?.author_country_code || '',
                          author_phone: e?.value?.author_whatsapp_number || '',
                          author_country: e?.value?.author_country || '',
                          is_email: true,
                          email_id: e?.value?.author_email_id || '',
                          contact_number:
                            e?.target?.author_whatsapp_number || '',
                          whatsapp_number:
                            e?.target?.author_whatsapp_number || '',
                          country: e?.value?.author_country || '',
                        });
                        setShowAddAuthorPopup(false);
                      }}
                      onHide={() => {
                        setShowAddAuthorPopup(false);
                      }}
                      typedData={typedInvitedAuthor}
                    />
                  )}
                  {showAddCoAuthorPopup && (
                    <AddCoAuthorPopup
                      id="coAuthor_details"
                      setCoAuthorData={(e) => {
                        if (e?.target?.value?.length > 0) {
                          let oldData = JSON.parse(JSON.stringify(co_authors));
                          e?.target?.value?.forEach((elm) => {
                            oldData.push({
                              id: '',
                              email: elm?.email_id || '',
                              name: elm?.name || '',
                              country: elm?.country || '',
                              phone: elm?.whatsapp_number || '',
                              country_code: elm?.country_code || '',
                            });
                          });
                          setFieldValue('co_authors', oldData);
                        }
                        setShowAddCoAuthorPopup(false);
                      }}
                      existingList={map(co_authors, (coAuthor) => ({
                        name: coAuthor?.name,
                        email: coAuthor?.email,
                      }))}
                      onHide={() => {
                        setShowAddCoAuthorPopup(false);
                      }}
                      existingAuthor={{
                        name: author_name || '',
                        email: authors_email || '',
                      }}
                      typedData={typedInvitedCoAuthor}
                    />
                  )}
                  <Card className="cps-20 cpe-20 cpt-30 cpb-20 cmb-24">
                    <div className="text-16-500 color-1717 cmb-24">
                      Abstract Details
                    </div>
                    <div className="border-bottom cmb-24 color-d1d1"></div>
                    <div className="row">
                      <div className=" cmb-24">
                        <TextInput
                          label="Paper Title*"
                          value={values?.paper_title}
                          error={errors?.paper_title}
                          onChange={handleChange}
                          id="paper_title"
                          placeholder="Enter paper title"
                        />
                      </div>
                      <div className=" cmb-24">
                        <TextEditor
                          label="Abstract*"
                          id="paper_abstract"
                          value={values?.paper_abstract}
                          error={errors?.paper_abstract}
                          onChange={handleChange}
                          placeholder="Enter paper_abstract"
                        />
                      </div>
                      <div className=" cmb-24">
                        <CreatableDropdown
                          label="Keywords"
                          id="paper_keywords"
                          options={unionBy(
                            [
                              ...keywordsList?.existing,
                              ...keywordsList?.custom,
                            ],
                            'id'
                          )}
                          placeholder="Enter Keywords"
                          value={values?.paper_keywords}
                          error={errors?.paper_keywords}
                          onChange={handleChange}
                          onCreateOption={(e) => {
                            let newCreate = [];
                            if (e?.includes(',')) {
                              newCreate = e?.split(',');
                            } else {
                              newCreate = [e];
                            }
                            newCreate = newCreate?.map((o) => {
                              let removedSpace = o?.trim();
                              return titleCaseString(removedSpace);
                            });
                            let strToArray = values?.paper_keywords
                              ? values?.paper_keywords?.split(',')
                              : [];
                            strToArray = union([...strToArray, ...newCreate]);
                            setKeywordsList({
                              ...keywordsList,
                              custom: [
                                ...keywordsList?.custom,
                                ...newCreate?.map((e) => {
                                  return { id: e, label: e };
                                }),
                              ],
                            });
                            handleChange({
                              target: {
                                id: 'paper_keywords',
                                value: strToArray.join(','),
                              },
                            });
                          }}
                        />
                      </div>
                      <div className=" cmb-24">
                        {is_email ? (
                          <TextInput
                            isRequired
                            label="Author"
                            placeholder="Enter author email"
                            id="authors"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={author_name}
                            error={err_authors_email}
                            onBlur={() => {
                              if (!err_authors_email) {
                                const selectedEmail = authors_email;
                                const isExist = co_authors?.some(
                                  (o) => o?.email === selectedEmail
                                );
                                if (isExist) {
                                  dispatch(
                                    throwError({
                                      message: 'Email already exist in author.',
                                    })
                                  );
                                  setFieldValue('authors_email', '');
                                }
                              }
                            }}
                            handelCancel={() => {
                              setValues({
                                ...values,
                                authors: '',
                                author_name: '',
                                authors_email: '',
                                author_phone: '',
                                author_country: '',
                                is_email: '',
                                email_id: '',
                                contact_number: '',
                                whatsapp_number: '',
                                country: '',
                              });
                            }}
                          />
                        ) : (
                          <UserDropdown
                            IsIcon
                            isRequired
                            showOnSearch
                            label="Author"
                            id="authors"
                            placeholder="Author email"
                            value={values?.authors}
                            error={err_authors}
                            onChange={(e) => {
                              const {
                                id: selectedId,
                                name: selectedName,
                                email_id: selectedEmail,
                                country_id: selectedCountryId,
                                mobile_number: selectedMobileNumber,
                              } = e?.target?.data;
                              const isExist = co_authors?.some(
                                (o) => o?.email === selectedEmail
                              );
                              if (isExist) {
                                dispatch(
                                  throwError({
                                    message:
                                      'Author already exist in co-author.',
                                  })
                                );
                              } else {
                                setValues({
                                  ...values,
                                  authors: selectedId || '',
                                  author_name: selectedName || '',
                                  authors_email: selectedEmail || '',
                                  author_phone: selectedMobileNumber || '',
                                  author_country: selectedCountryId || '',
                                  is_email: false,
                                  email_id: selectedEmail || '',
                                  contact_number: selectedMobileNumber || '',
                                  whatsapp_number: selectedMobileNumber || '',
                                  country: selectedCountryId || '',
                                });
                                handleChange(e);
                              }
                            }}
                            existingList={[]}
                            handelInvite={(e) => {
                              setTypedInvitedAuthor(e?.value);
                              setValues({
                                ...values,
                                authors: '',
                                author_name: '',
                                authors_email: '',
                                author_phone: '',
                                author_country: '',
                                is_email: '',
                                email_id: '',
                                contact_number: '',
                                whatsapp_number: '',
                                country: '',
                              });
                              setShowAddAuthorPopup(true);
                            }}
                          />
                        )}
                      </div>
                      <div className=" cmb-24">
                        <NewUserDropdown
                          showOnSearch
                          id="co_authors"
                          label="Co Authors"
                          placeholder="Co-Author email"
                          handleInvite={(e) => {
                            setTypedInvitedCoAuthor(e?.value);
                            setShowAddCoAuthorPopup(true);
                          }}
                          onChange={(e) => {
                            let oldData = JSON.parse(
                              JSON.stringify(co_authors)
                            );
                            oldData.push({
                              id: e?.target?.value?.id || '',
                              email: e?.target?.value?.email_id || '',
                              name: e?.target?.value?.name || '',
                              country: e?.target?.value?.country_id || '',
                              phone: e?.target?.value?.mobile_number || '',
                            });

                            setFieldValue('co_authors', oldData);
                          }}
                          handleDelete={(e) => {
                            let oldData = JSON.parse(
                              JSON.stringify(co_authors)
                            )?.filter((_, i) => i !== e);
                            setFieldValue('co_authors', oldData);
                          }}
                          // existingList={map(co_authors, "name")}
                          existingList={map(co_authors, (CoAuthor) => ({
                            name: CoAuthor?.name,
                            email: CoAuthor?.email,
                            id: CoAuthor?.id,
                          }))}
                          existingAuthor={{
                            name: author_name || '',
                            email: authors_email || '',
                          }}
                        />
                      </div>
                      <div className=" cmb-24">
                        <UploadInput
                          multiple={false}
                          label="Upload Paper*"
                          id="abstract_path"
                          value={values?.abstract_path}
                          supportedFormats={[
                            'XLS',
                            'XLSX',
                            'PDF',
                            'WORD',
                            'PPT',
                            'PPTX',
                            'DOC',
                            'DOCX',
                          ]}
                          handleChange={(e) => {
                            setPreviewFile(null);
                            setFieldValue(
                              'abstractPathFileName',
                              e?.target?.fileName
                            );
                            setFieldValue(e?.target?.id, e?.target?.value);
                            handleChange(e);
                            if (e?.target?.value)
                              handleUploadBeforeSubmission(e?.target?.value);
                          }}
                        />
                      </div>
                      {values?.abstract_path &&
                        (isPreviewLoading ? (
                          <div className="cmb-16">
                            <Loader size="sm" />
                          </div>
                        ) : previewFile ? (
                          <div
                            className="cmb-16 text-16-500-20 color-7cff pointer"
                            onClick={() => handleViewPreview(previewFile)}
                          >
                            View Submitted Abstract
                          </div>
                        ) : (
                          <div className="cmb-16 text-16-500-20">
                            Please check you file
                          </div>
                        ))}
                    </div>
                    <div className="row">
                      <div className="cmb-24 col-md-6">
                        <Dropdown
                          label="Presentation Type*"
                          id="presentation_type"
                          placeholder="Select"
                          options={eventMode}
                          optionKey="value"
                          optionValue="value"
                          onChange={handleChange}
                          value={values?.presentation_type}
                          error={errors?.presentation_type}
                        />
                      </div>
                      <div className="col-md-6 cmb-24">
                        <Label label="Submission Type*" />
                        <div className="d-flex gap-3 cmt-10">
                          <div className="d-flex align-items-center">
                            <RadioInput
                              id="submission_type"
                              name="submission_type"
                              value={'Abstract Submission'}
                              onChange={() => {
                                setFieldValue(
                                  'submission_type',
                                  'Abstract Submission'
                                );
                              }}
                              checked={
                                values?.submission_type ===
                                'Abstract Submission'
                              }
                            />

                            <div className="text-16-400 color-raisin-black ms-3">
                              Abstract
                            </div>
                          </div>
                          <div className="d-flex align-items-center ms-5">
                            <RadioInput
                              name="submission_type"
                              id="submission_type"
                              value={'Full Paper Submission'}
                              onChange={() => {
                                setFieldValue(
                                  'submission_type',
                                  'Full Paper Submission'
                                );
                              }}
                              checked={
                                values?.submission_type ===
                                'Full Paper Submission'
                              }
                            />

                            <div className="text-16-400 color-raisin-black ms-3">
                              Full Paper
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {errors?.submission_type && (
                            <span
                              style={{ color: 'red' }}
                              className="text-12-400"
                            >
                              {errors?.submission_type}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label="How did you know about this conference?"
                          id="source"
                          onChange={handleChange}
                          value={values?.source}
                          error={errors?.source}
                        />
                      </div>
                    </div>
                  </Card>

                  <Card className="cps-20 cpe-20 cpb-24 cpt-24">
                    <div className="text-16-500 color-1717 cmb-24">
                      Contact Details
                    </div>
                    <div className="border-bottom cmb-24 color-d1d1"></div>

                    <div className="row cmb-24">
                      <div className="col-md-6 cmb-24">
                        <TextInput
                          label="Email ID *"
                          id="email_id"
                          placeholder="Enter email id"
                          onChange={(e) => {
                            handleChange(convertString(1, e));
                          }}
                          value={values?.email_id}
                          error={errors?.email_id}
                        />
                      </div>
                      <div className="col-md-6 cmb-24">
                        <Location
                          type="country"
                          data={{
                            label: 'Country *',
                            id: 'country',
                            placeholder: 'Select Country',
                            value: values?.country,
                            error: errors?.country,
                            // onChange: handleChange,
                            onChange: (e) => {
                              handleChange(e);
                              const selectedCountry = dialCode.find(
                                (country) =>
                                  country?.name === e.target?.data?.country
                              );
                              if (selectedCountry) {
                                setFieldValue(
                                  'country_code',
                                  selectedCountry.code
                                );
                                setFieldValue(
                                  'mobile_no_length',
                                  selectedCountry.phoneLength
                                );
                              } else {
                                setFieldValue('country_code', '');
                              }
                            },
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          isPhoneNumber
                          label="Contact *"
                          placeholder="Phone Number"
                          id="contact_number"
                          value={values?.contact_number}
                          error={errors?.contact_number}
                          phoneNumberData={{
                            id: 'country_code',
                            value: values?.country_code,
                          }}
                          onChange={(e) => {
                            if (e.target.id === 'contact_number') {
                              handleChange(numberOnlyFromInput(e));
                            } else {
                              handleChange(e);
                              handleChange({
                                target: { id: 'contact_number', value: '' },
                              });
                              setFieldValue(
                                'mobile_no_length',
                                e.target.length
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          IsIcon
                          icon={icons?.whatsappOutline}
                          label="Phone Number"
                          id="whatsapp_number"
                          onChange={handleChange}
                          value={values?.whatsapp_number}
                          error={errors?.whatsapp_number}
                        />
                      </div>
                    </div>

                    <div className="d-flex gap-4">
                      <Button
                        text="Cancel"
                        btnStyle="light-outline"
                        className="cps-40 cpe-40"
                        onClick={resetForm}
                      />
                      <Button
                        text="Submit"
                        btnStyle="primary-dark"
                        className="cps-40 cpe-40"
                        onClick={handleSubmit}
                        btnLoading={btnLoading}
                        disabled={isEqual(values, initialValues)}
                      />
                    </div>
                  </Card>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NewAbstractSubmission;
