import React from "react";
import Card from "components/Layout/Card";
import { icons } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getUserType, titleCaseString } from "utils/helpers";
import "./RBUserType.scss";

const RBUserType = () => {
  const params = useParams();
  const { type, userType } = params;
  const memberType = getUserType();
  const navigate = useNavigate();

  const userLabel = titleCaseString(params?.type)?.replace("-", " ");
  const handleNavigate = (dataType) => {
    if (dataType === "all") {
      navigate(
        `/${memberType}/settings/rbac/${type}/${userType}/all/detail/all`
      );
    } else {
      navigate(`/${memberType}/settings/rbac/${type}/${userType}/${dataType}`);
    }
  };

  return (
    <Card className="rbac-user-type-container cps-24 cpt-16 cpe-24 cpb-16 br-10 h-100">
      <div className="d-flex align-items-center gap-3 cmb-29">
        <i
          className="bi bi-chevron-left pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="text-16-500-20 color-title-navy">{userLabel}</span>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div
            className="text-16-600-20 rbac-users-type"
            onClick={() => {
              handleNavigate("all");
            }}
          >
            <img src={icons?.Groups} alt="groups" />
            All {userLabel}
          </div>
        </div>
        <div className="col-md-6">
          <div
            className="text-16-600-20 rbac-users-type"
            onClick={() => {
              handleNavigate("individual");
            }}
          >
            <img src={icons?.PersonSearch} alt="person" />
            Individual {userLabel}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RBUserType;
