import Button from 'components/form/Button';
import Profile from 'components/Layout/Profile';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { icons, profilePath } from 'utils/constants';
import { generatePreSignedUrl } from 'utils/helpers';

const LinkCVPreview = ({ userDetails, researchProfile, list }) => {
  const type =
    (userDetails && userDetails?.user_type === '2') ||
    userDetails?.user_type === '3' ||
    userDetails?.user_type === '4'
      ? 'Professional'
      : userDetails?.user_type === '5' ||
        userDetails?.user_type === '6' ||
        userDetails?.user_type === '7'
      ? 'Institution'
      : userDetails?.user_type === '9'
      ? 'Corporate'
      : userDetails?.user_type === '11' || userDetails?.user_type === '12'
      ? 'Student'
      : '';
  // console.log('userDetails', userDetails)
  const [s3URL, setS3URL] = useState('');

  const reduxData = useSelector((state) => state.global);
  const { resumeList } = reduxData;

  const { profile_details, educational_details, about, affiliations } =
    researchProfile || [];
  const { personal_details, professional_details } = userDetails || [];

  const InfoBlock = ({ icon, label, value }) =>
    value && (
      <>
        <div className="fa-center gap-2 flex-nowrap cmb-4">
          <div className="w-14 h-14 f-center">
            <img src={icon} alt="" className="fit-image" loading="lazy" />
          </div>
          <div className="text-12-400 color-black-olive">
            {label}: {value}
          </div>
        </div>
      </>
    );
  const InfoBlockWithList = ({ icon, label, items }) =>
    items && (
      <>
        <div className="fa-center gap-2 flex-nowrap cmb-14">
          <div className="w-18 h-18 f-center">
            <img src={icon} alt="" className="fit-image" loading="lazy" />
          </div>
          <div className="text-14-500 color-48c0">{label}</div>
        </div>
        <ul className="list-style-none">
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <hr className="b-d1d1" />
      </>
    );
  const EmpBlock = ({ title, date, subTitle, items }) => {
    const itemList = items
      ? items
          .split('-')
          .map((item) => item.trim())
          .filter((item) => item)
      : [];

    return (
      <div className="empList">
        <div className="fb-center flex-norap">
          <div className="text-14-500 color-1717">{title}</div>
          <div className="text-12-400 color-7cff">{date}</div>
        </div>
        <div className="text-10-400 color-dark-gray cmb-8">{subTitle}</div>
        <ul className="list-style-none">
          {itemList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    );
  };
  const JorBlock = ({ title, items }) =>
    title && (
      <div className="jorList" style={{ paddingLeft: '14px' }}>
        <div className="fb-center flex-norap cmb-8">
          <div className="text-14-500 color-1717">{title}</div>
        </div>
        <ul className="list-style-none">
          {items && <li>{items || '---'}</li>}
        </ul>
      </div>
    );
  const AcheiBlock = ({ title, items }) => (
    <div className="acheiList" style={{ paddingLeft: '14px' }}>
      <div className="fb-center flex-norap cmb-8">
        <div className="text-14-500 color-1717">{title}</div>
      </div>
      <ul className="list-style-none">{items && <li>{items}</li>}</ul>
    </div>
  );
  const PubBlock = ({ title, description }) => (
    <div className="pubList" style={{ padding: '14px' }}>
      <div className="fb-center flex-norap">
        <div className="text-14-500 color-1717 cmb-8">{title}</div>
      </div>
      {/* <div className="text-12-400 color-dark-gray cmb-8">{description}</div> */}
      <ul className="list-style-none">
        {description && <li>{description}</li>}
      </ul>
    </div>
  );
  const EduBlock = ({ title, date, description }) =>
    title && (
      <>
        <div className="fb-center flex-nowrap cmb-6">
          <div className="text-14-500 color-1717">{title}</div>
          <div className="text-12-400 color-dark-gray">{date}</div>
        </div>
        <div className="text-12-400 color-dark-gray cmb-22">{description}</div>
      </>
    );
  const fetchProfile = async (profileName) => {
    let retunURL = '';
    const response = await generatePreSignedUrl(profileName, profilePath);
    retunURL = response;
    setS3URL(retunURL);
  };
  useEffect(() => {
    if (profile_details?.profile_photo) {
      fetchProfile(profile_details?.profile_photo);
    } else {
      setS3URL(profile_details?.profile_photo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_details?.profile_photo]);

  // const experienceList = [
  //   ...list?.current_experience_details,
  //   ...list?.past_experience_details,
  // ];

  return (
    <div className="h-100vh overflow-auto iferp-scroll bg-d180 cps-25 cpe-25 cpt-32 cpb-32">
      <div className="cmb-24 d-flex align-items-center justify-content-start gap-3 flex-nowrap">
        <div>
          <Button
            text="Download PDF"
            icon={
              <img
                src={icons.bPdf}
                alt=""
                loading="lazy"
                className="w-16 h-16 cme-10"
              />
            }
            btnStyle="AI"
          />
        </div>
        <div>
          <Button
            text="Download .txt"
            icon={
              <img
                src={icons.bTxt}
                alt=""
                loading="lazy"
                className="w-16 h-16 cme-10"
              />
            }
            btnStyle="AI"
          />
        </div>
      </div>
      <div className="cvBlock iferp-scroll">
        <div className="cpt-14 cpb-14 cps-14 cpe-14 headBlock">
          <div className="d-flex gap-3">
            <div className="w-102 h-102 d-flex align-items-center justify-content-center">
              <img src={s3URL} alt="" loading="lazy" className="fit-image" />
            </div>
            <div className="flex-grow-1">
              <div className="fb-center flex-nowrap cmb-10">
                <div className="text-22-600 color-1717">
                  {`${profile_details?.first_name} ${profile_details?.last_name}` ||
                    '--'}
                </div>
                {type && <div className="text-14-500 color-7cff">{type}</div>}
              </div>
              <Row className="row-gap-2">
                <Col md={6}>
                  {userDetails?.phone_number && (
                    <InfoBlock
                      icon={icons.bPhone}
                      label="Phone"
                      value={userDetails?.phone_number}
                    />
                  )}
                  {personal_details?.date_of_birth && (
                    <InfoBlock
                      icon={icons.bCalender}
                      label="DOB"
                      value={personal_details?.date_of_birth}
                    />
                  )}
                  {personal_details?.date_of_birth && (
                    <InfoBlock
                      icon={icons.bMail}
                      label="Email"
                      value={userDetails?.email_id}
                    />
                  )}
                </Col>
                <Col md={6}>
                  {personal_details?.country_name && (
                    <InfoBlock
                      icon={icons.bGlobe}
                      label="Country"
                      value={personal_details?.country_name}
                    />
                  )}
                  {personal_details?.gender && (
                    <InfoBlock
                      icon={icons.bGender}
                      label="Gender"
                      value={personal_details?.gender}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mainCvBlock">
          <Row className="ms-0 me-0 ps-0 pe-0">
            <Col md={4} className="ps-0 pe-0">
              <div className="br-f4fc cps-14 cpe-14 cpt-14 cpb-14">
                {educational_details?.area_of_interest && (
                  <InfoBlockWithList
                    icon={icons.bArea}
                    label="Area of Interest"
                    items={
                      educational_details?.area_of_interest &&
                      educational_details?.area_of_interest?.split(',')
                    }
                  />
                )}
                {about?.disciplines && (
                  <InfoBlockWithList
                    icon={icons.bDisipline}
                    label="Disciplines"
                    items={about?.disciplines && about?.disciplines.split(',')}
                  />
                )}
                {about?.skills_and_expertise && (
                  <InfoBlockWithList
                    icon={icons.bSkill}
                    label="Skills & Expertise"
                    items={
                      about?.skills_and_expertise &&
                      about?.skills_and_expertise.split(',')
                    }
                  />
                )}
                {about?.languages && (
                  <InfoBlockWithList
                    icon={icons.bLanguague}
                    label="Languages"
                    items={about?.languages && about?.languages.split(',')}
                  />
                )}
                {list?.educational_details?.length > 0 && (
                  <>
                    {(list?.educational_details[0]?.ug_course ||
                      list?.educational_details[0]?.pg_course ||
                      list?.educational_details[0]?.phd_course) && (
                      <>
                        <div className="fa-center gap-2 flex-nowrap cmb-14">
                          <div className="w-18 h-18 f-center">
                            <img
                              src={icons.bEducation} // Assuming `icons.bEducation` is defined somewhere
                              alt="Education Icon"
                              className="fit-image"
                              loading="lazy"
                            />
                          </div>
                          <div className="text-14-500 color-48c0">
                            Education
                          </div>
                        </div>

                        {/* Undergraduate Education Block */}
                        <EduBlock
                          title={
                            list?.educational_details[0]?.ug_course ||
                            'Not Available'
                          }
                          date={
                            list?.educational_details[0]?.ug_year_of_completion
                              ? moment(
                                  list?.educational_details[0]
                                    ?.ug_year_of_completion
                                ).format('DD-MMM-YYYY')
                              : null
                          }
                          description={`${
                            list?.educational_details[0]?.ug_department
                              ? `${list?.educational_details[0]?.ug_department},`
                              : ''
                          } ${
                            list?.educational_details[0]?.ug_university
                              ? `${list?.educational_details[0]?.ug_university},`
                              : ''
                          } ${
                            list?.educational_details[0]?.ug_institution
                              ? `${list?.educational_details[0]?.ug_institution}`
                              : ''
                          }`}
                        />

                        {/* Postgraduate Education Block */}
                        <EduBlock
                          title={list?.educational_details[0]?.pg_course || ''}
                          date={
                            list?.educational_details[0]?.pg_year_of_completion
                              ? moment(
                                  list?.educational_details[0]
                                    ?.pg_year_of_completion
                                ).format('DD-MMM-YYYY')
                              : null
                          }
                          description={`${
                            list?.educational_details[0]?.pg_department
                              ? `${list?.educational_details[0]?.pg_department},`
                              : ''
                          } ${
                            list?.educational_details[0]?.pg_university
                              ? `${list?.educational_details[0]?.pg_university},`
                              : ''
                          } ${
                            list?.educational_details[0]?.pg_institution
                              ? `${list?.educational_details[0]?.pg_institution}`
                              : ''
                          }`}
                        />

                        {/* PhD Education Block */}
                        <EduBlock
                          title={list?.educational_details[0]?.phd_course || ''}
                          date={
                            list?.educational_details[0]?.phd_year_of_completion
                              ? moment(
                                  list?.educational_details[0]
                                    ?.phd_year_of_completion
                                ).format('DD-MMM-YYYY')
                              : null
                          }
                          description={`${
                            list?.educational_details[0]?.phd_department
                              ? `${list?.educational_details[0]?.phd_department},`
                              : ''
                          } ${
                            list?.educational_details[0]?.phd_university
                              ? `${list?.educational_details[0]?.phd_university},`
                              : ''
                          } ${
                            list?.educational_details[0]?.phd_institution
                              ? `${list?.educational_details[0]?.phd_institution}`
                              : ''
                          }`}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col md={8} className="ps-0 pe-0">
              {list?.length !== 0 && list?.about[0].introduction && (
                <div className="br-f4fc cps-14 cpe-14 cpt-14 cpb-14">
                  <div className="text-14-500 color-1717 cmb-8">Profile</div>
                  <div className="text-12-400 color-dark-gray">
                    {list?.length !== 0 && list?.about[0].introduction}
                  </div>
                  <hr className="b-d1d1" />
                </div>
              )}
              {list?.length !== 0 &&
                list?.past_experience_details?.length > 0 && (
                  <React.Fragment>
                    <div
                      className="fa-center gap-2 flex-nowrap cmb-22 "
                      style={{ padding: '14px' }}
                    >
                      <div className="w-18 h-18 f-center">
                        <img
                          src={icons.bDisipline}
                          alt=""
                          className="fit-image"
                          loading="lazy"
                        />
                      </div>
                      <div className="text-14-500 color-48c0">
                        Employment History
                      </div>
                    </div>
                    {list?.past_experience_details?.map((ele) => {
                      return (
                        <>
                          <EmpBlock
                            title={ele?.institution}
                            date={`${moment(ele?.start_date).format(
                              'MMM YYYY'
                            )} -${moment(ele?.end_date).format('MMM YYYY')}`}
                            subTitle={`${ele?.department} (${ele?.position})`}
                            items={ele?.description}
                          />
                        </>
                      );
                    })}

                    {/* {experienceList?.map((ele) => {
                      return (
                        <EmpBlock
                          key={ele?.id}
                          title={ele?.institution || ele?.institution_name}
                          date={
                            ele?.start_date && ele?.end_date
                              ? `${moment(ele?.start_date).format(
                                  'MMM YYYY',
                                )} - ${moment(ele?.end_date).format(
                                  'MMM YYYY',
                                )}`
                              : ''
                          }
                          subTitle={`${ele?.department} (${
                            ele?.position || ele?.designation
                          })`}
                          items={ele?.description}
                        />
                      );
                    })} */}
                    <hr className="b-d1d1" />
                  </React.Fragment>
                )}
              {/* {list?.length !== 0 && list?.publications?.length > 0 && (
                <React.Fragment>
                  <div
                    className="fa-center gap-2 flex-nowrap cmb-22"
                    style={{ padding: '14px' }}
                  >
                    <div className="w-18 h-18 f-center">
                      <img
                        src={icons.bPublication}
                        alt=""
                        className="fit-image"
                        loading="lazy"
                      />
                    </div>
                    <div className="text-14-500 color-48c0">Publications</div>
                  </div>
                  {list?.publications?.map((ele, index) => {
                    return (
                      <PubBlock
                        title={ele?.paper_title}
                        description={ele?.description}
                      />
                    );
                  })}

                  <hr className="b-d1d1" />
                </React.Fragment>
              )}
              {list?.length !== 0 && list?.current_journal_roles.length > 0 && (
                <React.Fragment>
                  <div
                    className="fa-center gap-2 flex-nowrap cmb-22"
                    style={{ padding: '14px' }}
                  >
                    <div className="w-18 h-18 f-center">
                      <img
                        src={icons.bJournal}
                        alt=""
                        className="fit-image"
                        loading="lazy"
                      />
                    </div>
                    <div className="text-14-500 color-48c0">Journal Roles</div>
                  </div>
                  {list?.current_journal_roles?.map((ele, index) => {
                    return (
                      <JorBlock
                        title={`${ele?.role} (${ele?.journals} ) `}
                        items={ele?.description}
                      />
                    );
                  })}

                  <hr className="b-d1d1" />
                </React.Fragment>
              )}
              {list?.length !== 0 && list?.achievements.length > 0 && (
                <React.Fragment>
                  <div
                    className="fa-center gap-2 flex-nowrap cmb-22"
                    style={{ padding: '14px' }}
                  >
                    <div className="w-18 h-18 f-center">
                      <img
                        src={icons.bAchievement}
                        alt=""
                        className="fit-image"
                        loading="lazy"
                      />
                    </div>
                    <div className="text-14-500 color-48c0">Achievements</div>
                  </div>
                  {list?.achievements.map((ele) => {
                    return (
                      <AcheiBlock
                        title={`${ele?.event_name} (${ele?.award_name} )`}
                        items={ele?.description}
                      />
                    );
                  })}
                </React.Fragment>
              )} */}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LinkCVPreview;
