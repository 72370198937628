import moment from "moment";
import { forEach } from "lodash";
import Dropdown from "components/form/Dropdown";
import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import {
  findOverallMax,
  getDataFromLocalStorage,
  getYearList,
  objectToFormData,
} from "utils/helpers";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMemberAnalytics } from "store/slices";

const MembersAnalyticsChart = ({ isReport, commonYear }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoadingMA, setIsLoadingMA] = useState(false);
  const [memberAnalyticData, setMemberAnalyticData] = useState({});
  const [year, setYear] = useState({
    memberYear: moment().format("YYYY"),
    countryWiseYear: moment().format("YYYY"),
  });

  const fetchMemberAnalytics = async (yearData) => {
    setIsLoadingMA(true);
    const response = await dispatch(
      getMemberAnalytics(objectToFormData(yearData))
    );
    setMemberAnalyticData(response?.data);
    setIsLoadingMA(false);
  };

  useEffect(() => {
    if (isReport) {
      fetchMemberAnalytics({ year: year?.memberYear });
    } else {
      if (["0", "6","1"].includes(getDataFromLocalStorage("user_type"))) {
        //new API
        fetchMemberAnalytics({
          year: commonYear ? commonYear?.memberYear : year?.memberYear,
        });
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonYear]);
  const { chart_by_year = {} } = memberAnalyticData || {};

  // For Member’s Analytics
  let newMemberChart = {
    categories: [],
    data: [
      { name: "Student Members", data: [] },
      { name: "Professional Members", data: [] },
      { name: "Institutional Members", data: [] },
    ],
  };

  forEach(chart_by_year, (items, key) => {
    let updatedDate = commonYear?.memberYear
      ? `${moment()
          .month(key - 1)
          .format("MMM")} ${commonYear?.memberYear}`
      : `${moment()
          .month(key - 1)
          .format("MMM")} ${year?.memberYear}`;
    newMemberChart?.categories.push(updatedDate);
    newMemberChart?.data[0]?.data?.push(items?.student_members);
    newMemberChart?.data[1]?.data?.push(items?.professional_members);
    newMemberChart?.data[2]?.data?.push(items?.institution_members);
  });

  const overallMaxForMember = findOverallMax(newMemberChart?.data, 0);

  let pathName = window.location.pathname;
  return (
    <div className="mt-3">
      {isLoadingMA ? (
        <Card className="cpt-80 cpb-80 center-flex">
          <Loader size="md" />
        </Card>
      ) : (
        <Card className="cps-30 cpe-30 cpt-30 cpb-30">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-2">
            <div className="text-18-600 title-text text-nowrap">
              IFERP Member’s Analytics
            </div>
            {!["/admin/dashboard", "/resource/dashboard"].includes(
              pathName
            ) && (
              <div className="d-flex">
                <Dropdown
                  options={getYearList(10).map((o) => {
                    return { ...o, name: o.id };
                  })}
                  optionValue="name"
                  onChange={(e) => {
                    let oldData = { year: e.target.value };
                    setYear({ ...year, memberYear: e.target.value });
                    fetchMemberAnalytics(oldData);
                  }}
                  value={year?.memberYear}
                  placeholder="Year"
                />
              </div>
            )}
          </div>
          <ReactApexChart
            options={{
              height: 400,
              type: "line",
              chart: {
                toolbar: {
                  show: false,
                },
              },
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              colors: ["#2479E0", "#55C7FA", "#8992e0"],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 3,
              },
              xaxis: {
                categories: newMemberChart?.categories,
              },
              yaxis: {
                min: 0,
                max: overallMaxForMember || 500,
              },
              legend: { show: true },
            }}
            series={newMemberChart?.data}
            type="line"
            height={400}
          />
        </Card>
      )}
    </div>
  );
};

export default MembersAnalyticsChart;
