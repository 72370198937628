import React, { useEffect, useMemo, useState } from "react";
import ReviewerDropdown from "../ReviewerDropdown";
import Button from "components/form/Button";
import ReviewerRemovePopup from "./ReviewerRemovePopup";
import TextArea from "components/form/TextArea";
import DatePicker from "components/form/DatePicker";
import moment from "moment";
import FileUpload from "components/form/FileUpload";
import {
  getFilenameFromUrl,
  getStatus,
  getStatusColor,
  objectToFormData,
  objectToQueryParams,
  titleCaseString,
} from "utils/helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllocateTimeAndChangeResultStatus,
  fetchResourceReviewers,
  throwError,
  throwSuccess,
  updateEventsSubmittedAbstractStatus,
} from "store/slices";
import Profile from "components/Layout/Profile";
import AddReviewerDropdownPopup from "./AddReviewerDropdownPopup";
import { useParams } from "react-router-dom";
import { icons } from "utils/constants";
import OldReviewReport from "./OldReviewReport";
// import NewReviewReport from "./NewReviewReport";
import RevisionReviewReportDetails from "./RevisionReviewReportDetails";

const time = [
  { id: 1, value: "1 Days" },
  { id: 2, value: "2 Days" },
  { id: 3, value: "3 Days" },
  { id: 4, value: "4 Days" },
  { id: 5, value: "5 Days" },
  // { id: 4, value: "Other" },
];

const ReviewState = ({
  // plagiarismAccepted,
  status,
  data,
  paperData,
  handleSuccess,
}) => {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const params = useParams();
  const dispatch = useDispatch();
  const [chooseTime, setChooseTime] = useState(null);
  const [isDeletePopup, setIsDeletePopup] = useState(null);
  const [isReviewerDetails, setIsReviewerDetails] = useState(false);
  const [isRevisionReviewerDetails, setIsRevisionReviewerDetails] =
    useState(false);
  const [fieldType, setFieldType] = useState(false);
  const [list, setList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnStatus, setBtnStatus] = useState("");
  const [isOldReport, setIsOldReport] = useState(false);
  // const [isNewReport, setIsNewReport] = useState(false);
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);
  const {
    event_id,
    id,
    allocated_reviewer,
    allocated_time,
    user_id,
    paperId,
    // paperTitle,
    // new_reviewer_revisoin,
    // old_reviewer_revisoin,
    submittedPapersStatus,
    reviewer_result_array,
  } = paperData || {};
  const { is_plagiarism_done, revision_status } = submittedPapersStatus || {};

  const { is_remodify } = data || {};

  const getAllReviewers = async () => {
    const response = await dispatch(
      fetchResourceReviewers(objectToQueryParams({ event_id: event_id }))
    );
    if (response?.status === 200) {
      let newList = response?.data?.reviewers;
      if (newList?.length > 0) {
        newList = response?.data?.reviewers?.filter((o) => o?.id !== +user_id);
      }
      setList(newList || []);
    }
  };

  const handleSendToReviewer = async () => {
    setBtnLoader(true);
    const payload = {
      id: id,
      paper_submitted_status: "3",
      allocated_time: chooseTime?.value,
      abstract_action: "Allocate Time",
    };
    const response = await dispatch(
      fetchAllocateTimeAndChangeResultStatus(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoader(false);
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Comments are required"),
  });
  const initialValues = {
    comment: "",
    republish_date: "",
    document: "",
    document_file_name: "",
  };

  const handelSave = (values, btnStatus, userID) => {
    setBtnLoader(btnStatus);
    let payloadData = {
      id: params.paperId,
      paper_submitted_status: "3",
      abstract_action: "Update Reviewer Status",
      reviewer_id: userID ? userID : isReviewerDetails?.user_id,
    };

    switch (btnStatus) {
      case "1":
        payloadData = {
          ...payloadData,
          acceptance_status: "1",
        };
        break;
      case "2":
        payloadData = {
          ...payloadData,
          acceptance_status: "2",
          revision_remarks: values?.comment,
          republish_date: values?.republish_date,
        };
        break;
      case "3":
        payloadData = {
          ...payloadData,
          acceptance_status: "3",
          reject_remarks: values?.comment,
          reject_report: values?.document,
        };
        break;

      default:
        break;
    }
    handelUpdate(payloadData);
  };

  const handelUpdate = async (obj) => {
    const payload = objectToFormData(obj);
    let response = await dispatch(updateEventsSubmittedAbstractStatus(payload));
    if (response?.status === 200) {
      handleSuccess();
    } else {
      setBtnLoader("");
    }
    setBtnLoader("");
  };

  useEffect(() => {
    getAllReviewers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReviewerDetails?.reviewer_result) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(isReviewerDetails?.reviewer_result)?.includes(s.id)) {
          return {
            ...s,
            score: isReviewerDetails?.reviewer_result[s?.id],
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewerDetails?.reviewer_result]);

  const averageScore = useMemo(() => {
    const totalScore = scores?.reduce((sum, item) => sum + item?.score, 0);
    return (totalScore / scores?.length)?.toFixed(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores]);

  const { paper_title, general_comment, recommendation, reviewer_result } =
    isReviewerDetails || {};

  const paperAccepted = is_remodify === "2";
  const paperRejected = is_remodify === "3";
  // const paperRevision = is_remodify === "1";
  const revision_allocated_reviewer = reviewer_result_array?.find(
    (o) => o?.allocated_reviewer
  );

  const updated_reviewer_result_array =
    (reviewer_result_array?.length > 1 &&
      reviewer_result_array?.slice(0, -1)) ||
    [];

  useEffect(() => {
    if (revision_allocated_reviewer?.allocated_reviewer?.length >= 0) {
      setIsRevisionReviewerDetails(true);
    }
  }, [revision_allocated_reviewer]);

  const {
    // view: viewAccess,
    update: updateAccess,
    delete: deleteAccess,
    create: createAccess,
  } = userDetails?.role_data?.find(
    (item) => item?.permission_name === "Event Management"
  )?.sub_permission || {};
  const isEdit = updateAccess === "1";
  const isCreate = createAccess === "1";
  const isDelete = deleteAccess === "1";

  return (
    <>
      {isDeletePopup && (
        <ReviewerRemovePopup
          onHide={() => {
            setIsDeletePopup(null);
          }}
          abstractId={id}
          elem={isDeletePopup}
          handleSuccess={() => {
            handleSuccess();
          }}
        />
      )}

      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            (is_plagiarism_done === "1" && status >= 2) || is_remodify > 0
              ? "active"
              : "not-active"
            // plagiarismAccepted && status >= 2 ? "active" : "not-active"
          }`}
        >
          {paperAccepted ? <img src={icons?.RightIcon} alt="right" /> : "3"}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              (is_plagiarism_done === "1" && status >= 2) || is_remodify > 0
                ? "color-new-car"
                : "color-6866"
              // plagiarismAccepted && status >= 2 ? "color-new-car" : "color-6866"
            }`}
          >
            Review
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        <div className="cpt-14 cpb-20 w-100 reviewer-state-block">
          {is_remodify !== "0" ? (
            <>
              {reviewer_result_array?.length === 0 && paperAccepted && (
                <div className="cpt-14 cpb-14 w-100 text-14-400-18 color-black-olive">
                  Review has been completed !!
                </div>
              )}
              {reviewer_result_array?.length === 0 && paperRejected && (
                <div className="cpt-14 cpb-14 w-100 text-14-400-18 color-black-olive">
                  Review has been Rejected !!
                </div>
              )}
              {/* {paperRevision && ( */}
              <>
                {updated_reviewer_result_array &&
                  updated_reviewer_result_array?.map((oldReport, i) => {
                    return (
                      <div
                        className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                        key={i + 1}
                      >
                        <div
                          className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                          onClick={() => {
                            setIsOldReport(isOldReport === i ? null : i);
                          }}
                        >
                          {i === 0 && <span>Review Report</span>}
                          {i > 0 && <span>Revision {i}</span>}
                          <i
                            className={`${
                              isOldReport === i
                                ? "bi bi-chevron-up"
                                : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                        {isOldReport === i && (
                          <OldReviewReport
                            oldData={oldReport}
                            paperData={paperData}
                            index={i}
                          />
                        )}
                        {/* {oldReport?.allocated_reviewer?.length > 0 &&
                            isOldReport === i &&
                            reviewer_result_array?.length - 1 === i && (
                              <RevisionReviewReportDetails
                                revisionAllocatedReviewer={
                                  revision_allocated_reviewer
                                }
                                time={time}
                                list={list}
                                handleSuccess={handleSuccess}
                                paperData={paperData}
                              />
                            )} */}
                      </div>
                    );
                  })}
                {/* 
                {revision_allocated_reviewer?.allocated_reviewer?.length >
                  1 && ( */}
                {revision_status === "1" &&
                  is_plagiarism_done === "1" &&
                  is_remodify === "1" && (
                    <div className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8">
                      <div
                        className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                        onClick={() => {
                          setIsRevisionReviewerDetails(
                            !isRevisionReviewerDetails
                          );
                        }}
                      >
                        <span>
                          Revision {updated_reviewer_result_array?.length}
                        </span>
                        <i
                          className={`${
                            isRevisionReviewerDetails
                              ? "bi bi-chevron-up"
                              : "bi bi-chevron-down"
                          }`}
                          style={{ fontSize: "15px" }}
                        />
                      </div>

                      {isRevisionReviewerDetails && (
                        <RevisionReviewReportDetails
                          revisionAllocatedReviewer={
                            revision_allocated_reviewer
                          }
                          time={time}
                          list={list}
                          handleSuccess={() => {
                            handleSuccess();
                          }}
                          paperData={paperData}
                          setIsDeletePopup={setIsDeletePopup}
                        />
                      )}
                    </div>
                  )}
                {/* {allocated_reviewer &&
                    allocated_reviewer?.map((oldReport, i) => {
                      return (
                        <div
                          className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                          key={i + 1}
                        >
                          <div
                            className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                            onClick={() => {
                              setIsOldReport(isOldReport === i ? null : i);
                            }}
                          >
                            <span>Review Report</span>
                            <i
                              className={`${
                                isOldReport === i
                                  ? "bi bi-chevron-up"
                                  : "bi bi-chevron-down"
                              }`}
                              style={{ fontSize: "15px" }}
                            />
                          </div>
                          {isOldReport === i && (
                            <OldReviewReport
                              oldData={oldReport}
                              paperData={paperData}
                              index={i}
                            />
                          )}
                        </div>
                      );
                    })} */}

                {/* {revArr?.map((elem, index) => {
                    return (
                      <div
                        className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                        key={index}
                      >
                        <div
                          className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                          onClick={() => {
                            setIsRevisionReviewerDetails(
                              isRevisionReviewerDetails === index ? null : index
                            );
                          }}
                        >
                          <span>Revision {index + 1}</span>
                          <i
                            className={`${
                              isRevisionReviewerDetails === index
                                ? "bi bi-chevron-up"
                                : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                        {isRevisionReviewerDetails === index && (
                          <RevisionReviewReportDetails
                            elem={elem}
                            time={time}
                            handleSuccess={handleSuccess}
                            paperData={paperData}
                          />
                        )}
                      </div>
                    );
                  })} */}

                {/* {old_reviewer_revisoin &&
                    old_reviewer_revisoin?.map((oldRepo, oldI) => {
                      const newIndex = allocated_reviewer?.length + oldI;
                      return (
                        <div
                          className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                          key={oldI + 1}
                        >
                          <div
                            className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                            onClick={() => {
                              setIsOldReport(
                                isOldReport === newIndex ? null : newIndex
                              );
                            }}
                          >
                            <span>Old Peer Review Report {oldI + 2}</span>
                            <i
                              className={`${
                                isOldReport === newIndex
                                  ? "bi bi-chevron-up"
                                  : "bi bi-chevron-down"
                              }`}
                              style={{ fontSize: "15px" }}
                            />
                          </div>
                          {isOldReport === newIndex && (
                            <OldReviewReport
                              oldData={oldRepo}
                              paperData={paperData}
                              index={oldI + 1}
                            />
                          )}
                        </div>
                      );
                    })} */}

                {/* {new_reviewer_revisoin &&
                    new_reviewer_revisoin?.map((newReport, newI) => {
                      return (
                        <div
                          className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                          key={newI + 1}
                        >
                          <div
                            className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                            onClick={() => {
                              setIsNewReport(!isNewReport);
                            }}
                          >
                            <span>New Peer Review Report {newI + 1}</span>
                            <i
                              className={`${
                                isNewReport
                                  ? "bi bi-chevron-up"
                                  : "bi bi-chevron-down"
                              }`}
                              style={{ fontSize: "15px" }}
                            />
                          </div>
                          {isNewReport && (
                            <NewReviewReport
                              elem={newReport}
                              paperData={paperData}
                              handelSave={handelSave}
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              btnLoader={btnLoader}
                            />
                          )}
                        </div>
                      );
                    })} */}
              </>
              {/* )} */}
            </>
          ) : (
            <>
              {is_plagiarism_done === "1" && status >= 2 ? (
                /* {plagiarismAccepted && status >= 2 ? ( */
                <div className="w-50 cpb-20">
                  <div className="w-100 d-flex align-items-center gap-3">
                    <div className="w-100 position-relative">
                      <ReviewerDropdown
                        disabled={!isCreate || !isEdit}
                        paperData={paperData}
                        // id="allocated_reviewers"
                        label={"Allocate Reviewer"}
                        isRequired
                        options={list}
                        placeholder="Search Reviewer"
                        optionValue="name"
                        optionKey="id"
                        handleSuccess={() => {
                          handleSuccess();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-100 cpt-20 cpb-20"></div>
              )}

              {allocated_reviewer?.length > 0 && (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-14-500-17 color-6866">
                      Allocated Reviewers :
                    </div>
                    <div className="d-flex">
                      <AddReviewerDropdownPopup
                        disabled={!isCreate || !isEdit}
                        options={list}
                        paperData={paperData}
                        handleSuccess={() => {
                          handleSuccess();
                        }}
                      />
                    </div>
                  </div>

                  <div className="row cmb-18">
                    {allocated_reviewer?.map((elem, index) => {
                      const {
                        name,
                        email_id,
                        member_id,
                        phone_number,
                        allocated_review,
                        profile_image,
                        area_of_interest,
                        paper_status,
                        acceptance_status,
                        reject_reason,
                      } = elem || {};

                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 gy-4"
                          key={index}
                        >
                          <div
                            className={`${allocated_time ? "pointer" : ""} ${
                              isReviewerDetails?.id === elem?.id
                                ? "bg-f6ff"
                                : ""
                            } cps-16 cpt-16 cpe-16 cpb-16 position-relative border rounded h-100`}
                          >
                            {!allocated_time && isDelete && (
                              <span
                                className="position-absolute pointer"
                                style={{ right: "20px" }}
                                onClick={() => {
                                  setIsDeletePopup(elem);
                                }}
                              >
                                <i className="bi bi-x-lg" />
                              </span>
                            )}
                            <div
                              className="row"
                              onClick={() => {
                                if (allocated_time) {
                                  setIsReviewerDetails(elem);
                                }
                              }}
                            >
                              {/* Image Section */}
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 cmb-15">
                                <div style={{ height: "114px" }}>
                                  <Profile
                                    url={profile_image}
                                    size="s-114"
                                    isS3UserURL
                                    text={name}
                                  />
                                  {/* <img
              className="img-fluid rounded w-100 h-100"
              src={profile_image}
              style={{ objectFit: "cover" }}
              alt="profile"
            /> */}
                                </div>
                              </div>

                              {/* Info Section */}
                              <div className="col-lg-8 col-md-8 col-sm-8 col-8 cmb-15">
                                <div className="d-flex flex-column">
                                  <div className="text-14-400-18 color-new-car cmb-15">
                                    {name}
                                  </div>
                                  <div className="text-12-400-15 color-new-car cmb-15">
                                    {member_id}
                                  </div>
                                  <div className="text-14-300-18 color-6866 cmb-15 d-flex align-items-center gap-2 text-wrap  flex-wrap">
                                    <i className="bi bi-envelope" /> {email_id}
                                  </div>
                                  <div className="text-14-300-18 color-6866 d-flex align-items-center gap-2">
                                    <i className="bi bi-telephone" />{" "}
                                    {phone_number}
                                  </div>
                                </div>
                              </div>

                              {/* Allocated Reviews */}
                              <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-15">
                                <div className="text-12-400-15 color-6866">
                                  Allocated Reviews
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-15">
                                <div className="text-12-400-15 color-6866">
                                  : {allocated_review}
                                </div>
                              </div>

                              {/* Area of Interest */}
                              <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                <div className="text-12-400-15 color-6866">
                                  Area of Interest
                                </div>
                              </div>
                              <div
                                className={`col-lg-8 col-md-6 col-sm-6 col-6 ${
                                  paper_status ? "" : "cmb-13"
                                }`}
                              >
                                <div className="text-12-400-15 color-6866">
                                  : {area_of_interest?.split(",")?.join(", ")}
                                </div>
                              </div>

                              {!["2"].includes(acceptance_status) &&
                                [0, 1].includes(elem?.paper_status) && (
                                  <>
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmt-10 cmb-13">
                                      <div className="text-14-400-18 color-black-olive">
                                        Paper Status
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmt-10 cmb-13">
                                      <div className="d-flex align-items-center text-14-400-18">
                                        :{" "}
                                        <span
                                          className={`${
                                            paper_status === 0
                                              ? "color-5110"
                                              : "color-a35b"
                                          } ms-1`}
                                        >
                                          {" "}
                                          {paper_status === 0
                                            ? "Pending"
                                            : "Completed"}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {acceptance_status && (
                                <>
                                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="text-14-400-18 color-black-olive">
                                      Reviewer Status
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="d-flex align-items-center text-14-400-18">
                                      :{" "}
                                      <span
                                        className={`${getStatusColor(
                                          acceptance_status
                                        )} ms-1`}
                                      >
                                        {" "}
                                        {getStatus(acceptance_status)}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}

                              {reject_reason && (
                                <>
                                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="text-14-400-18 color-black-olive">
                                      Reason
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="d-flex align-items-center text-14-400-18">
                                      :{" "}
                                      <span className="color-6866 ms-1">
                                        {" "}
                                        {reject_reason}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* {allocated_reviewer?.map((elem, index) => {
                      const {
                        name,
                        email_id,
                        member_id,
                        phone_number,
                        allocated_review,
                        profile_image,
                        area_of_interest,
                        paper_status,
                        acceptance_status,
                        reject_reason,
                      } = elem || {};

                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 gy-4"
                          key={index}
                        >
                          <div
                            className={`${allocated_time ? "pointer" : ""} ${
                              isReviewerDetails?.id === elem?.id
                                ? "bg-f6ff"
                                : ""
                            } cps-16 cpt-16 cpe-16 cpb-16 position-relative border rounded h-100`}
                          >
                            {!allocated_time && (
                              <span
                                className="position-absolute pointer"
                                style={{ right: "20px" }}
                                onClick={() => {
                                  setIsDeletePopup(elem);
                                }}
                              >
                                <i className="bi bi-x-lg" />
                              </span>
                            )}
                            <div
                              className="row"
                              onClick={() => {
                                if (allocated_time) {
                                  setIsReviewerDetails(elem);
                                }
                              }}
                            >
                              
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 cmb-15">
                                <div style={{ height: "114px" }}>
                                  <Profile
                                    url={profile_image}
                                    size="s-114"
                                    isS3UserURL
                                    text={name}
                                  />
                                </div>
                              </div>

                              
                              <div className="col-lg-8 col-md-8 col-sm-8 col-8 cmb-15">
                                <div className="d-flex flex-column">
                                  <div className="text-14-400-18 color-new-car cmb-15">
                                    {name}
                                  </div>
                                  <div className="text-12-400-15 color-new-car cmb-15">
                                    {member_id}
                                  </div>
                                  <div className="text-14-300-18 color-6866 cmb-15 d-flex align-items-center gap-2 text-wrap  flex-wrap">
                                    <i className="bi bi-envelope" /> {email_id}
                                  </div>
                                  <div className="text-14-300-18 color-6866 d-flex align-items-center gap-2">
                                    <i className="bi bi-telephone" />{" "}
                                    {phone_number}
                                  </div>
                                </div>
                              </div>

                              
                              <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-15">
                                <div className="text-12-400-15 color-6866">
                                  Allocated Reviews
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-15">
                                <div className="text-12-400-15 color-6866">
                                  : {allocated_review}
                                </div>
                              </div>

                              
                              <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                <div className="text-12-400-15 color-6866">
                                  Area of Interest
                                </div>
                              </div>
                              <div
                                className={`col-lg-8 col-md-6 col-sm-6 col-6 ${
                                  paper_status ? "" : "cmb-13"
                                }`}
                              >
                                <div className="text-12-400-15 color-6866">
                                  : {area_of_interest?.split(",")?.join(", ")}
                                </div>
                              </div>

                              {!["2"].includes(acceptance_status) &&
                                [0, 1].includes(elem?.paper_status) && (
                                  <>
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmt-10 cmb-13">
                                      <div className="text-14-400-18 color-black-olive">
                                        Paper Status
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmt-10 cmb-13">
                                      <div className="d-flex align-items-center text-14-400-18">
                                        :{" "}
                                        <span
                                          className={`${
                                            paper_status === 0
                                              ? "color-5110"
                                              : "color-a35b"
                                          } ms-1`}
                                        >
                                          {" "}
                                          {paper_status === 0
                                            ? "Pending"
                                            : "Completed"}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {acceptance_status && (
                                <>
                                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="text-14-400-18 color-black-olive">
                                      Reviewer Status
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="d-flex align-items-center text-14-400-18">
                                      :{" "}
                                      <span
                                        className={`${getStatusColor(
                                          acceptance_status
                                        )} ms-1`}
                                      >
                                        {" "}
                                        {getStatus(acceptance_status)}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}

                              {reject_reason && (
                                <>
                                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="text-14-400-18 color-black-olive">
                                      Reason
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-13">
                                    <div className="d-flex align-items-center text-14-400-18">
                                      :{" "}
                                      <span className="color-6866 ms-1">
                                        {" "}
                                        {reject_reason}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
                  </div>

                  {!allocated_time && (
                    <>
                      <div className="d-flex w-100 flex-column cmb-25">
                        <div className="text-14-500-17 color-6866 cmb-12">
                          Allocate Time :
                        </div>
                        <div className="d-flex gap-3">
                          {time?.map((elm) => {
                            return (
                              <span
                                className={`${
                                  chooseTime?.id === elm?.id ? "active" : ""
                                } allocate-time-chunks pointer`}
                                key={elm?.id}
                                onClick={() => {
                                  setChooseTime(elm);
                                }}
                              >
                                {elm?.value?.includes("1 Days")
                                  ? "1 Day"
                                  : elm?.value}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="d-flex">
                        <Button
                          text="Send to Reviewer"
                          className="h-35 cps-20 cpe-20"
                          btnStyle="primary-dark"
                          onClick={handleSendToReviewer}
                          btnLoading={btnLoader}
                          disabled={!chooseTime?.id || !isCreate || !isEdit}
                        />
                      </div>
                    </>
                  )}

                  {isReviewerDetails && (
                    <>
                      <div className="d-flex align-align-items-center cmb-38 gap-4">
                        <div className="d-flex flex-column">
                          <span className="text-14-500-17 color-6866 cmb-12">
                            Allocated Time :
                          </span>
                          <span className="allocate-time-chunks w-fit-content">
                            {allocated_time?.includes("1 Days")
                              ? "1 Day"
                              : allocated_time}
                          </span>
                        </div>
                        {[0, 1].includes(isReviewerDetails?.paper_status) && (
                          <div className="d-flex flex-column">
                            <span className="text-14-500-17 color-6866 cmb-12">
                              Paper Status :
                            </span>
                            <span className="paper-status-chunks w-fit-content">
                              {isReviewerDetails?.paper_status === 0
                                ? "Pending"
                                : "Completed"}
                            </span>
                          </div>
                        )}
                        {isReviewerDetails?.reject_reason && (
                          <div className="flex-column d-flex">
                            <span className="text-14-500-17 color-6866 cmb-12">
                              Reason for paper rejection :
                            </span>
                            <span className="">
                              {isReviewerDetails?.reject_reason}
                            </span>
                          </div>
                        )}
                      </div>

                      {[1]?.includes(isReviewerDetails?.paper_status) && (
                        <>
                          <div className="text-16-400-20 color-new-car border-bottom cpb-16 cmb-24">
                            Peer Review Report :
                          </div>
                          <div className="row gy-3 mb-md-4 mb-lg-0">
                            <div className="col-lg-7">
                              <div className="text-14-500-17 color-6866 cmb-12">
                                Abstract ID :
                              </div>
                              {paperId && (
                                <div className="text-14-300-18 color-6866 cmb-24">
                                  {paperId}
                                </div>
                              )}

                              <div className="text-14-500-17 color-6866 cmb-12">
                                Manuscript Title :
                              </div>
                              {paper_title && (
                                <div className="text-14-300-18 color-6866 cmb-24">
                                  {titleCaseString(paper_title)}
                                </div>
                              )}

                              <div className="text-14-500-17 color-6866 cmb-12">
                                Recommendation :
                              </div>
                              {recommendation && (
                                <div className="text-14-300-18 color-6866 cmb-24">
                                  {titleCaseString(recommendation)}
                                </div>
                              )}

                              <div className="text-14-500-17 color-6866 cmb-12">
                                General Comments :
                              </div>
                              {general_comment && (
                                <div className="text-14-300-18 color-6866">
                                  {titleCaseString(general_comment)}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-5">
                              <div className="text-14-500-17 color-6866 cmb-12">
                                Followed Rates :
                              </div>
                              <div className="text-14-300-17 color-6866 cmb-24">
                                (1 = Poor) , (2 = Fair) , (3 = Good) , (4 =
                                Excellent)
                              </div>
                              <div className="border br-5 cpt-25 cpb-16">
                                <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
                                  Evaluation Report
                                </div>
                                <div className="cpt-23 cpb-16 border-bottom">
                                  {scores?.map((item, index) => (
                                    <div
                                      className="d-flex align-items-center cmb-16 text-14-500-17 color-6866 row"
                                      key={index}
                                    >
                                      <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                                        {item?.label}{" "}
                                        <span className="ms-lg-4 ms-md-4 ms-sm-3">
                                          :
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                                        {item?.score}
                                      </div>
                                    </div>
                                  ))}
                                </div>

                                <div className="d-flex align-items-center text-14-500-17 color-6866 cpt-16 row">
                                  <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                                    Overall
                                    <span className="ms-lg-4 ms-md-4 ms-sm-3">
                                      :
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                                    {`${averageScore} ${
                                      averageScore > 0.0
                                        ? `(${
                                            averageScore <= 1
                                              ? "Poor"
                                              : averageScore <= 2
                                              ? "Fair"
                                              : averageScore <= 3
                                              ? "Good"
                                              : "Excellent"
                                          })`
                                        : ""
                                    }`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-16-500-20 color-6866 cmb-28 d-flex">
                            Abstract Result :
                            <div className="ms-4">
                              {reviewer_result?.abstract_result}
                            </div>
                          </div>
                          {!fieldType && (
                            <div className="d-flex gap-3 cmb-22">
                              <Button
                                disabled={!isCreate || !isEdit}
                                text="Proceed to Next"
                                className="h-35 cps-20 cpe-20"
                                btnStyle="primary-dark"
                                onClick={() => {
                                  setBtnStatus("1");
                                  handelSave(false, "1");
                                }}
                                btnLoading={btnLoader === btnStatus}
                              />
                              <Button
                                disabled={!isCreate || !isEdit}
                                text="Reject"
                                className="h-35 cps-20 cpe-20"
                                btnStyle="primary-dark"
                                onClick={() => {
                                  setFieldType("Reject");
                                }}
                              />
                              <Button
                                disabled={!isCreate || !isEdit}
                                text="Revision Needed"
                                className="h-35 cps-20 cpe-20"
                                btnStyle="primary-outline"
                                onClick={() => {
                                  setFieldType("Revision");
                                }}
                              />
                            </div>
                          )}

                          {fieldType && (
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={(
                                values,
                                { setSubmitting, setFieldError }
                              ) => {
                                if (btnStatus === "2") {
                                  if (!values?.republish_date) {
                                    setFieldError(
                                      "republish_date",
                                      "Republish date is required for revision"
                                    );
                                    setSubmitting(false);
                                    return;
                                  } else {
                                    handelSave(values, "2");
                                  }
                                } else if (btnStatus === "3") {
                                  if (!values?.document) {
                                    setFieldError(
                                      "document",
                                      "Report is required"
                                    );
                                    setSubmitting(false);
                                    return;
                                  } else {
                                    handelSave(values, "3");
                                  }
                                } else {
                                }

                                setSubmitting(false);
                              }}
                            >
                              {(props) => {
                                const {
                                  values,
                                  errors,
                                  handleChange,
                                  setFieldValue,
                                  handleSubmit,
                                } = props;

                                return (
                                  <form>
                                    <div className="cmb-16">
                                      <TextArea
                                        disabled={!isCreate || !isEdit}
                                        label="Revision Remarks :"
                                        labelClass="text-15-500 mb-1"
                                        placeholder="Give your comments..."
                                        id="comment"
                                        onChange={handleChange}
                                        value={values?.comment}
                                        error={errors?.comment}
                                        rows={3}
                                      />
                                    </div>
                                    {fieldType === "Revision" ? (
                                      <div className="cmb-16">
                                        <DatePicker
                                          disabled={!isCreate || !isEdit}
                                          id="republish_date"
                                          label="Republish Date *"
                                          labelClass="text-15-500 mb-1"
                                          placeholder="DD-MM-YYYY"
                                          minDate={moment()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "republish_date",
                                              e.target.value
                                            );
                                          }}
                                          value={values?.republish_date}
                                          error={errors?.republish_date}
                                        />
                                      </div>
                                    ) : (
                                      <div className="cmb-16">
                                        <FileUpload
                                          disabled={!isCreate || !isEdit}
                                          label="Report *"
                                          labelClass="text-15-500 mb-1"
                                          fileText={getFilenameFromUrl(
                                            values?.plagiarism_document_file_name
                                          )}
                                          id="document"
                                          onChange={(e) => {
                                            setFieldValue(
                                              "document",
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "document_file_name",
                                              e.target.fileName
                                            );
                                          }}
                                          value={values?.document}
                                          error={errors?.document}
                                        />
                                      </div>
                                    )}
                                    <div className="d-flex align-content-center gap-3">
                                      <Button
                                        disabled={!isCreate || !isEdit}
                                        text={
                                          fieldType === "Revision"
                                            ? "Sent Revision"
                                            : "Reject"
                                        }
                                        className="h-35 cps-20 cpe-20"
                                        btnStyle="primary-dark"
                                        onClick={() => {
                                          setBtnStatus(
                                            fieldType === "Revision" ? "2" : "3"
                                          );

                                          handleSubmit();
                                        }}
                                        btnLoading={btnLoader === btnStatus}
                                      />
                                      <Button
                                        disabled={!isCreate || !isEdit}
                                        text="Back"
                                        className="h-35 cps-20 cpe-20"
                                        btnStyle="primary-outline"
                                        onClick={() => {
                                          setFieldType(false);
                                        }}
                                      />
                                    </div>
                                  </form>
                                );
                              }}
                            </Formik>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewState;
