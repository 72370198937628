import React from 'react';
import { helpDeskMailIFERP } from 'utils/helpers';

const AcceptanceTemplatePreview = ({
  values,
  templatePreviewRef,
  paperData,
}) => {
  const {
    client_name,
    session_name,
    paper_title,
    location,
    date,
    bref_description_of_session,
    sdg_number,
    brief_description_of_sdg,
    conference_name,
    registration_deadline,
    coupon_code,
    discount_amount,
    your_organization_name,

    // conference_organizer,
    // presentation_type,
    // paper_id,
    // coAuthor_name,
    // relevant_field,
    // video_link,
    // complete_registration,
    // // support_email,
    // conference_theme,
    // your_contact_information,
    // your_website,
  } = values || {};
  return (
    <div ref={templatePreviewRef}>
      <p>
        <strong>
          {client_name
            ? `Dear ${client_name},`
            : ` Dear [Client Name] / Dr. [Client Name],`}
        </strong>
      </p>

      <p>
        Congratulations! We are pleased to inform you that your paper,{' '}
        <strong>
          {paper_title ? ` "${paper_title}" ` : ` "{PaperTitle}" `}
        </strong>
        , has been <strong>accepted </strong>
        for presentation at{' '}
        {conference_name ? conference_name : `[Conference Name]`} accredited by{' '}
        <strong>Continuous Professional Development (CPD) </strong>
        in <strong>{location ? location : `[Location]`}</strong> on{' '}
        <strong>{date ? date : `[Date]`}</strong>. 
        <br/>This is a significant
        milestone in your research journey, and we are excited to have you join
        us!
      </p>

      <p>
        Your submission has successfully passed our rigorous double-blind peer
        review and plagiarism check, demonstrating the significance and quality
        of your work.
      </p>

      <p>
        <strong>Indexing & Publication:</strong>
      </p>

      <p>
        Your paper will be submitted for evaluation and indexing in{' '}
        <strong>Web of Science (BkCI) </strong>and <strong>SCOPUS </strong>
        (terms and conditions apply). This will significantly boost the
        visibility and impact of your research. Alternatively, you can opt to
        publish your paper in a <strong>Scopus-indexed journal</strong>.
      </p>

      <p>
        <strong>Session and SDG Alignment</strong>
      </p>

      <p>
        Your paper will be presented in the{' '}
        <strong>{session_name ? session_name : `[Session Name]`}</strong>{' '}
        session, which is focused on{' '}
        {bref_description_of_session
          ? bref_description_of_session
          : `[brief description of session]`}
        . This session directly aligns with the{' '}
        {sdg_number ? sdg_number : `[SDG Number]`} (Sustainable Development
        Goal) and will contribute to discussions surrounding{' '}
        {brief_description_of_sdg
          ? brief_description_of_sdg
          : `[brief description
        of SDG]`}
        . Your research plays a crucial role in advancing solutions to these
        global challenges.
      </p>

      <p>
        <strong>CPD Benefits</strong>
      </p>
      <p>
        As an accepted presenter, you will receive a{' '}
        <strong>CPD Certificate</strong> for your participation and be eligible
        for <strong>CPD points</strong>. You will also enjoy{' '}
        <strong>1-year free iCPD membership</strong>, offering you access to
        exclusive resources and further professional development opportunities.
      </p>

      <p>
        <strong>Next Steps:</strong>
      </p>

      <p>
        To complete your participation, kindly <strong>register</strong> for the
        conference by{' '}
        <strong>
          {registration_deadline
            ? registration_deadline
            : `[Registration Deadline]`}
        </strong>
        .
      </p>

      <p>Registration Link</p>
      <p>
        Use the coupon code{' '}
        <strong>{coupon_code ? coupon_code : `[Coupon Code]`}</strong> for a{' '}
        {discount_amount ? discount_amount : `[Discount Amount]`}{' '}
        discount on your registration.
      </p>
      <p>Warm regards,</p>
      <p>
        {your_organization_name ? (
          your_organization_name
        ) : (
          `[Your Organization’s Name]`
        )}
      </p>
      {/* --------------------------------------------------------------------------------------------------- */}
    </div>
  );
};

export default AcceptanceTemplatePreview;
