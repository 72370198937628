import Card from "components/Layout/Card";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchEventPriceCategories } from "store/slices";
import EventPriceCategory from "./EventPriceCategory";
import PriceCategory from "./PriceCategory";
import { objectToFormData } from "utils/helpers";
import CreditDetails from "./CreditDetails";

const EventPriceDetailsManagement = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [eventCategoriesList, setEventCategoriesList] = useState([]);
  const [priceCategoriesList, setPriceCategoriesList] = useState([]);

  const getEventPriceCategories = async () => {
    const response = await dispatch(
      fetchEventPriceCategories(objectToFormData({ category_type: "3" }))
    );
    setEventCategoriesList(response?.data?.event_pricing || []);
    setPriceCategoriesList(response?.data?.pricing_category || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getEventPriceCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className={"cmb-23 cps-20 cpe-20 cpb-36 cpt-20"}>
        <div className="text-16-500-20 color-title-navy cmb-26">
          Pricing Category
        </div>
        <EventPriceCategory
          isLoading={isLoading}
          eventCategoriesList={eventCategoriesList}
          handleSuccess={() => {
            getEventPriceCategories();
          }}
        />
      </Card>
      <Card className={"cps-20 cpe-20 cpb-36 cpt-20 cmb-26"}>
        <div className="text-16-500-20 color-title-navy cmb-26">
          Registration Category
        </div>
        <PriceCategory
          isLoading={isLoading}
          priceCategoriesList={priceCategoriesList}
          handleSuccess={() => {
            getEventPriceCategories();
          }}
        />
      </Card>
      {/* Only Stage */}
      {/* <Card className={"cps-20 cpe-20 cpb-36 cpt-20"}>
        <div className="text-16-500-20 color-title-navy cmb-26">
          Cv AI Credits
        </div>
        <CreditDetails />
      </Card> */}
    </>
  );
};

export default EventPriceDetailsManagement;
