import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { cloneDeep, some } from 'lodash';
import Button from 'components/form/Button';
import TextInput from 'components/form/TextInput';
import UserDropdown from 'components/form/UserDropdown';
import UserCreatable from 'components/form/UserCreatable';
import { useDispatch, useSelector } from 'react-redux';
import { addPublication, GenreteResume, throwError } from 'store/slices';
import {
  getDataFromLocalStorage,
  handleCampaignLog,
  objectToFormData,
  trimAllSpace,
} from 'utils/helpers';

const Publications = ({
  onNext,
  onPrevious,
  userDetailslist,
  fetchDetails,
  handelCv,
  loadingCv,
  credit,
}) => {
  const dispatch = useDispatch();
  const userId = getDataFromLocalStorage('id');

  const { userDetails, researchProfile } = useSelector((state) => ({
    userDetails: state.student.userDetails,
    researchProfile: state.student.researchProfile,
  }));
  const { id, email_id, first_name, last_name } = userDetails;
  const { publication } = researchProfile || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnGeLoading, setBtnGeLoading] = useState(false);

  const handelSave = async (values) => {
    const isFormChanged =
      JSON.stringify(values) !== JSON.stringify(initialValues);

    // if (!isFormChanged) {
    //   onNext();
    //   return;
    // }
    setBtnLoading(true);
    const payload = values?.publication?.map((elm) => {
      return { ...elm, authors_email: elm?.authors ? '' : elm?.authors_email };
    });
    const responseTwo = await dispatch(addPublication(payload));
    if (responseTwo?.status === 200) {
      if (localStorage.getItem('isCampaign', true)) {
        handleCampaignLog({ user_id: id, type: 'update' }, dispatch);
      }
      fetchDetails();
      //await handelCv();
    }
    onNext();
    setBtnLoading(false);
  };

  const handelAddAuthor = (e, data) => {
    const { co_authors, authors_email } = data;
    let email = '';
    let name = '';
    if (e?.target?.isCreate) {
      email = trimAllSpace(e?.target?.value);
    } else {
      email = trimAllSpace(e?.target?.data?.email_id);
      name = e?.target?.data?.name;
    }
    const isValidEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(email);
    if (!isValidEmail) {
      dispatch(throwError({ message: 'Please enter valid email' }));
      return;
    }
    if (authors_email === email) {
      dispatch(
        throwError({ message: 'Author email cannot add as co-author.' })
      );
      return;
    }
    if (co_authors?.find((o) => o.email === email)) {
      dispatch(throwError({ message: 'This email already exist in list.' }));
      return;
    }
    return { email, name };
  };

  const validationSchema = Yup.object().shape({
    publication: Yup.array(
      Yup.object({
        authors: Yup.lazy((_, obj) => {
          const { is_email } = obj?.parent;
          if (is_email) {
            return Yup.string();
          } else {
            return Yup.string().required('Author is required.');
          }
        }),
        authors_email: Yup.lazy((_, obj) => {
          const { is_email } = obj?.parent;
          if (is_email) {
            return Yup.string()
              .required('Author email is required.')
              .email('Email must be a valid email');
          } else {
            return Yup.string();
          }
        }),
        paper_title: Yup.string().required('Paper title is required.'),
        issn: Yup.string().required('ISSN/ISBN is required.'),
        publication_link: Yup.string().url('Enter valid url.'),
      })
    ),
  });
  const defaultData = [
    {
      id: '',
      authors: id,
      authors_email: email_id,
      authors_name: `${first_name} ${last_name}`,
      is_email: false,
      paper_title: '',
      no_of_pages: '',
      issn: '',
      publication_link: '',
      co_authors: [],
    },
  ];
  const initialValues = {
    publication:
      publication.length === 0
        ? defaultData
        : publication?.map((elm) => {
            return {
              ...elm,
              authors_email:
                elm?.authors_email || elm?.author_details?.email || '',
              authors_name: elm?.author_details?.name || '',
            };
          }),
  };

  const handelGenreteResume = async () => {
    setBtnGeLoading(true);
    const payload = {
      id: userId,
    };
    const res = await dispatch(GenreteResume(objectToFormData(payload)));
    if (res?.status === 200) {
      setBtnGeLoading(false);
    }
    setBtnGeLoading(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSave}
    >
      {(props) => {
        const { values, errors, handleChange, handleSubmit, setFieldValue } =
          props;

        const isAddMore = some(
          values?.publication,
          (o) =>
            (!o?.authors && !o?.authors_email) || !o?.paper_title || !o?.issn
        );
        return (
          <div>
            {/* {values.publication.length - 1 === index && ( */}
            <div className="d-flex justify-content-end">
              <Button
                isSquare
                text="+ Add Another Publication"
                btnStyle="primary-outline-car"
                className="h-35 text-14-500"
                disabled={isAddMore}
                onClick={() => {
                  setFieldValue('publication', [
                    ...values.publication,
                    ...defaultData,
                  ]);
                }}
              />
            </div>

            {/* )} */}
            {values?.publication?.map((elem, index) => {
              const {
                authors,
                co_authors,
                paper_title,
                no_of_pages,
                issn,
                publication_link,
                is_email,
                authors_email,
                authors_name,
              } = elem;

              const {
                authors: errAuthors,
                paper_title: errPaperTitle,
                issn: errIssn,
                publication_link: errPublicationLink,
                authors_email: errEmail,
              } = errors?.publication?.[index] || {};

              let existingList = [];
              if (authors) {
                existingList = [
                  {
                    id: +authors,
                    name: authors_name || '',
                  },
                ];
              }

              return (
                <form className="row" key={index}>
                  <div className="fb-center">
                    <div className="mb-2 text-15-500">
                      Publication {index + 1}
                    </div>
                  </div>

                  <div className="cmb-18 col-md-6">
                    {is_email ? (
                      <TextInput
                        label="Authors"
                        placeholder="Enter author email for invite"
                        id={`publication[${index}][authors_email]`}
                        onChange={handleChange}
                        value={authors_email}
                        error={errEmail}
                      />
                    ) : (
                      <UserDropdown
                        label="Authors"
                        placeholder="Select Author"
                        id={`publication[${index}][authors]`}
                        value={+authors}
                        error={errAuthors}
                        existingList={existingList}
                        onChange={(e) => {
                          setFieldValue(
                            `publication[${index}][authors_email]`,
                            e?.target?.data?.email_id
                          );
                          handleChange(e);
                        }}
                      />
                    )}
                  </div>

                  <div className="cmb-18 col-md-6">
                    <UserCreatable
                      label="Co-Authors"
                      placeholder="Select Member Or Enter Email"
                      id={`publication[${index}][co_authors]`}
                      onChange={(e) => {
                        const res = handelAddAuthor(e, elem);
                        if (res?.email) {
                          const oldData = cloneDeep(co_authors);
                          oldData.push({ ...res, id: '' });
                          setFieldValue(
                            `publication[${index}][co_authors]`,
                            oldData
                          );
                        }
                      }}
                    />
                  </div>

                  {co_authors?.length > 0 && (
                    <div className="cmb-18 d-flex flex-wrap gap-2">
                      {co_authors?.map((elm, cindex) => (
                        <span
                          key={cindex}
                          className="d-flex align-items-center gap-1 border p-1 ps-2 pe-2 w-fit"
                        >
                          <span className="text-14-500">
                            {elm.name || elm.email}
                          </span>
                          <span className="ms-2">
                            <i
                              className="bi bi-trash-fill text-danger pointer"
                              onClick={() => {
                                const oldData = cloneDeep(co_authors);
                                const newArry = oldData.filter(
                                  (_, i) => i !== cindex
                                );
                                setFieldValue(
                                  `publication[${index}][co_authors]`,
                                  newArry
                                );
                              }}
                            />
                          </span>
                        </span>
                      ))}
                    </div>
                  )}

                  <div className="cmb-18 col-md-6">
                    <TextInput
                      label="Paper Title*"
                      placeholder="Enter paper title"
                      id={`publication[${index}][paper_title]`}
                      onChange={handleChange}
                      value={paper_title}
                      error={errPaperTitle}
                    />
                  </div>

                  <div className="cmb-18 col-md-6">
                    <TextInput
                      label="Publication Link"
                      placeholder="Enter Publication Link"
                      id={`publication[${index}][publication_link]`}
                      onChange={handleChange}
                      value={publication_link}
                      error={errPublicationLink}
                    />
                  </div>

                  <div className="cmb-18 col-md-6">
                    <TextInput
                      label="No. of Pages"
                      placeholder="Enter No. of Pages"
                      id={`publication[${index}][no_of_pages]`}
                      onChange={handleChange}
                      value={no_of_pages}
                    />
                  </div>

                  <div className="cmb-18 col-md-6">
                    <TextInput
                      label="ISSN/ISBN*"
                      placeholder="Enter ISSN/ISBN"
                      id={`publication[${index}][issn]`}
                      onChange={handleChange}
                      value={issn}
                      error={errIssn}
                    />
                  </div>
                  <div className="d-flex cmb-10">
                    {values?.publication.length > 1 && (
                      <Button
                        isSquare
                        text="Delete"
                        btnStyle="primary-gray"
                        icon={<i className="bi bi-trash me-2" />}
                        className="cpt-5 cpb-5 cps-10 cpe-10 h-35"
                        onClick={() => {
                          const listArray = cloneDeep(values?.publication);
                          listArray.splice(index, 1);
                          setFieldValue('publication', listArray);
                        }}
                      />
                    )}
                  </div>
                </form>
              );
            })}
            {/* {credit && ( */}
            <div className="text-14-400 color-dark-gray d-flex justify-content-end cmb-15">
              Total AI credits :
              <span className="text-16-500 cms-4"> {credit || 0}</span>
            </div>
            {/* )} */}

            <div className="d-flex justify-content-between gap-4">
              <button className="btn btn-outline-primary" onClick={onPrevious}>
                Previous
              </button>
              <div className="d-flex gap-2">
                {/* <Button
                
                text="Genrete AI Resume"
                btnStyle="primary-dark"
                className="cps-10 cpe-10"
                onClick={(e)=>{
                  e.preventDefault();  
    e.stopPropagation();
                  handelCv()
                }}
                btnLoading={loadingCv}
                disabled={loadingCv}
              />
        <Button text='Save' className="btn-primary cps-25 cpe-25"  onClick={handleSubmit} 
       btnLoading={btnLoading} disabled={btnLoading}  /> */}

                <Button
                  text="Save & Continue"
                  btnStyle="primary-dark"
                  className="cps-10 cpe-10"
                  onClick={handleSubmit} // On click, trigger handleClick
                  btnLoading={loadingCv}
                  disabled={loadingCv || btnLoading}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Publications;
