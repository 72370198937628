import Button from 'components/form/Button';
import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import { cloneDeep, isEqual, some } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import DatePicker from 'components/form/DatePicker';
import Dropdown from 'components/form/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { addAchievements, GenreteResume } from 'store/slices';
import {
  getDataFromLocalStorage,
  handleCampaignLog,
  objectToFormData,
} from 'utils/helpers';

const Achievements = ({
  onPrevious,
  fetchDetails,
  handelCv,
  loadingCv,
  credit,
}) => {
  const dispatch = useDispatch();
  const { researchProfile } = useSelector((state) => ({
    researchProfile: state.student.researchProfile,
  }));
  const { achievements } = researchProfile || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const id = getDataFromLocalStorage('id');

  const handelSave = async (values) => {
    setBtnLoading(true);
    const payload = { achievements: JSON.stringify(values.achievements) };
    console.log('payload', payload);
    const response = await dispatch(addAchievements(payload));
    if (response?.status === 200) {
      if (localStorage.getItem('isCampaign', true)) {
        handleCampaignLog({ user_id: id, type: 'update' }, dispatch);
      }
      fetchDetails();
      await handelCv();
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    achievements: Yup.array(
      Yup.object({
        award_name: Yup.string().required('Award name is required.'),
        event_name: Yup.string().required('Event is required.'),
        date: Yup.string().required('Date is required.'),
        category: Yup.string().required('Category is required.'),
      })
    ),
  });

  const initialValues = {
    achievements:
      achievements?.length === 0
        ? [
            {
              id: '',
              award_name: '',
              event_name: '',
              category: '',
              date: '',
            },
          ]
        : achievements,
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handelSave}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, errors, handleChange, handleSubmit, setFieldValue } =
            props;

          const isAddMoreAchivement = some(
            values?.achievements,
            (o) => !o?.award_name || !o?.event_name
          );

          return (
            <form>
              {/* Button at the top to add a new achievement */}

              <div className="row">
                {values?.achievements?.map((elem, index) => {
                  const { award_name, event_name, date, category } = elem;
                  const {
                    award_name: errAward,
                    event_name: errEvent,
                    date: errDate,
                    category: errCategory,
                  } = errors?.achievements?.[index] || {};
                  return (
                    <React.Fragment key={index}>
                      <div className="fb-center">
                        <div className="mb-2 text-15-500">
                          Achievements {index + 1}
                        </div>
                        {values.achievements.length - 1 === index && (
                          <Button
                            isSquare
                            text="+ Add Another Role"
                            btnStyle="primary-outline-car"
                            className="h-35 text-14-500"
                            disabled={isAddMoreAchivement}
                            onClick={() => {
                              setFieldValue('achievements', [
                                ...values.achievements,
                                {
                                  id: '',
                                  award_name: '',
                                  event_name: '',
                                  date: '',
                                  category: '',
                                },
                              ]);
                            }}
                          />
                        )}
                      </div>

                      <div className="col-md-6 cmb-22">
                        <TextInput
                          label="Award/Certificate Name*"
                          placeholder="Enter Award/Certificate Name"
                          onChange={handleChange}
                          value={award_name}
                          error={errAward}
                          id={`achievements[${index}][award_name]`}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <TextInput
                          label="Event Name*"
                          placeholder="Enter Event Name"
                          id={`achievements[${index}][event_name]`}
                          value={event_name}
                          error={errEvent}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <Dropdown
                          label="Category*"
                          placeholder="Select Category"
                          id={`achievements[${index}][category]`}
                          options={[
                            {
                              id: 'Honor',
                              label: 'Honor',
                            },
                            {
                              id: 'Elected',
                              label: 'Elected',
                            },
                          ]}
                          value={category}
                          error={errCategory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          label="Date*"
                          placeholder="Select Date"
                          id={`achievements[${index}][date]`}
                          value={date}
                          error={errDate}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        className={`d-flex gap-3 ${
                          values.achievements.length - 1 === index
                            ? ''
                            : ' cmb-22'
                        }`}
                      ></div>

                      {values?.achievements.length > 1 && (
                        <div className="d-flex justify-content-start mb-4">
                          <Button
                            isSquare
                            text="Delete"
                            btnStyle="primary-gray"
                            icon={<i className="bi bi-trash me-2" />}
                            className="cpt-5 cpb-5 cps-10 cpe-10"
                            onClick={() => {
                              const updatedAchievements = cloneDeep(
                                values?.achievements
                              );
                              updatedAchievements.splice(index, 1); // Remove the achievement at the current index
                              setFieldValue(
                                'achievements',
                                updatedAchievements
                              );
                            }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              {/* {credit && ( */}
              <div className="text-14-400 color-dark-gray d-flex justify-content-end cmb-15">
                Total AI credits :
                <span className="text-16-500 cms-4"> {credit || 0}</span>
              </div>
              {/* )} */}
              <div className="d-flex justify-content-between gap-4">
                <button
                  className="btn btn-outline-primary"
                  onClick={onPrevious}
                >
                  Previous
                </button>
                <div className="d-flex gap-2">
                  {/* <Button
                
                text="Genrete AI Resume"
                btnStyle="primary-dark"
                className="cps-10 cpe-10"
                onClick={(e)=>{
                  e.preventDefault();  
    e.stopPropagation();
                  handelCv()
                }}
                btnLoading={loadingCv}
                disabled={loadingCv}
              />
        <Button
                
                  text="Save"
                  btnStyle="primary-dark"
                  className="cps-40 cpe-40"
                  onClick={handleSubmit}
                  btnLoading={btnLoading}
                 // disabled={isEqual(values,initialValues)||btnLoading}
                /> */}

                  <Button
                    text="Generate AI Resume"
                    btnStyle="primary-dark"
                    className="cps-10 cpe-10"
                    onClick={handleSubmit} // On click, trigger handleClick
                    btnLoading={loadingCv}
                    disabled={loadingCv || btnLoading}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Achievements;
