import Button from 'components/form/Button';
import CheckBox from 'components/form/CheckBox';
import FollowUpDropdown from 'components/form/FollowUpDropdown';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { omit } from 'lodash';
import {
  encrypt,
  getEmailSubject,
  getRegistrationTemplatesPreview,
} from 'utils/helpers';
import RegistrationFollowUpsTemplatesForm from './RegistrationFollowUpsTemplatesForm';
import {
  // templateInitialValues,
  usePrefilledInitialsValues,
} from 'utils/helpers/ResourceSideNewAbstractFlow/initialValues';
import RegistrationPayment from './RegistrationPayment/RegistrationPayment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  reSendFollowUpsData,
  sendFollowUpsData,
  throwError,
  throwSuccess,
} from 'store/slices';
import moment from 'moment';
import { icons } from 'utils/constants';

const RegistrationState = ({ status, data, paperData, handleSuccess }) => {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const prefilledValues = usePrefilledInitialsValues();
  const templatePreview = useRef(null);
  const params = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [iseditInfo, setIseditInfo] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = useState('');
  const [tabType, setTabType] = useState('0');
  const [initialValues, setInitialValues] = useState({
    followUps: '',
    preferences: {
      whatsapp: '',
      // sms: "",
      email: '',
    },
  });
  const validationSchema = Yup.object().shape({
    // followUps: Yup.string().required("Follow ups is required"),
    preferences: Yup.object()
      .shape({
        whatsapp: Yup.string(),
        // sms: Yup.string(),
        email: Yup.string(),
      })
      .test(
        'at-least-one-selected',
        'At least one option must be selected',
        (value) => value?.whatsapp || value?.email
        // || value?.sms
      ),
  });

  const handleSend = async (value) => {
    // setBtnLoading(true);

    if (templatePreview?.current) {
      const htmlContent = templatePreview.current.innerHTML.trim();

      const mailSubject = getEmailSubject(value);
      const { followUps, preferences, ...rest } = value;
      const payload = {
        abstact_id: params?.paperId,
        followups_status: value?.followUps,
        mail_subject: mailSubject,
        followups_data: JSON.stringify(rest),
        mail_template: encrypt(htmlContent),
      };
      const formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }
      for (let key in value?.preferences) {
        formData.append(`send_via[]`, value?.preferences[key]);
      }
      let response;
      if (btnLoading === 'send') {
        response = await dispatch(sendFollowUpsData(formData));
      } else {
        response = await dispatch(reSendFollowUpsData(formData));
      }
      if (response?.status === 200) {
        if (formRef.current) {
          formRef.current.resetForm();
        }
        dispatch(throwSuccess(response?.message));
        setSelectedOptionValue('');
        handleSuccess();
      } else {
        dispatch(throwError({ message: response?.messsage }));
      }

      setBtnLoading(false);
    }
  };

  const handleFollowUpChange = (e, setFieldValue) => {
    const selectedFollowUp = e.target.value;
    const previewFormDetails = e.target.data?.follow_ups_data;

    let parsedDetails = {};
    if (previewFormDetails) {
      parsedDetails = JSON.parse(previewFormDetails);
    }

    // setSelectedOptionValue(selectedFollowUp);
    const templateValues = prefilledValues[selectedFollowUp] || {};

    // const templateValues2 = templateInitialValues[selectedFollowUp] || {};

    const updatedValues = {
      followUps: selectedFollowUp,
      ...templateValues,
      ...parsedDetails,
    };

    Object.keys(updatedValues)?.forEach((key) => {
      setFieldValue(key, updatedValues[key]);
    });

    setInitialValues(updatedValues);
  };

  const handleFollowUpChangeWrapper = (e, setFieldValue) => {
    handleFollowUpChange(e, setFieldValue);
    setSelectedOptionValue(e.target.value);
  };

  useEffect(() => {
    if (
      paperData?.submittedPapersStatus?.follow_ups_data &&
      selectedOptionValue
    ) {
      const parseData = JSON.parse(
        paperData?.submittedPapersStatus?.follow_ups_data
      );

      setInitialValues((prev) => ({
        ...prev,
        ...parseData,
      }));
    }
  }, [paperData?.submittedPapersStatus?.follow_ups_data, selectedOptionValue]);

  useEffect(() => {
    if (paperData?.abstract_payment) {
      setTabType('1');
    }
  }, [paperData?.abstract_payment]);

  const { submittedPapersStatus, follow_ups_stage, payment_status } =
    paperData || {};
  const { acceptance_letter } = submittedPapersStatus || {};
  const isActive = status >= 4 && acceptance_letter;
  const paymentIsAccepted = ['1'].includes(payment_status);
  const isRegistrationStateDone = paymentIsAccepted && status >= 5;

  const activeTab = 'text-19-400-24 color-new-car active-text-underline';
  const inactiveTab = 'text-19-400-24 color-6866 pointer';

  const {
    // view: viewAccess,
    update: updateAccess,
    // delete: deleteAccess,
    create: createAccess,
  } =
    userDetails?.role_data?.find(
      (item) => item?.permission_name === 'Event Management'
    )?.sub_permission || {};
  const isEdit = updateAccess === '1';
  const isCreate = createAccess === '1';
  console.log('✌️isCreate --->', isCreate);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className={`steps-block ${isActive ? 'active' : 'not-active'}`}>
          {isRegistrationStateDone ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            '5'
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActive ? 'color-new-car' : 'color-6866'
            }`}
          >
            Registration
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        <div className="w-100 cpt-20 cpb-20">
          {isActive ? (
            paymentIsAccepted ? (
              <div className="cpt-10 cpb-10 w-100 text-14-400-18 color-black-olive">
                Registration successfully completed !!
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center gap-4 cmb-40">
                  <div
                    className={tabType === '0' ? activeTab : inactiveTab}
                    onClick={() => {
                      setTabType('0');
                    }}
                  >
                    Follow Ups
                  </div>
                  <div className={tabType === '1' ? activeTab : inactiveTab}>
                    Payment
                  </div>
                </div>

                {tabType === '0' && (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSend}
                    innerRef={formRef}
                  >
                    {(props) => {
                      const { values, errors, setFieldValue, handleSubmit } =
                        props;
                      const { preferences } = values;

                      return (
                        <>
                          <form>
                            <div className="d-flex gap-5 cmb-20">
                              <div>
                                <FollowUpDropdown
                                  labelClass={'color-1717'}
                                  id="followUps"
                                  label="Follow ups"
                                  options={paperData?.follow_ups_stage}
                                  // options={followUpsOptions}
                                  optionKey={'stage'}
                                  optionValue={'stage'}
                                  value={values?.followUps}
                                  onChange={(e) => {
                                    handleFollowUpChangeWrapper(
                                      e,
                                      setFieldValue
                                    );
                                    // handleFollowUpChange(e, setFieldValue);
                                    setIseditInfo(true);
                                    setIsFollowUp(true);
                                  }}
                                  // error={errors?.followUps}
                                  disabled={!isCreate}
                                />
                              </div>
                              <div className="f-center justify-content-start">
                                <div className="text-14-300-18 cmb-14 ">
                                  Send Via :
                                </div>
                                <div className="d-flex gap-3">
                                  <div className="d-flex align-items-center gap-2">
                                    <CheckBox
                                      type="ACTIVE"
                                      isChecked={preferences?.whatsapp}
                                      onClick={() => {
                                        if (preferences?.whatsapp) {
                                          setFieldValue(
                                            'preferences.whatsapp',
                                            ''
                                          );
                                        } else {
                                          setFieldValue(
                                            'preferences.whatsapp',
                                            'Whatsapp'
                                          );
                                        }
                                      }}
                                    />

                                    <span className="text-black-olive text-14-300-18">
                                      Whats App
                                    </span>
                                  </div>
                                  {/* <div className="d-flex align-items-center justify-content-center gap-2">
                                    <CheckBox
                                      type="ACTIVE"
                                      isChecked={preferences?.sms}
                                      onClick={() => {
                                        if (preferences?.sms) {
                                          setFieldValue("preferences.sms", "");
                                        } else {
                                          setFieldValue(
                                            "preferences.sms",
                                            "Sms"
                                          );
                                        }
                                      }}
                                    />

                                    <span className="text-black-olive text-14-300-18">
                                      SMS
                                    </span>
                                  </div> */}
                                  <div className="d-flex align-items-center justify-content-center gap-2">
                                    <CheckBox
                                      type="ACTIVE"
                                      isChecked={preferences?.email}
                                      onClick={() => {
                                        if (preferences?.email) {
                                          setFieldValue(
                                            'preferences.email',
                                            ''
                                          );
                                        } else {
                                          setFieldValue(
                                            'preferences.email',
                                            'Email'
                                          );
                                        }
                                      }}
                                    />

                                    <span className="text-black-olive text-14-300-18">
                                      E-Mail
                                    </span>
                                  </div>
                                </div>
                                {errors?.preferences && (
                                  <span
                                    className="text-13-400 pt-1 d-flex"
                                    style={{ color: 'red' }}
                                  >
                                    {errors?.preferences}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 cmb-20">
                              <Button
                                text="Send"
                                btnStyle="primary-dark"
                                className="h-35 cps-20 cpe-20"
                                onClick={() => {
                                  if (!errors?.preferences) {
                                    setBtnLoading('send');
                                    handleSubmit();
                                  }
                                }}
                                btnLoading={btnLoading === 'send'}
                                disabled={
                                  errors?.preferences ||
                                  // Object.values(
                                  //   omit(values, ['preferences'])
                                  // ).some((value) => !value) ||
                                  !isCreate
                                }
                              />
                            </div>
                          </form>
                          <Row>
                            <Col lg={6}>
                              <div
                                className={`cmb-18 br-5 ${
                                  iseditInfo ? 'b-0-7-5' : ''
                                }`}
                              >
                                <div className="bg-E9EE br-5 text-14-500-17 d-flex flex-column">
                                  <div
                                    className="cps-20 cpt-12 cpe-20 color-new-car cpb-12 d-flex align-items-center justify-content-between pointer flex-grow-1"
                                    onClick={() => {
                                      setIseditInfo(!iseditInfo);
                                    }}
                                  >
                                    <div>Edit Information</div>
                                    <i
                                      className={`${
                                        iseditInfo
                                          ? 'bi bi-chevron-up'
                                          : 'bi bi-chevron-down'
                                      }`}
                                      style={{ fontSize: '15px' }}
                                    />
                                  </div>
                                  {iseditInfo && (
                                    <div className="cp-20 bg-white br-5 h-300 iferp-scroll overflow-auto">
                                      <RegistrationFollowUpsTemplatesForm
                                        props={props}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className={`cmb-18 br-5 ${
                                  isFollowUp ? 'b-0-7-5' : ''
                                }`}
                              >
                                <div className="bg-E9EE br-5 text-14-500-17 d-flex flex-column">
                                  <div
                                    className="cps-20 cpt-12 cpe-20 color-new-car cpb-12 d-flex align-items-center justify-content-between pointer flex-grow-1"
                                    onClick={() => {
                                      setIsFollowUp(!isFollowUp);
                                    }}
                                  >
                                    <div>Follow Ups</div>
                                    <i
                                      className={`${
                                        isFollowUp
                                          ? 'bi bi-chevron-up'
                                          : 'bi bi-chevron-down'
                                      }`}
                                      style={{ fontSize: '15px' }}
                                    />
                                  </div>
                                  {isFollowUp && (
                                    <div className="cp-20 bg-white br-5">
                                      <div className="h-360 iferp-scroll overflow-auto">
                                        {follow_ups_stage?.map(
                                          (elem, index) => {
                                            const {
                                              stage,
                                              is_active,
                                              is_next_or_prev,
                                              display_paid,
                                            } = elem;
                                            const isActive =
                                              stage === values?.followUps;
                                            const isCompleted =
                                              is_active === '1';
                                            const isDisplayedButtons =
                                              display_paid === 1;

                                            const isCompletedStage =
                                              follow_ups_stage?.find(
                                                (o) =>
                                                  o?.stage === stage &&
                                                  o?.is_active === '1'
                                              );

                                            return (
                                              <React.Fragment key={index}>
                                                <div className="d-flex justify-content-start align-items-center">
                                                  <div
                                                    className={`steps-block-follow ${
                                                      isActive ||
                                                      is_next_or_prev === '1'
                                                        ? 'active'
                                                        : 'not-active'
                                                    }`}
                                                  ></div>
                                                  <div>
                                                    <div
                                                      style={{
                                                        maxHeight: '20px',
                                                      }}
                                                      className={`text-16-500-20 ${
                                                        isActive ||
                                                        is_next_or_prev === '1'
                                                          ? 'color-new-car'
                                                          : 'color-6866'
                                                      }`}
                                                    >
                                                      {stage}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="d-flex justify-content-start">
                                                  <div className="sparator-block-follow">
                                                    <span className="hr-line-follow" />
                                                  </div>
                                                  <div className="cpt-20 cpb-20">
                                                    {isActive ||
                                                    is_next_or_prev === '1' ? (
                                                      <>
                                                        {isCompleted &&
                                                          !isDisplayedButtons && (
                                                            <>
                                                              <div className="color-6866 text-12-400-15 cmb-14">
                                                                {moment(
                                                                  isCompletedStage?.send_at
                                                                ).format(
                                                                  'DD-MMM-YYYY'
                                                                )}
                                                              </div>
                                                              <div className="d-flex align-items-center gap-3 cmb-14">
                                                                <div className="cps-15 cpe-15 cpt-8 cpb-8 bg-effed color-a35b br-40 text-12-500-15">
                                                                  Completed
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        {isDisplayedButtons && (
                                                          <>
                                                            <div className="color-6866 text-12-400-15 cmb-14">
                                                              {moment(
                                                                isCompletedStage?.send_at
                                                              ).format(
                                                                'DD-MMM-YYYY'
                                                              )}
                                                            </div>

                                                            <div className="d-flex align-items-center gap-3 cmb-14">
                                                              <div className="cps-15 cpe-15 cpt-8 cpb-8 bg-effed color-a35b br-40 text-12-500-15">
                                                                Completed
                                                              </div>
                                                              <div className="d-flex">
                                                                <Button
                                                                  isRounded
                                                                  icon={
                                                                    <i className="bi bi-arrow-clockwise" />
                                                                  }
                                                                  text="Resend"
                                                                  btnStyle="primary-dark"
                                                                  className="cps-15 cpe-15 gap-2 h-30 text-12-400-15"
                                                                  btnLoading={
                                                                    btnLoading ===
                                                                    'resend'
                                                                  }
                                                                  disabled={
                                                                    errors?.preferences ||
                                                                    Object?.values(
                                                                      omit(
                                                                        values,
                                                                        [
                                                                          'preferences',
                                                                        ]
                                                                      )
                                                                    )?.some(
                                                                      (value) =>
                                                                        !value
                                                                    )
                                                                  }
                                                                  onClick={() => {
                                                                    if (
                                                                      !errors?.preferences
                                                                    ) {
                                                                      setBtnLoading(
                                                                        'resend'
                                                                      );
                                                                      handleFollowUpChangeWrapper(
                                                                        {
                                                                          target:
                                                                            {
                                                                              data: elem,
                                                                              id: 'followUps',
                                                                              value:
                                                                                elem.stage,
                                                                            },
                                                                        },
                                                                        setFieldValue
                                                                      );
                                                                      handleSubmit();
                                                                    }
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="d-flex">
                                                              <Button
                                                                isRounded
                                                                text="Paid"
                                                                btnStyle="primary-dark"
                                                                className="cps-20 cpe-20 gap-2 h-30 text-12-400-15"
                                                                onClick={() => {
                                                                  setTabType(
                                                                    '1'
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    ) : (
                                                      !isActive &&
                                                      !is_next_or_prev && (
                                                        <div className="cps-20 cpe-20 cpt-8 cpb-8"></div>
                                                      )
                                                    )}

                                                    {(isActive ||
                                                      is_next_or_prev ===
                                                        '1') &&
                                                      !isCompleted && (
                                                        <div className="cps-20 cpe-20 cpt-8 cpb-8 bg-f4fc color-E751 br-40 text-12-400-15">
                                                          Pending
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                            {values?.followUps && (
                              <Col lg={6}>
                                <div className="bg-FBF6">
                                  <div
                                    className="h-538 iferp-scroll overflow-auto cp-20"
                                    ref={templatePreview}
                                  >
                                    {getRegistrationTemplatesPreview(values)}
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </>
                      );
                    }}
                  </Formik>
                )}

                {tabType === '1' && (
                  <>
                    <RegistrationPayment
                      paperData={paperData}
                      handleSuccess={handleSuccess}
                    />
                  </>
                )}
              </>
            )
          ) : (
            <div className=" "></div>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationState;
