import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBox from 'components/form/CheckBox';
import Button from 'components/form/Button';
import Card from 'components/Layout/Card';
import Table from 'components/Layout/Table';
import DeletePopup from 'components/Layout/DeletePopup';
import FilterDropdown from 'components/Layout/FilterDropdown';
import ExportButton from 'components/Layout/ExportButton';
import { getDataFromLocalStorage, objectToFormData } from 'utils/helpers';
import { icons } from 'utils/constants';
import {
  deleteMembers,
  exportProfile,
  fetchAllProfiles,
  fetchAllResourceProfiles,
  getAllUserTypeReportData,
  getSourceList,
  resourceCountriesList,
  setIsAllProfileAPICall,
  setIsEditDataOfProfile,
  setIsEditUserProfile,
  throwError,
  throwSuccess,
} from 'store/slices';
import AddMember from './AddMember';
import ProfileDetail from './ProfileDetail';
import ProfileDeleteRequestPopup from 'components/Layout/ProfileDeleteRequestPopup';

// const getPercentageColor = (percentage) => {
//   if (percentage >= 80) {
//     return "color-success";
//     // return "color-a21a";
//   } else if (percentage >= 50) {
//     return "color-warning";
//     // return "color-a500";
//   } else if (percentage < 50) {
//     return "color-danger";
//     // return "color-4646";
//   }
// };

const AllProfiles = ({ tabType }) => {
  const params = useParams();
  const { type } = params;
  const isSingleCountryReport = type === 'country-wise';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    membershipList,
    resourceCountryList,
    isAllProfileAPICall,
    userDetails,
  } = useSelector((state) => ({
    membershipList: state.global.membershipList,
    resourceCountryList: state.global.resourceCountryList,
    isAllProfileAPICall: state.global.isAllProfileAPICall,
    userDetails: state.student.userDetails,
  }));
  const {
    view: viewAccess,
    update: updateAccess,
    delete: deleteAccess,
    create: createAccess,
  } = userDetails?.role_data?.find(
    (item) => item?.permission_name === 'Profile Management'
  )?.sub_permission || {};

  const { user_type: userType, resource_role } =
    getDataFromLocalStorage() || {};
  // const userType = getDataFromLocalStorage("user_type");
  const [isDeleteRequestId, setIsDeleteRequestId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteLoadingType, setDeleteLoadingType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userid, setUserid] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [isAddMember, setAddMember] = useState(false);
  const [isDeatils, setDeatils] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [newDates, setNewDates] = useState({
    start_date: '',
    end_date: '',
    date_filter: '',
  });
  const [searchPayload, setSearchPayload] = useState({
    name: '',
    membership_id_or_type: '',
    email: '',
    phoneno: '',
    join_date: '',
    valid_date: '',
    country: '',
    profile_completion: '',
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 20,
    membership_plan_ids: '',
    is_non_allocated_resource: '',
    temporary_users: '',
    tab: 'pending_request',
    ...(userType === '6' && { resource_type: resource_role }),
  });
  const [filterDates, setFilterDates] = useState({
    start_date: '',
    end_date: '',
    date_filter: '',
  });
  const [sourceList, setSourceList] = useState([]);

  const getProfiles = async (obj) => {
    let forData = '';
    let modifiedObj = {
      ...obj,
    };

    if (
      obj?.join_date !== 'custom' &&
      obj?.valid_date !== 'custom' &&
      obj?.start_date &&
      obj?.end_date
    ) {
      delete modifiedObj.start_date;
      delete modifiedObj.end_date;
      forData = objectToFormData(modifiedObj);
    } else {
      forData = objectToFormData(obj);
    }
    let response = null;
    if (isSingleCountryReport) {
      response = await dispatch(getAllUserTypeReportData(forData));
      let resList = [];
      let resResultCount = 0;
      if (response?.data?.data) {
        resList = response?.data?.data || [];
        resResultCount = response?.data?.result_count || 0;
      }
      setTableList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    } else {
      if (userType === '6') {
        response = await dispatch(fetchAllResourceProfiles(forData));
      } else {
        response = await dispatch(fetchAllProfiles(forData));
      }
      let resList = [];
      let resResultCount = 0;
      if (response?.data?.users) {
        resList = response?.data?.users || [];
        resResultCount = response?.data?.result_count || 0;
      }
      setTableList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    }

    window.scrollTo(0, 0);
    setIsLoading(false);
  };
  const handelChangeFilter = (val) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, membership_plan_ids: val, offset: 0 };
    setFilterData(newData);
    getProfiles(newData);
  };
  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getProfiles(newData);
  };
  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getProfiles(newData);
  };
  const handelDeleteRequest = async (payload) => {
    setDeleteLoadingType(payload?.status === '1' ? 'approve' : 'ignore');
    setDeleteLoading(payload?.userid);
    const response = await dispatch(deleteMembers(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      getProfiles({ ...filterData, ...searchPayload });
    } else {
      dispatch(throwError(response?.message));
    }
    setDeleteLoading(false);
    setDeleteLoadingType(false);
  };

  const fetchSourceList = async () => {
    const response = await dispatch(getSourceList());
    setSourceList(response?.data || []);
  };

  useEffect(() => {
    fetchSourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (['0', '1', '6'].includes(userType)) {
      let updatedFilterData = { ...filterData, ...searchPayload };
      if (tabType === '0') {
        delete updatedFilterData.tab;
      }
      if (isSingleCountryReport) {
        const urlParams = new URLSearchParams(window.location.search);
        const countryCode = urlParams.get('country_code');
        if (countryCode) {
          delete updatedFilterData.tab;
          getProfiles({ ...updatedFilterData, country_code: countryCode });
        }
      } else {
        getProfiles({ ...updatedFilterData });
      }
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabType, isSingleCountryReport, searchPayload]);

  const fetchResourceCountry = async () => {
    await dispatch(resourceCountriesList());
  };

  useEffect(() => {
    if (userType === '6') fetchResourceCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    const updatedDates = {
      start_date: filterDates?.start_date
        ? filterDates?.start_date
        : filterData?.start_date
        ? moment(filterData?.start_date).format('YYYY-MM-DD')
        : '',
      end_date: filterDates?.end_date
        ? filterDates?.end_date
        : filterData?.end_date
        ? moment(filterData?.end_date).format('YYYY-MM-DD')
        : '',
      date_filter: filterDates?.date_filter ? filterData?.date_filter : '',
    };

    // Pass this to the ExportButton or wherever it's needed
    setNewDates(updatedDates); // Assuming you have setNewDates as a state setter function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPayload, filterDates, filterData]);

  useEffect(() => {
    if (isAllProfileAPICall) {
      getProfiles({ ...filterData, ...searchPayload });
      dispatch(setIsAllProfileAPICall(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllProfileAPICall]);

  let header = [
    {
      isSearch: false,
      searchInputName: 'name',
      title: 'S.No',
    },
    {
      isSearch: true,
      searchInputName: 'name',
      title: 'Name',
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: sourceList,
        key: 'source',
        value: 'source',
      },
      searchInputName: 'source',
      title: <div className="text-nowrap ms-4 me-4">Source</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: [
          { value: 'Above 80%', name: 'Above 80' },
          { value: '50 - 79%', name: '50 - 79' },
          { value: 'Below 50%', name: 'Below 50' },
        ],
        key: 'name',
        value: 'value',
      },
      searchInputName: 'profile_completion',
      title: <div className="text-nowrap">Profile Completion</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: membershipList,
        key: 'name',
        value: 'name',
      },
      searchInputName: 'membership_id_or_type',
      title: (
        <>
          <div>{'Membership ID'}</div>
          <div>{'& Type'}</div>
        </>
      ),
    },
    {
      isSearch: true,
      searchInputName: 'email',
      title: 'Email ID',
    },
    {
      isSearch: true,
      searchInputName: 'phoneno',
      title: 'Phone Number',
    },
    {
      isSearch: isSingleCountryReport ? false : true,
      isResourceCountryFilter: ['0', '1'].includes(userType) ? false : true,
      handleApplyClick: (e) => {
        setSearchPayload((prev) => {
          return {
            ...prev,
            multiple_countries: e,
          };
        });
      },
      dropdownOptions: {
        options: resourceCountryList,
        key: 'id',
        value: 'country',
      },
      searchInputName: 'country',
      title: <div className="text-nowrap">Country</div>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: 'join_date',
      title: 'Join Date',
      isProfileManagement: true,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: 'valid_date',
      title: 'Valid Till',
      isProfileManagement: true,
    },
    {
      isSearch: false,
      searchLable: isSingleCountryReport
        ? 'Edit/Delete'
        : tabType === '0'
        ? 'Edit/Delete'
        : 'Approve/Ignore',
      title: 'Action',
    },
  ];

  if ((tabType !== '0' || userType !== '0') && resource_role !== '2') {
    header = header.filter((col) => col.searchInputName !== 'source');
  }
  if (tabType === '1') {
    header.splice(header?.length - 1, 0, {
      isSearch: false,
      title: 'Comments',
    });
  }

  const rowData = [];
  tableList?.forEach((elem, index) => {
    const {
      id,
      email,
      phone,
      memberId,
      memberType,
      join_date,
      valid_till,
      firstName,
      lastName,
      country,
      reason_of_delete,
      profile_completion,
      source,
    } = elem;

    let isExpired = valid_till && moment().diff(valid_till, 'days') > 0;
    const isTempUser = elem?.memberId.includes('TEMP');
    //only for conference coordinator
    // const isHideEditDelete = resource_role !== "2";

    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span
            className={`${
              isTempUser
                ? 'color-5110 d-flex gap-2 justify-content-center'
                : 'color-new-car pointer'
            } text-nowrap text-16-500-20`}
            onClick={() => {
              if (!isTempUser) {
                setDeatils(true);
                setUserDetail(id);
              }
            }}
          >
            {`${firstName} ${lastName}`}

            {isTempUser && (
              <span className="dashboard-pricing-label-badge">Temp</span>
            )}
          </span>
        ),
      },
      {
        value: <span>{source ? source : '-'}</span>,
      },
      {
        value: (
          <span>
            {profile_completion
              ? profile_completion === '0'
                ? '0'
                : `${profile_completion}%`
              : '-'}
          </span>
        ),
      },
      {
        value: (
          <span>
            {memberId && <div>{memberId}</div>}
            <div>{memberType}</div>
          </span>
        ),
      },
      {
        value: (
          <div
            style={{
              width: '250px',
              wordBreak: 'break-word',
            }}
          >
            {email}
          </div>
        ),
      },
      {
        value: phone,
      },
      {
        value: country || '',
      },
      {
        value: moment(join_date).format('DD.MM.YYYY'),
      },
      {
        value: isExpired
          ? 'Expired'
          : valid_till
          ? moment(valid_till).format('DD.MM.YYYY')
          : '-',
      },
      {
        value: (
          <span className="action-icon-buttons">
            {isTempUser ? (
              viewAccess === '1' && (
                <span
                  className="pointer"
                  onClick={() => {
                    setDeatils(true);
                    setUserDetail(id);
                  }}
                >
                  <i className="bi bi-eye color-8a8a text-20" />
                </span>
              )
            ) : isSingleCountryReport || tabType === '0' ? (
              <>
                {updateAccess === '1' && (
                  <Button
                    btnStyle="light-outline"
                    icon={<img src={icons.edit} alt="edit" />}
                    className="me-2"
                    onClick={() => {
                      setEditData(elem);
                      // setAddMember(true);
                      dispatch(setIsEditUserProfile(true));
                      dispatch(setIsEditDataOfProfile(elem));
                    }}
                    isSquare
                  />
                )}
                {deleteAccess === '1' && (
                  <Button
                    btnStyle="light-outline"
                    icon={<img src={icons.deleteIcon} alt="delete" />}
                    onClick={() => {
                      if (userType === '6') {
                        setIsDeleteRequestId(id);
                      } else {
                        setUserid(id);
                      }
                    }}
                    isSquare
                  />
                )}
              </>
            ) : (
              updateAccess === '1' && (
                <>
                  <Button
                    text={'Approve'}
                    btnStyle="primary-dark"
                    className="mw-70 me-2 text-14-400"
                    onClick={() => {
                      handelDeleteRequest({ status: '1', userid: id });
                    }}
                    isSquare
                    btnLoading={
                      deleteLoadingType === 'approve' && deleteLoading === id
                    }
                  />
                  <Button
                    text={'Ignore'}
                    className="mw-70 me-2 text-14-400"
                    btnStyle="danger-outline"
                    onClick={() => {
                      handelDeleteRequest({ status: '0', userid: id });
                    }}
                    isSquare
                    btnLoading={
                      deleteLoadingType === 'ignore' && deleteLoading === id
                    }
                  />
                </>
              )
            )}
          </span>
        ),
      },
    ];
    if ((tabType !== '0' || userType !== '0') && resource_role !== '2') {
      obj.splice(2, 1);
    }
    if (tabType === '1') {
      obj.splice(obj?.length - 1, 0, {
        value: <span className="text-nowrap">{reason_of_delete}</span> || '-',
      });
    }
    rowData.push({ data: obj });
  });

  return (
    <>
      {isDeleteRequestId && (
        <ProfileDeleteRequestPopup
          id={isDeleteRequestId}
          onHide={() => {
            setIsDeleteRequestId(null);
          }}
          handelSuccess={() => {
            setIsDeleteRequestId(null);
            getProfiles({ ...filterData, ...searchPayload });
          }}
        />
      )}
      {userid && (
        <DeletePopup
          id={userid}
          onHide={() => {
            setUserid(null);
          }}
          handelSuccess={() => {
            setUserid(null);
            getProfiles({ ...filterData, ...searchPayload });
          }}
          handelDelete={async () => {
            let forData = objectToFormData({ userid: userid, status: '1' });
            const response = await dispatch(deleteMembers(forData));
            return response;
          }}
        />
      )}
      {isAddMember && (
        <AddMember
          editData={editData}
          onHide={() => {
            setAddMember(false);
            setEditData(null);
          }}
          handelSuccess={() => {
            setAddMember(false);
            getProfiles({
              ...filterData,
              ...searchPayload,
            });
          }}
        />
      )}
      {isDeatils && (
        <ProfileDetail
          userID={userDetail}
          onHide={() => {
            setDeatils(false);
          }}
        />
      )}
      <Card className="cps-20 cpe-20 cpb-20">
        {isSingleCountryReport ? (
          <div className="table-title cmb-20 cpt-28">
            Profiles ({filterData?.total})
          </div>
        ) : (
          <div
            className={`${
              tabType === '1' ? 'cmb-20' : ''
            } d-flex justify-content-between align-items-center flex-wrap cpt-28`}
          >
            <div className="table-title">
              {`${tabType === '0' ? 'All Profiles' : 'All Pending Requests'}`} (
              {filterData?.total})
            </div>

            {tabType === '0' && (
              <div className="d-flex flex-wrap gap-3 cmt-28 cpb-28">
                {(['0', '1'].includes(userType) || resource_role === '2') && (
                  <>
                    <div className="text-14-400 grey-border d-flex align-items-center gap-2 ps-2 pe-2">
                      <CheckBox
                        type="PRIMARY-ACTIVE"
                        onClick={() => {
                          setIsLoading(true);
                          let newData = { ...filterData, ...searchPayload };
                          newData = {
                            ...newData,
                            temporary_users: filterData.temporary_users
                              ? ''
                              : '1',
                          };
                          setFilterData(newData);
                          getProfiles(newData);
                        }}
                        isChecked={filterData.temporary_users === '1'}
                      />
                      <div>Temporary ID</div>
                    </div>
                    <div className="text-14-400 grey-border d-flex align-items-center gap-2 ps-2 pe-2">
                      <CheckBox
                        type="PRIMARY-ACTIVE"
                        onClick={() => {
                          setIsLoading(true);
                          let newData = { ...filterData, ...searchPayload };
                          newData = {
                            ...newData,
                            is_non_allocated_resource:
                              filterData.is_non_allocated_resource ? '' : '1',
                          };
                          setFilterData(newData);
                          getProfiles(newData);
                        }}
                        isChecked={filterData.is_non_allocated_resource === '1'}
                      />
                      <div>Not Allocated Resource</div>
                    </div>
                  </>
                )}
                {viewAccess === '1' && (
                  <ExportButton
                    exportAPI={exportProfile}
                    payload={objectToFormData({
                      ...filterData,
                      ...searchPayload,
                      ...newDates,
                    })}
                    isFilter={
                      !(
                        (searchPayload?.start_date &&
                          searchPayload?.end_date) ||
                        searchPayload?.join_date ||
                        searchPayload?.valid_date
                      )
                    }
                    setFilterDates={setFilterDates}
                    filterDates={filterDates}
                  />
                )}
                <div>
                  <FilterDropdown
                    list={membershipList}
                    handelChangeFilter={handelChangeFilter}
                    isApplyButton
                  />
                </div>
                {/* resource_role !== "2" && */}
                {createAccess === '1' && (
                  <Button
                    isSquare
                    text="+ Add Member"
                    btnStyle="primary-outline"
                    className="h-35 text-14-500 text-nowrap"
                    onClick={() => {
                      setAddMember(!isAddMember);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div className="w-100 overflow-auto">
          <Table
            isLoading={isLoading}
            header={header}
            rowData={rowData}
            filterData={filterData}
            searchPayload={searchPayload}
            searchInputChange={handelChangeSearch}
            changeOffset={handelChangePagination}
            isOtherPagination
          />
        </div>
      </Card>
    </>
  );
};

export default AllProfiles;
