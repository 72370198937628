import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDataFromLocalStorage } from "utils/helpers";
import { setIsCollapseSidebar, setIsLogout } from "store/slices";
import {
  adminRoute,
  corporateRoute,
  institutionalRoute,
  profetionalRoute,
  resourceRoute,
  studentRoute,
  icons,
  reportSubMenu,
  accountsRoute,
  superAdminRoute,
} from "utils/constants";
import "./Sidebar.scss";
import { useEffect } from "react";

const Sidebar = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));

  // console.log("✌️userDetails --->", userDetails);
  // const { view: viewAccess } =
  //   userDetails?.role_data?.find(
  //     (item) => item?.permission_name === "Event Management"
  //   )?.sub_permission || {};

  const adminSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: adminRoute.dashboard,
    },

    {
      id: 2,
      title: "Profile Management",
      icon: icons.NewProfile,
      url: adminRoute.profileManagement,
    },

    {
      id: 3,
      title: "Event Management",
      icon: icons.NewConferences,
      url: adminRoute.eventManagement,
    },
    {
      id: 4,
      title: "Segment",
      icon: icons.NewConferences,
      url: adminRoute.segmentManagement,
    },
    {
      id: 5,
      title: "Journal Management",
      icon: icons.NewPublications,
      url: adminRoute.journalManagement,
    },
    {
      id: 6,
      title: "Network Management",
      icon: icons.NewNetworks,
      url: adminRoute.networkManagement,
    },
    {
      id: 7,
      title: "Chapters & Groups Management",
      icon: icons.NewGroups,
      url: adminRoute.chaptersAndGroups,
    },
    {
      id: 8,
      title: "Certificate Management",
      icon: icons.NewCertificate,
      url: adminRoute.certificateManagement,
    },
    {
      id: 9,
      title: "Award Management",
      icon: icons.NewNomiAward,
      url: adminRoute.awardManagement,
    },
    {
      id: 10,
      title: "Resource Management",
      icon: icons.NewGroups,
      url: adminRoute.resourceManagement,
    },
    {
      id: 11,
      title: "Account Management",
      icon: icons.NewGroups,
      url: adminRoute.accountManagement,
    },
    {
      id: 12,
      title: "Payment Verification",
      icon: icons.NewGroups,
      url: adminRoute.paymentVerification,
    },

    {
      id: 13,
      title: "Career Management",
      icon: icons.NewCareers,
      url: adminRoute.careerManagement,
    },
    {
      id: 14,
      title: "Mentorship Management",
      icon: icons.NewMentorship,
      url: adminRoute.mentorshipManagement,
    },
    {
      id: 15,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: adminRoute.inboxNotifications,
    },
    {
      id: 16,
      title: "Reports",
      icon: icons.NewInbox,
      url: adminRoute.reports,
    },
    {
      id: 17,
      title: "Branding Management",
      icon: icons.NewCertificate,
      url: adminRoute.brandingManagement,
    },
    {
      id: 18,
      title: "University Management",
      icon: icons.NewConferences,
      url: adminRoute.universityManagement,
    },
    {
      id: 19,
      title: "Institution Management",
      icon: icons.NewConferences,
      url: adminRoute.institutionManagement,
    },
    {
      id: 20,
      title: "Region Management",
      icon: icons.NewGroups,
      url: adminRoute.regionManagement,
    },
    {
      id: 21,
      title: "Department Management",
      icon: icons.NewGroups,
      url: adminRoute.departmentManagement,
    },
    {
      id: 22,
      title: "Settings",
      icon: icons.NewCareers,
      url: adminRoute.setting,
    },
    {
      id: 23,
      title: "Newsletter",
      icon: icons.NewGroups,
      url: adminRoute.newsLetter,
    },
    // {
    //   id: 24,
    //   title: "User Activity Management",
    //   icon: icons.NewProfile,
    //   url: adminRoute.userActivityManagement,
    // },
  ];


  const filteredAdminSidebarOptions = adminSidebarOptions.filter((option) => {
    if (option.title === "Dashboard" || option.title === "Account Management") {
      return true;
    }
    const permission = userDetails?.role_data?.find(
      (item) => item.permission_name === option.title
    );
    return permission?.sub_permission?.view === "1";
  });
  


  const studentSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: studentRoute.dashboard,
    },
    {
      id: 2,
      title: "My Profile",
      icon: icons.NewProfile,
      url: studentRoute.myProfile,
      tourId: "profile-details-id",
    },
    {
      id: 3,
      title: "Conferences & Events",
      icon: icons.NewConferences,
      url: studentRoute.conferencesAndEvents,
      tourId: "all-events-id",
    },
    {
      id: 4,
      title: "Publications",
      icon: icons.NewPublications,
      url: studentRoute.publications,
      tourId: "publications-id",
    },
    {
      id: 5,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: studentRoute.inboxNotifications,
    },
    {
      id: 6,
      title: "My Post and Networks",
      icon: icons.NewNetworks,
      url: studentRoute.networkManagement,
      tourId: "my-network-community-id",
    },
    {
      id: 7,
      title: "Chapters & Groups",
      icon: icons.NewGroups,
      url: studentRoute.chaptersAndGroups,
      tourId: "chapters-groups-id",
    },
    {
      id: 8,
      title: "Career Support",
      icon: icons.NewCareers,
      url: studentRoute.careerSupport,
      tourId: "career-support-id",
    },
    {
      id: 9,
      title: "Mentorship",
      icon: icons.NewMentorship,
      url: studentRoute.mentorship,
      tourId: "mentorship-id",
      isNewFeature: true,
    },
    {
      id: 10,
      title: "Nominate For Award",
      icon: icons.NewNomiAward,
      url: studentRoute.nominateForAward,
      tourId: "nomiante-for-awards-id",
    },
    {
      id: 11,
      title: "IFERP Digital Library",
      icon: icons.NewDigitalLibrary,
      url: studentRoute.digitalLibrary,
    },
    {
      id: 12,
      title: "Certificates & Rewards",
      icon: icons.NewCertificate,
      url: studentRoute.certificatesAndRewards,
    },
    {
      id: 13,
      title: "Award Winners",
      icon: icons.NewNomiAward,
      url: studentRoute.awardWinners,
    },
    {
      id: 14,
      title: "Help & support",
      icon: icons.NewHelp,
      url: studentRoute.helpSupport,
    },
  ];




  const profetionalSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: profetionalRoute.dashboard,
    },

    {
      id: 15,
      title: "My Task",
      icon: icons.NewPublications,
      url: profetionalRoute.myTask,
      tourId: "my-task-id",
    },
    {
      id: 2,
      title: "My Profile",
      icon: icons.NewProfile,
      url: profetionalRoute.myProfile,
      tourId: "profile-details-id",
    },
    {
      id: 3,
      title: "Conferences & Events",
      icon: icons.NewConferences,
      url: profetionalRoute.conferencesAndEvents,
      tourId: "all-events-id",
    },
    {
      id: 4,
      title: "Publications",
      icon: icons.NewPublications,
      url: profetionalRoute.publications,
      tourId: "publications-id",
    },
    {
      id: 5,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: profetionalRoute.inboxNotifications,
    },
    {
      id: 6,
      title: "My Post and Networks",
      icon: icons.NewNetworks,
      url: profetionalRoute.networkManagement,
      tourId: "my-network-community-id",
    },
    {
      id: 7,
      title: "Chapters & Groups",
      icon: icons.NewGroups,
      url: profetionalRoute.chaptersAndGroups,
      tourId: "chapters-groups-id",
    },
    {
      id: 8,
      title: "Career Support",
      icon: icons.NewCareers,
      url: profetionalRoute.careerSupport,
      tourId: "career-support-id",
    },
    {
      id: 9,
      title: "Mentorship",
      icon: icons.NewMentorship,
      url: profetionalRoute.mentorship,
      tourId: "mentorship-id",
      isNewFeature: true,
    },
    {
      id: 10,
      title: "Nominate For Award",
      icon: icons.NewNomiAward,
      url: profetionalRoute.nominateForAward,
      tourId: "nomiante-for-awards-id",
    },
    {
      id: 11,
      title: "IFERP Digital Library",
      icon: icons.NewDigitalLibrary,
      url: profetionalRoute.digitalLibrary,
    },
    {
      id: 12,
      title: "Certificates & Rewards",
      icon: icons.NewCertificate,
      url: profetionalRoute.certificatesAndRewards,
    },
    {
      id: 13,
      title: "Award Winners",
      icon: icons.NewNomiAward,
      url: profetionalRoute.awardWinners,
    },
    {
      id: 14,
      title: "Help & support",
      icon: icons.NewHelp,
      url: profetionalRoute.helpSupport,
    },
  ];




  const instituteSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: institutionalRoute.dashboard,
    },
    {
      id: 2,
      title: "Our Profile",
      icon: icons.NewProfile,
      url: institutionalRoute.myProfile,
    },
    {
      id: 3,
      title: "Activity Plan",
      icon: icons.activePlan,
      url: institutionalRoute.activityPlan,
    },
    {
      id: 4,
      title: "Our Academicians",
      icon: icons.ourAcademies,
      url: institutionalRoute.ourAcademies,
    },
    {
      id: 5,
      title: "Conferences & Events",
      icon: icons.NewConferences,
      url: institutionalRoute.conferencesAndEvents,
    },
    {
      id: 6,
      title: "Publications",
      icon: icons.NewPublications,
      url: institutionalRoute.publications,
    },
    {
      id: 7,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: institutionalRoute.inboxNotifications,
    },
    {
      id: 8,
      title: "My Post and Networks",
      icon: icons.NewNetworks,
      url: institutionalRoute.networkManagement,
    },
    {
      id: 9,
      title: "Innovation Ambassador",
      icon: icons.innovation,
      url: institutionalRoute.innovationAmbassador,
    },
    {
      id: 10,
      title: "Chapters & Groups",
      icon: icons.NewGroups,
      url: institutionalRoute.chaptersAndGroups,
    },
    {
      id: 11,
      title: "Career Support",
      icon: icons.NewCareers,
      url: institutionalRoute.careerSupport,
    },
    {
      id: 12,
      title: "Nominate For Award",
      icon: icons.nominateAward,
      url: institutionalRoute.nominateForAward,
    },
    {
      id: 13,
      title: "Certificates & Rewards",
      icon: icons.NewCertificate,
      url: institutionalRoute.certificatesAndRewardsList,
    },
    {
      id: 14,
      title: "Collaboration",
      icon: icons.collaboration,
      url: institutionalRoute.collaboration,
    },
    {
      id: 15,
      title: "Funds And Grants",
      icon: icons.fund,
      url: institutionalRoute.fundsAndGrants,
    },
    {
      id: 16,
      title: "Help",
      icon: icons.NewHelp,
      url: institutionalRoute.helpSupport,
    },
  ];

  const corporateSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: corporateRoute.dashboard,
    },
    {
      id: 2,
      title: "Our Profile",
      icon: icons.corporateProfile,
      url: corporateRoute.myProfile,
    },
    {
      id: 3,
      title: "Conferences & Events",
      icon: icons.NewConferences,
      url: corporateRoute.events,
      tourId: "all-events-id",
    },
    {
      id: 4,
      title: "Publications",
      icon: icons.NewPublications,
      url: corporateRoute.publications,
    },
    {
      id: 5,
      title: "Branding & Publicity",
      icon: icons.branding,
      url: corporateRoute.brandingAndPublicity,
    },
    {
      id: 6,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: corporateRoute.inboxNotifications,
    },
    {
      id: 7,
      title: "My Post and Networks",
      icon: icons.NewNetworks,
      url: corporateRoute.networkManagement,
    },
    {
      id: 8,
      title: "Chapters & Groups",
      icon: icons.NewGroups,
      url: corporateRoute.chaptersAndGroups,
    },
    {
      id: 9,
      title: "Talent Development",
      icon: icons.NewCareers,
      url: corporateRoute.careerSupport,
    },
    {
      id: 10,
      title: "Certificates & Rewards",
      icon: icons.NewCertificate,
      url: corporateRoute.certificatesAndRewardsList,
    },
    {
      id: 11,
      title: "Award Winners",
      icon: icons.NewNomiAward,
      url: corporateRoute.awardWinners,
    },
    {
      id: 12,
      title: "Nominate For Award",
      icon: icons.nominateAward,
      url: corporateRoute.nominateForAward,
    },
    {
      id: 13,
      title: "IFERP Digital Library",
      icon: icons.NewDigitalLibrary,
      url: corporateRoute.digitalLibrary,
    },
    {
      id: 14,
      title: "Help",
      icon: icons.institutionalHelp,
      url: corporateRoute.helpSupport,
    },
  ];
  

  const resourceSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: resourceRoute.dashboard,
    },
    {
      id: 2,
      title: "Profile Management",
      icon: icons.NewProfile,
      url: resourceRoute.profileManagement,
    },
    {
      id: 3,
      title: "Event Management",
      icon: icons.NewConferences,
      url: resourceRoute.eventManagement,
    },
    {
      id: 14,
      title: "Segment",
      icon: icons.NewConferences,
      url: resourceRoute.segmentManagement,
    },
    {
      id: 4,
      title: "Journal Management",
      icon: icons.NewPublications,
      url: resourceRoute.journalManagement,
    },
    {
      id: 5,
      title: "Chapters & Groups Management",
      icon: icons.NewGroups,
      url: resourceRoute.chaptersAndGroups,
    },
    {
      id: 6,
      title: "My Post and Networks",
      icon: icons.NewNetworks,
      url: resourceRoute.networkManagement,
    },
    {
      id: 7,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: resourceRoute.inboxNotifications,
    },
    {
      id: 8,
      title: "Reports",
      icon: icons.NewInbox,
      url: resourceRoute.reports,
    },
    {
      id: 9,
      title: "Certificate Management",
      icon: icons.NewCertificate,
      url: resourceRoute.certificateManagement,
    },
    {
      id: 10,
      title: "Award Management",
      icon: icons.NewNomiAward,
      url: resourceRoute.awardManagement,
    },
    {
      id: 11,
      title: "Career Management",
      icon: icons.NewCareers,
      url: resourceRoute.careerManagement,
    },
    {
      id: 12,
      title: "Mentorship Management",
      icon: icons.NewMentorship,
      url: resourceRoute.mentorshipManagement,
    },
    {
      id: 13,
      title: "Branding Management",
      icon: icons.NewCertificate,
      url: resourceRoute.brandingManagement,
    },
  ];

  const filtereCoOrdinatorResourceSidebarOptions = resourceSidebarOptions.filter((option) => {
    if (option.title === "Dashboard" || option.title === "Account Management") {
      return true;
    }
    const permission = userDetails?.role_data?.find(
      (item) => item.permission_name === option.title
    );
    return permission?.sub_permission?.view === "1";
  });

  

  const accountsSidebarOptions = [
    // {
    //   id: 1,
    //   title: "Accounts Management",
    //   icon: icons.NewPublications,
    //   url: accountsRoute.accountManagement,
    // },
    {
      id: 2,
      title: "Payment Verification",
      icon: icons.NewPublications,
      url: accountsRoute.paymentVerification,
    },
  ];

  const filteredAccountsSidebarOptions = accountsSidebarOptions.filter((option) => {
    if (option.title === "Dashboard") {
      return true;
    }
    const permission = userDetails?.role_data?.find(
      (item) => item.permission_name === option.title
    );
    return permission?.sub_permission?.view === "1";
  });
  
  
  const superAdminSidebarOptions = [
    {
      id: 1,
      title: "Dashboard",
      icon: icons.NewDashboard,
      url: superAdminRoute.dashboard,
    },

    {
      id: 2,
      title: "Profile Management",
      icon: icons.NewProfile,
      url: superAdminRoute.profileManagement,
    },
    {
      id: 3,
      title: "Event Management",
      icon: icons.NewConferences,
      url: superAdminRoute.eventManagement,
    },
    // {
    //   id: 4,
    //   title: "Segment",
    //   icon: icons.NewConferences,
    //   url: superAdminRoute.segmentManagement,
    // },
    {
      id: 5,
      title: "Journal Management",
      icon: icons.NewPublications,
      url: superAdminRoute.journalManagement,
    },
    {
      id: 6,
      title: "Network Management",
      icon: icons.NewNetworks,
      url: superAdminRoute.networkManagement,
    },
    {
      id: 7,
      title: "Chapters & Groups Management",
      icon: icons.NewGroups,
      url: superAdminRoute.chaptersAndGroups,
    },
    {
      id: 8,
      title: "Certificate Management",
      icon: icons.NewCertificate,
      url: superAdminRoute.certificateManagement,
    },
    {
      id: 9,
      title: "Award Management",
      icon: icons.NewNomiAward,
      url: superAdminRoute.awardManagement,
    },
    {
      id: 10,
      title: "Resource Management",
      icon: icons.NewGroups,
      url: superAdminRoute.resourceManagement,
    },
    {
      id: 11,
      title: "Account Management",
      icon: icons.NewGroups,
      url: superAdminRoute.accountManagement,
    },
    {
      id: 12,
      title: "Payment Verification",
      icon: icons.NewGroups,
      url: superAdminRoute.paymentVerification,
    },

    {
      id: 13,
      title: "Career Management",
      icon: icons.NewCareers,
      url: superAdminRoute.careerManagement,
    },
    {
      id: 14,
      title: "Mentorship Management",
      icon: icons.NewMentorship,
      url: superAdminRoute.mentorshipManagement,
    },
    {
      id: 15,
      title: "Inbox/Notifications",
      icon: icons.NewInbox,
      url: superAdminRoute.inboxNotifications,
    },
    {
      id: 16,
      title: "Reports",
      icon: icons.NewInbox,
      url: superAdminRoute.reports,
    },
    {
      id: 17,
      title: "Branding Management",
      icon: icons.NewCertificate,
      url: superAdminRoute.brandingManagement,
    },
    {
      id: 18,
      title: "University Management",
      icon: icons.NewConferences,
      url: superAdminRoute.universityManagement,
    },
    {
      id: 19,
      title: "Institution Management",
      icon: icons.NewConferences,
      url: superAdminRoute.institutionManagement,
    },
    {
      id: 20,
      title: "Region Management",
      icon: icons.NewGroups,
      url: superAdminRoute.regionManagement,
    },
    {
      id: 21,
      title: "Department Management",
      icon: icons.NewGroups,
      url: superAdminRoute.departmentManagement,
    },
    {
      id: 22,
      title: "Settings",
      icon: icons.NewCareers,
      url: superAdminRoute.setting,
    },
    {
      id: 23,
      title: "Newsletter",
      icon: icons.NewGroups,
      url: superAdminRoute.newsLetter,
    },
    // {
    //   id: 24,
    //   title: "User Activity Management",
    //   icon: icons.NewProfile,
    //   url: adminRoute.userActivityManagement,
    // },
  ];

  const userData = getDataFromLocalStorage();
  // const [IsShow , setIsShow] = useState(false);
  const { user_type, membership_details = {}, resource_role } = userData;
  const { id: membershipID } = membership_details;
  let navigate = useNavigate();
  const { tourIndex, isTour, isCollapseSidebar } = useSelector((state) => ({
    tourIndex: state.global.tourIndex,
    isTour: state.global.isTour,
    isCollapseSidebar: state.global.isCollapseSidebar,
  }));
  let { mentor_status } = getDataFromLocalStorage();
  const handleRedirect = (url) => {
    navigate(url);
  };
  const getOptions = (type) => {
    switch (type) {
      case "0":
        return filteredAdminSidebarOptions;
      case "1":
        return superAdminSidebarOptions;
      case "2":
        let newProfetionalSidebarOptions = [...profetionalSidebarOptions];
        if (!userData?.is_reviewer) {
          newProfetionalSidebarOptions = profetionalSidebarOptions?.filter(
            (option) => option?.id !== 15
          );
        }
        return newProfetionalSidebarOptions;
      case "3":
        return instituteSidebarOptions;
      case "4":
        return corporateSidebarOptions;
      case "5":
        return studentSidebarOptions;
      case "6":
        let newRoutes = filtereCoOrdinatorResourceSidebarOptions;
        // if (resource_role && resource_role === "2") {
        //   newRoutes = resourceSidebarOptions.filter((_, i) => i !== 1);
        // }

        if (resource_role === "1") {
          newRoutes = filtereCoOrdinatorResourceSidebarOptions.filter(
            (option) =>
              option?.id !== 9 && option?.id !== 10 && option?.id !== 14
          );
        }
        return newRoutes;

      case "7":
        return filteredAccountsSidebarOptions;
      default:
        return [];
    }
  };
  useEffect(() => {
    let elem = document.getElementById("side-option-list-id");
    if (tourIndex < 12 && isTour) {
      if (elem) {
        elem.style.overflowY = "hidden";
      }
    } else {
      if (elem) {
        elem.style.overflowY = "auto";
      }
    }
  }, [tourIndex, isTour]);

  const access = {
    isHelpAndSupport: membershipID === 3 || membershipID === 12,
    isSubMentorship: membershipID === 3 || membershipID === 2,
  };
  const loadOption = getOptions(user_type);

  return (
    <div
      id="Sidebar-container"
      className={`iferp-scroll ${isCollapseSidebar ? "collapse-side" : ""}`}
    >
      {/* <div className={`mobile-logo-container ${IsShow ? 'showslider' : 'hideSlider'}`} onClick={() => setIsShow(!IsShow)}> */}
      <div
        className={`mobile-logo-container`}
        onClick={() => {
          if (window.innerWidth > 1088) {
            dispatch(setIsCollapseSidebar(false));
          }
        }}
      >
        {/* <img className="img-logo1" src={icons.roundLogo} alt="img" /> */}
        <i className="bi bi-list img-logo1 text-24-500" />
      </div>

      <nav
        className={`sidemenu-container ${
          isCollapseSidebar ? "collapse-sidemenu-container" : ""
        }`}
        id="nav-sidemenu"
      >
        <div className="desktop-logo-container">
          <div>
            <img className="img-logo1" src={icons.logo} alt="img" />
          </div>
          <div
            className="expand-icon"
            onClick={() => {
              dispatch(setIsCollapseSidebar(true));
            }}
          >
            <img src={icons.NewMenu} alt="collapse" />
          </div>
        </div>
        <ul className="iferp-scroll px-2" id="side-option-list-id">
          {loadOption?.map((elem) => {
            let isActive = window.location?.pathname?.includes(elem.url);
            const isHelp =
              window.location.pathname.includes("help") &&
              access.isHelpAndSupport;

            const isMentorship =
              window.location.pathname.includes("mentorship") &&
              access.isSubMentorship;

            const isReports = window.location.pathname.includes("/reports");

            if (isHelp && elem.url.includes("help")) {
              isActive = true;
            }
            if (isMentorship && elem.url.includes("mentorship")) {
              isActive = true;
            }
            if (isReports && elem.url.includes("reports")) {
              isActive = true;
            }

            return (
              <li
                key={elem.id}
                id={elem.tourId || ""}
                className={`sidemenu-block ${
                  isActive
                    ? `${
                        isMentorship &&
                        mentor_status !== "Reject" &&
                        mentor_status !== ""
                          ? "cmb-130"
                          : ""
                      }
                    ${isMentorship && "cmb-60"}
                    ${isReports && "cmb-250"}
                      } active-option pointer `
                    : "color-black-olive pointer"
                }`}
                onClick={() => {
                  if (elem.url) {
                    handleRedirect(elem.url);
                  }
                }}
              >
                <div className="sidemenu-block">
                  <img src={elem.icon} alt={elem.title} />
                  <em>{elem.title}</em>
                  {!isCollapseSidebar && elem?.isNewFeature && !isActive && (
                    <span className="new-feature-logo-block text-12-500">
                      New
                    </span>
                  )}

                  {isHelp && isActive && (
                    <i className="bi bi-chevron-down help-colaps-icon" />
                  )}
                  {isMentorship && isActive && (
                    <i className="bi bi-chevron-down help-colaps-icon" />
                  )}
                  {isReports && isActive && (
                    <i className="bi bi-chevron-down help-colaps-icon" />
                  )}
                </div>
                {isHelp && isActive && (
                  <div className="help-and-support">
                    <div className="help-option-block mt-3">
                      <div className="hr-line" />
                      <div className="vr-line" />
                      <div
                        className={`help-option w-100 text-14-500-18  pointer ${
                          window.location.pathname.includes("help-and-support")
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={() => {
                          navigate(
                            `/${params?.memberType}/help/help-and-support`
                          );
                        }}
                      >
                        Help & Support
                      </div>
                    </div>
                    <div className="help-option-block mt-3">
                      <div className="hr-line" />
                      <div className="vr-line" />
                      <div
                        className={`help-option w-100 text-14-500-18  pointer ${
                          window.location.pathname.includes(
                            "my-personal-executive"
                          )
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(
                            `/${params?.memberType}/help/my-personal-executive`
                          );
                        }}
                      >
                        My Personal Executive
                      </div>
                    </div>
                  </div>
                )}
                {isHelp && isActive && (
                  <div className="mobile-help-and-support">
                    <div className="help-option-block mt-3">
                      <div
                        className={`text-22-400 ${
                          window.location.pathname.includes("help-and-support")
                            ? "color-new-car"
                            : "color-black-olive"
                        }`}
                      >
                        <i className="bi bi-headset" />
                      </div>
                      <div
                        className={`help-option w-100 text-14-500-18  pointer ${
                          window.location.pathname.includes("help-and-support")
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(
                            `/${params?.memberType}/help/help-and-support`
                          );
                        }}
                      >
                        Help & Support
                      </div>
                    </div>
                    <div className="help-option-block mt-3">
                      <div
                        className={` text-22-400 ${
                          window.location.pathname.includes(
                            "my-personal-executive"
                          )
                            ? "color-new-car"
                            : "color-black-olive"
                        }`}
                      >
                        <i className="bi bi-chat-left-dots" />
                      </div>
                      <div
                        className={`help-option w-100 text-14-500-18  pointer ${
                          window.location.pathname.includes(
                            "my-personal-executive"
                          )
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(
                            `/${params?.memberType}/help/my-personal-executive`
                          );
                        }}
                      >
                        My Personal Executive
                      </div>
                    </div>
                  </div>
                )}

                {isMentorship && isActive && (
                  <div className="sub-mentorship">
                    <div className="mentorship-option-block mt-3">
                      <div className="hr-line" />
                      <div className="vr-line" />
                      <div
                        className={`mentorship-option w-100 text-14-500-18  pointer ${
                          window.location.pathname.includes("mentee")
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={() => {
                          navigate(
                            `/${params?.memberType}/mentorship/mentee/all-mentors`
                          );
                        }}
                      >
                        Mentee Profile
                      </div>
                    </div>
                    {mentor_status !== "Reject" && mentor_status !== "" && (
                      <div className="mentorship-option-block mt-3">
                        <div className="hr-line" />
                        <div className="vr-line" />
                        <div
                          className={`mentorship-option w-100 text-14-500-18  pointer ${
                            window.location.pathname
                              ?.split("/")
                              .includes("mentor")
                              ? "active-sub"
                              : "inactive-sub"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(
                              `/${params?.memberType}/mentorship/mentor`
                            );
                          }}
                        >
                          Mentor Profile
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {isMentorship && isActive && (
                  <div className="mobile-sub-mentorship">
                    <div className="mentorship-option-block mt-3">
                      <div
                        className={`text-22-400 ${
                          window.location.pathname.includes("mentee")
                            ? "color-new-car"
                            : "color-black-olive"
                        }`}
                      >
                        <i className="bi bi-people-fill" />
                      </div>
                      <div
                        className={`mentorship-option w-100 text-14-500-18 pointer ${
                          window.location.pathname.includes("mentee")
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={() => {
                          navigate(
                            `/${params?.memberType}/mentorship/mentee/all-mentors`
                          );
                        }}
                      >
                        Mentee
                      </div>
                    </div>
                    <div className="mentorship-option-block mt-3">
                      <div
                        className={`text-22-400 ${
                          window.location.pathname
                            ?.split("/")
                            .includes("mentor")
                            ? "color-new-car"
                            : "color-black-olive"
                        }`}
                      >
                        <i className="bi bi-person-square" />
                      </div>
                      <div
                        className={`mentorship-option w-100 text-14-500-18 pointer ${
                          window.location.pathname
                            ?.split("/")
                            .includes("mentor")
                            ? "active-sub"
                            : "inactive-sub"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(`/${params?.memberType}/mentorship/mentor`);
                        }}
                      >
                        Mentor
                      </div>
                    </div>
                  </div>
                )}

                {isReports && isActive && (
                  <div className="sub-reports-menu">
                    {reportSubMenu.map((subMenu, index) => {
                      const { label, type } = subMenu;
                      return (
                        <div className="reports-option-block mt-3" key={index}>
                          <div className="hr-line" />
                          <div className="vr-line" />
                          <div
                            className={`reports-option w-100 text-14-500-18 pointer ${
                              window.location.pathname.includes(`${type}`)
                                ? "active-sub"
                                : "inactive-sub"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigate(`${elem?.url}/${type}`);
                            }}
                          >
                            {label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {isReports && isActive && (
                  <div className="mobile-sub-reports-menu">
                    {reportSubMenu.map((subMenu, index) => {
                      const { label, type, icon } = subMenu;
                      return (
                        <div className="reports-option-block mt-3" key={index}>
                          <div
                            className={`text-22-400 ${
                              window.location.pathname.includes(`${type}`)
                                ? "color-new-car"
                                : "color-black-olive"
                            }`}
                          >
                            <i
                              className={`${icon} ${
                                window.location.pathname.includes(`${type}`)
                                  ? "color-new-car"
                                  : ""
                              }`}
                            />
                          </div>
                          <div
                            className={`reports-option w-100 text-14-500-18 pointer ${
                              window.location.pathname.includes(`${type}`)
                                ? "active-sub"
                                : "inactive-sub"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigate(`${elem?.url}/${type}`);
                            }}
                          >
                            {label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </li>
            );
          })}

          <li
            className={`sidemenu-block pointer ${
              window.location.pathname.includes("help") &&
              access.isHelpAndSupport
                ? "cmt-125 logout-options"
                : ""
            }`}
            onClick={() => {
              dispatch(setIsLogout(true));
            }}
          >
            <div className="sidemenu-block text-14-500-18 color-black-olive pointer">
              <img src={icons.NewLogout} alt="logout" />
              <em>Log Out</em>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default Sidebar;
