import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import ToggleSwitch from "components/form/ToggleSwitch";
import Card from "components/Layout/Card";
import Profile from "components/Layout/Profile";
import Table from "components/Layout/Table";
import { isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchRBACUserPermission,
  throwError,
  throwSuccess,
  updateRBACUserPermission,
} from "store/slices";
import {
  getCountryCode,
  getDataFromLocalStorage,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";

const RBACUserListDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const { user_type } = getDataFromLocalStorage();
  const { type, listType, userId, userType } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarMenuList, setSidebarMenuList] = useState([]);
  const [userData, setUserData] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [initialData, setInitialData] = useState({});

  const getRBACUserPermissionDetail = async () => {
    let payload = { user_id: userId, user_type: userType };
    if (listType === "all") {
      delete payload?.user_id;
    }
    const response = await dispatch(
      fetchRBACUserPermission(objectToFormData(payload))
    );
    let resData = {};
    let resList = [];
    if (response?.data?.role_data) {
      resData = response?.data?.user_data || {};
      resList = response?.data?.role_data || [];
      setInitialData(response?.data);
    }
    setUserData(resData);
    setSidebarMenuList(resList);
    setIsLoading(false);
  };

  const handleCheck = (data) => {
    const { parentId, permissionObj, checkBoxType, checkValue } = data;
    const updatedList = sidebarMenuList?.map((elem) => {
      if (elem?.id === parentId) {
        return {
          ...elem,
          sub_permission: {
            ...permissionObj,
            [checkBoxType]: checkValue ? "1" : "0",
          },
        };
      }
      return elem;
    });
    setSidebarMenuList(updatedList);
    setIsHide(
      isEqual(
        JSON.stringify(initialData?.role_data),
        JSON.stringify(updatedList)
      )
    );
  };

  const handleToggle = () => {
    let updatedData = userData;
    updatedData = {
      ...updatedData,
      data_masked: updatedData?.data_masked === "0" ? "1" : "0",
    };
    setUserData(updatedData);
    setIsHide(
      isEqual(
        JSON.stringify(initialData?.user_data?.data_masked),
        JSON.stringify(updatedData?.data_masked)
      )
    );
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    let payload = {
      permission_data: JSON.stringify(sidebarMenuList),
      is_masked: userData?.data_masked,
      user_id: userId,
    };
    if (listType === "all") {
      delete payload?.user_id;
    }
    const response = await dispatch(
      updateRBACUserPermission(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      getRBACUserPermissionDetail();
      setIsHide(true);
    } else {
      dispatch(throwError({ message: response?.message }));
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    getRBACUserPermissionDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "name",
      title: <div className="text-nowrap">Events Name</div>,
    },
    {
      isSearch: false,
      searchInputName: "name",
      title: <div className="text-nowrap">View</div>,
    },
    {
      isSearch: false,
      searchInputName: "name",
      title: <div className="text-nowrap">Create</div>,
    },
    {
      isSearch: false,
      searchInputName: "name",
      title: <div className="text-nowrap">Update</div>,
    },
    {
      isSearch: false,
      searchInputName: "name",
      title: <div className="text-nowrap">Delete</div>,
    },
  ];
  const rowData = [];
  sidebarMenuList?.forEach((elem, index) => {
    const { id, permission_name, sub_permission } = elem;
    const {
      view: viewVal,
      create: createVal,
      update: updateVal,
      delete: deleteVal,
    } = sub_permission || {};
    let obj = [
      {
        value: <span>{index + 1}</span>,
      },
      {
        value: (
          <span
            className={`text-nowrap text-14-400-18`}
          >{`${permission_name}`}</span>
        ),
      },
      {
        value: (
          <div className="d-flex justify-content-center">
            <CheckBox
              type="ACTIVE"
              onClick={(e) => {
                const payload = {
                  parentId: id,
                  permissionObj: sub_permission,
                  checkBoxType: "view",
                  checkValue: viewVal !== "1",
                };
                handleCheck(payload);
              }}
              isChecked={viewVal === "1"}
            />
          </div>
        ),
      },
      {
        value: (
          <div className="d-flex justify-content-center">
            <CheckBox
              type="ACTIVE"
              onClick={(e) => {
                const payload = {
                  parentId: id,
                  permissionObj: sub_permission,
                  checkBoxType: "create",
                  checkValue: createVal !== "1",
                };
                handleCheck(payload);
              }}
              isChecked={createVal === "1"}
            />
          </div>
        ),
      },
      {
        value: (
          <div className="d-flex justify-content-center">
            <CheckBox
              type="ACTIVE"
              onClick={(e) => {
                const payload = {
                  parentId: id,
                  permissionObj: sub_permission,
                  checkBoxType: "update",
                  checkValue: updateVal !== "1",
                };
                handleCheck(payload);
              }}
              isChecked={updateVal === "1"}
            />
          </div>
        ),
      },
      {
        value: (
          <div className="d-flex justify-content-center">
            <CheckBox
              type="ACTIVE"
              onClick={(e) => {
                const payload = {
                  parentId: id,
                  permissionObj: sub_permission,
                  checkBoxType: "delete",
                  checkValue: deleteVal !== "1",
                };
                handleCheck(payload);
              }}
              isChecked={deleteVal === "1"}
            />
          </div>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  const {
    profile_image,
    name,
    email_id,
    phone_number,
    date_of_joining,
    member_id,
    data_masked,
    country_code,
  } = userData || {};

  const userLabel = `${titleCaseString(listType)} ${titleCaseString(
    type
  )?.replace("-", " ")}`;

  

  const {
    // view: viewAccess,
    // create: createAccess,
    update: updateAccess,
    // delete: deleteAccess,
  } =
    userDetails?.role_data?.find((item) => item?.permission_name === "Settings")
      ?.sub_permission || {};

  
  return (
    <Card className="rbac-user-type-container cps-24 cpt-16 cpe-24 cpb-16 br-10">
      <div className="d-flex align-items-center gap-3 cmb-42">
        <i
          className="bi bi-chevron-left pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="text-16-500-20 color-title-navy">{userLabel}</span>
      </div>

      {!isLoading &&
        (listType === "all" ? (
          <div
            className={`${
              user_type === "1"
                ? "justify-content-between"
                : "justify-content-end"
            } d-flex align-items-center cmb-40`}
          >
            {user_type === "1" && (
              <div className="d-flex align-items-center gap-3">
                <p className="text-14-400-18 color-1717 m-0">
                  {`Do you want to Data Masking for all ${titleCaseString(
                    type
                  )?.replace("-", " ")}`}
                  ?
                </p>
                <ToggleSwitch
                  isChecked={data_masked === "1"}
                  onChange={handleToggle}
                />
              </div>
            )}
            {!isHide && updateAccess === "1" && (
              <div className="d-flex">
                <Button
                  text={"Submit"}
                  className={"h-35 cps-15 cpe-15"}
                  btnStyle={"primary-dark"}
                  onClick={handleSubmit}
                  btnLoading={isSubmitLoading}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="d-flex gap-5 align-items-center">
              <Profile
                text={name}
                size="s-125"
                url={profile_image}
                isS3UserURL
              />
              <div className="d-flex flex-column ">
                <div className="text-20-400-25 color-new-car cmb-14">
                  {name}
                </div>
                <div className="d-flex align-items-center gap-4 cmb-20">
                  {email_id && (
                    <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-2">
                      <i className="bi bi-envelope" /> {email_id}
                    </div>
                  )}

                  {phone_number && (
                    <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-2">
                      <i className="bi bi-telephone" />
                      {`${getCountryCode(country_code)} ${phone_number}`}
                    </div>
                  )}
                </div>
                <div className="d-flex gap-5">
                  {date_of_joining && (
                    <div>
                      <h6>Date of Joining</h6>
                      <p>{moment(date_of_joining).format("DD-MMM-YYYY")}</p>
                    </div>
                  )}
                  {member_id && (
                    <div>
                      <h6>Employee ID</h6>
                      <p>{member_id}</p>
                    </div>
                  )}
                  {user_type === "1" && (
                    <div>
                      <h6>Data Masking</h6>
                      <ToggleSwitch
                        isChecked={data_masked === "1"}
                        onChange={handleToggle}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isHide && updateAccess === "1" && (
              <div className="d-flex cmb-30 justify-content-end">
                <Button
                  text={"Submit"}
                  className={"h-35 cps-15 cpe-15"}
                  btnStyle={"primary-dark"}
                  onClick={handleSubmit}
                  btnLoading={isSubmitLoading}
                />
              </div>
            )}
          </>
        ))}

      <div className="w-100 overflow-auto">
        <Table rowData={rowData} header={header} isLoading={isLoading} />
      </div>
    </Card>
  );
};

export default RBACUserListDetail;
