import React, { useEffect, useState } from "react";
import Table from "components/Layout/Table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { objectToFormData, titleCaseString } from "utils/helpers";
import { fetchPaymentsList } from "store/slices/accountsSlice";
import "./AccountManagement.scss";

const getStatusName = (val) => {
  if (val) {
    if (val === "0") {
      return "Pending";
    } else if (val === "1") {
      return "Success";
    } else {
      return "Verifying";
    }
  }
};
const paymentStatus = [
  { value: "Pending", name: "0" },
  { value: "Success", name: "1" },
  { value: "Verifying", name: "2" },
];

const AccountManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    type: "Accountant papers",
  });
  const [searchPayload, setSearchPayload] = useState({
    user_name: "",
    membership_id: "",
    accountant_name: "",
    accountant_id: "",
    confer_name: "",
    date: "",
    paper_id: "",
    paper_title: "",
    ref_no: "",
    payment_status: "",
  });

  const getAccountsList = async (obj) => {
    const response = await dispatch(fetchPaymentsList(objectToFormData(obj)));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.abstractpaperpayments) {
      resList = response?.data?.abstractpaperpayments || [];
      resResultCount = response?.data?.result_count || 0;
      setList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    }
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getAccountsList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = {
      ...filterData,
      ...searchPayload,
    };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getAccountsList(newData);
  };

  useEffect(() => {
    getAccountsList({ ...searchPayload, ...filterData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = window.location.pathname.includes("admin");
  // const isAccount = window.location.pathname.includes("accounts");
  const userType = isAdmin ? "admin" : "accounts";

  const handleRedirect = (id) => {
    navigate(`/${userType}/payment-verification-details/${id}`);
  };

  const {
    // create: createAccess,
    view: viewAccess,
    // update: updateAccess,
    // delete: deleteAccess,
  } = userDetails?.role_data?.find(
    (item) => item?.permission_name === "Payment Verification"
  )?.sub_permission || {};

  const header = [
    {
      isSearch: true,
      searchInputName: "user_name",
      title: <span className="text-nowrap">Name</span>,
    },
    {
      isSearch: true,
      searchInputName: "membership_id",
      title: <span className="text-nowrap">Member ID</span>,
    },
    {
      isSearch: true,
      searchInputName: "accountant_name",
      title: <span className="text-nowrap">A/c Name</span>,
    },
    {
      isSearch: true,
      searchInputName: "accountant_id",
      title: <span className="text-nowrap">A/c ID</span>,
    },
    {
      isSearch: true,
      searchInputName: "confer_name",
      title: <span className="text-nowrap">Conference Name</span>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "date",
      title: <span className="text-nowrap">Received Date</span>,
    },
    {
      isSearch: true,
      searchInputName: "paper_id",
      title: <span className="text-nowrap">Paper Id</span>,
    },
    {
      isSearch: true,
      searchInputName: "paper_title",
      title: <span className="text-nowrap">Paper Title</span>,
    },
    {
      isSearch: true,
      searchInputName: "ref_no",
      title: <span className="text-nowrap">Reference No.</span>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      isMyTaskFilter: true,
      myTaskDropdownOptions: {
        options: paymentStatus,
        key: "name",
        value: "value",
      },
      handleStatus: (e) => {
        setSearchPayload((prev) => {
          return { ...prev, payment_status: e.target.value };
        });
      },
      searchInputName: "payment_status",
      title: <span className="text-nowrap">Payment Status</span>,
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Action",
    },
  ];

  const rowData = [];
  list?.forEach((elem) => {
    const {
      id,
      user_name,
      membership_id,
      accountant_name,
      accountant_id,
      confer_name,
      date,
      paper_id,
      paper_title,
      ref_no,
      payment_status,
    } = elem || {};
    let obj = [
      {
        value: (
          <span className="text-nowrap pointer color-new-car">
            {titleCaseString(user_name)}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{membership_id}</span>,
      },
      {
        value: (
          <span
            className="text-nowrap pointer color-new-car"
            onClick={() => {
              handleRedirect(id);
            }}
          >
            {accountant_name}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{accountant_id}</span>,
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(confer_name)}</span>
        ),
      },
      {
        value: <span className="text-nowrap">{date}</span>,
      },
      {
        value: <span className="text-nowrap color-new-car">{paper_id}</span>,
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(paper_title)}</span>
        ),
      },
      {
        value: <span className="text-nowrap color-new-car">{ref_no}</span>,
      },
      {
        value: (
          <span
            className={`${
              payment_status === "0"
                ? "color-5110"
                : payment_status === "1"
                ? "color-a35b"
                : payment_status === "2"
                ? "color-6866"
                : ""
            } text-nowrap`}
          >
            {getStatusName(payment_status)}
          </span>
        ),
      },
      {
        value: (
          <span className="action-icon-buttons">
            {viewAccess === "1" && (
              <div
                className="d-flex align-items-center color-new-car text-15-500 pointer"
                onClick={() => {
                  handleRedirect(id);
                }}
              >
                View
              </div>
            )}
            {/* <div className="d-flex gap-2">
              <i
                className="bi bi-eye pointer"
                onClick={() => {
                  handleRedirect(id);
                }}
              />
              <i
                className="bi bi-pencil-square pointer"
                onClick={() => {
                  navigate(
                    `/${userType}/edit-payment-verification-details/${id}`
                  );
                }}
              />
            </div> */}
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  return (
    <div className="cps-20 cpe-20 cpb-20 unset-br">
      {/* {isAdmin && (
        <div className="d-flex justify-content-end align-items-center cpt-28 cpb-28 flex-wrap gap-2">
          <Button
            className="h-34 primary-outline1 text-14-500"
            onClick={() => navigate("/admin/add-account")}
            icon={<img src={icons.plus} alt="" className="pe-1 h-16" />}
            text="Add Accounts"
            btnStyle=""
          />
        </div>
      )} */}
      <div className="overflow-auto">
        <Table
          header={header}
          rowData={rowData}
          isOtherPagination
          isLoading={isLoading}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
        />
      </div>
    </div>
  );
};

export default AccountManagement;
