import React from 'react';
// Keynote speaker, session speaker notification
const FifthStepTemplate = ({ values }) => {
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    featured_speakers,
    register_webinar,
    // pre_conference_speaker_name,
    // date_time,
    // view_speaker_profile,
    // register_conference,
    // your_name,
    // your_title,
    // your_contact_information,
    // your_website,
  } = values;
  return (
    <div>
      <p>{client_name ? `Hi ${client_name},` : ` Hi [Recipient's Name],`}</p>

      <p>
        We’re excited to introduce you to our distinguished speakers who will be
        presenting at the upcoming{' '}
        <strong>
          {conference_name ? conference_name : '[Conference Name]'}{' '}
        </strong>
        , scheduled for{' '}
        <strong>
          {conference_date ? conference_date : '[Conference Dates]'}
        </strong>{' '}
        at{' '}
        <strong>
          {conference_location ? conference_location : '[Conference Venue]'}
        </strong>
        .
      </p>

      <p>
        This <strong>CPD-accredited conference</strong> brings together global
        experts to share their insights, and these speakers are sure to enhance
        your experience:
      </p>

      {featured_speakers?.length > 0 ? (
        featured_speakers?.map((elem, index) => {
          const {
            speaker_name: f_speaker_name,
            speaker_credentials,
            speaker_achievements,
            speaker_sessionTopic,
          } = elem;
          return (
            <React.Fragment key={index}>
              <div>
                {index % 2 === 0 ? '👨‍🏫' : '👩‍🏫'}
                <strong>
                  Dr. {f_speaker_name ? f_speaker_name : "[Speaker's Name]"}
                </strong>
              </div>
              <ul>
                <li>
                  <strong>Credentials:</strong>
                  {speaker_credentials
                    ? ` ${speaker_credentials}`
                    : ' [Academic degrees, professional titles]'}
                </li>
                <li>
                  <strong>Achievements:</strong>
                  {speaker_achievements
                    ? ` ${speaker_achievements}`
                    : ' [Awards, significant research contributions, industry impact]'}
                </li>
                <li>
                  <strong>Topic:</strong>
                  {speaker_sessionTopic
                    ? ` ${speaker_sessionTopic}`
                    : ' [Brief description of their presentation topic]'}
                </li>
              </ul>

              <br />
            </React.Fragment>
          );
        })
      ) : (
        <>
          <div>
            👨‍🏫
            <strong>Dr. [Speaker's Name]</strong>
          </div>
          <ul>
            <li>
              <strong>Credentials:</strong>
              [Academic degrees, professional titles]
            </li>
            <li>
              <strong>Achievements:</strong>
              [Awards, significant research contributions, industry impact]
            </li>
            <li>
              <strong>Topic:</strong>
              [Brief description of their presentation topic]
            </li>
          </ul>

          <div>
            👩‍🏫
            <strong>Dr. [Speaker's Name]</strong>
          </div>
          <ul>
            <li>
              <strong>Credentials:</strong>
              [Academic degrees, professional titles]
            </li>
            <li>
              <strong>Achievements:</strong>
              [Awards, significant research contributions, industry impact]
            </li>
            <li>
              <strong>Topic:</strong>
              [Brief description of their presentation topic]
            </li>
          </ul>
        </>
      )}

      <p>This is a fantastic opportunity to:</p>
      <p>
        ✅ Learn from global leaders in your field <br />
        ✅ Network with top professionals and researchers <br />✅ Earn a{' '}
        <strong>CPD certification</strong> to enhance your credentials
      </p>

      <p>
        {register_webinar ? (
          <>
            👉 Register Now:{' '}
            <a
              href={register_webinar}
              target="_blank"
              // rel="noopener noreferrer"
            >
              Click Here
            </a>
          </>
        ) : (
          '👉 Register Now: [Registration Link]'
        )}
      </p>

      <p>
        Don’t miss out on this chance to gain valuable knowledge and make
        meaningful connections. Looking forward to seeing you there! 😊
      </p>
    </div>
  );
};

export default FifthStepTemplate;
