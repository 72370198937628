import React, { useEffect, useState } from "react";
import AddSegmentPopup from "../AddSegmentPopup";
import Card from "components/Layout/Card";
import Button from "components/form/Button";
import Loader from "components/Layout/Loader";
import { getUserType, objectToFormData } from "utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchSegmentDetails } from "store/slices";
import moment from "moment";
import "./SegmentUsers.scss";

const SegmentUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const { eID } = params;
  const dispatch = useDispatch();
  const memberType = getUserType();
  const [isAddPopup, setIsAddPopup] = useState(false);
  const [popupType, setPopupType] = useState("Edit");
  const [isLoading, setIsLoading] = useState(true);
  const [segmentUserlist, setSegmentUserList] = useState({});

  const getSegmentDetails = async () => {
    const response = await dispatch(
      fetchSegmentDetails(objectToFormData({ event_id: eID }))
    );
    setSegmentUserList(response?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    getSegmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    create: createAccess,
    update: updateAccess,
  } = userDetails?.role_data?.find(
    (item) => item?.permission_name === "Segment"
  )?.sub_permission || {};

  return (
    <>
      {isAddPopup && (
        <AddSegmentPopup
          type={popupType}
          onHide={() => {
            setIsAddPopup(false);
          }}
          handleSuccess={() => {
            getSegmentDetails();
          }}
          data={popupType === "Edit" ? isAddPopup : ""}
        />
      )}

      <div className="segment-users-container">
        <Card className="cpt-34 cpe-20 cps-20 cpb-34">
          <div className="d-flex align-items-center justify-content-between cmb-30 cpb-23 border-bottom">
            <div className="table-title">
              Segment Users ({segmentUserlist?.segments_count || 0})
            </div>
            <div className="d-flex align-items-center flex-wrap gap-3">
              {/* <div style={{ width: "220px" }}>
                <SeachInput
                  placeholder="Search"
                  value={""}
                  onChange={() => {}}
                  onKeyDown={() => {}}
                  onClick={() => {}}
                  isSearchPointer
                />
              </div> */}

              {createAccess === "1" && (
                <Button
                  onClick={() => {
                    setPopupType("Add");
                    setIsAddPopup(true);
                  }}
                  text="+ Add Segment"
                  btnStyle="primary-outline"
                  className="h-35 text-14-500"
                  isSquare
                />
              )}
            </div>
          </div>
          {isLoading ? (
            <div className="center-flex cpt-50 cpb-50">
              <Loader size="md" />
            </div>
          ) : segmentUserlist?.segments?.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center cpt-200 cpb-200">
              No data found
            </div>
          ) : (
            <div className="row">
              <div className="cmb-16 text-16-400-27 color-black-olive">
                {segmentUserlist?.event_name}
              </div>
              {segmentUserlist?.segments?.map((elem, index) => {
                return (
                  <div className="col-md-4 cmb-14" key={index}>
                    <div
                      className="cps-14 cpt-14 cpe-14 cpb-17 segment-user-border position-relative"
                      onClick={() => {
                        navigate(
                          `/${memberType}/segment-users/details/${elem?.segment_id}`
                        );
                      }}
                    >
                      {updateAccess === "1" && (
                        <div
                          className="position-absolute right-25 pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPopupType("Edit");
                            setIsAddPopup(elem);
                          }}
                        >
                          <img src={icons.Edit} alt="editPen" />
                        </div>
                      )}
                      <div className="d-flex align-items-center gap-2 cmb-12">
                        <div className="text-14-500-28 color-48c0 width-25">
                          Segment
                        </div>
                        <div className="text-14-500-24 color-black-olive width-75">
                          : {elem?.segment_name}
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 cmb-12">
                        <div className="text-14-500-28 color-48c0 width-25">
                          Condition
                        </div>
                        <div className="text-14-500-24 color-black-olive width-75">
                          : {elem?.category_condition}
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 cmb-12">
                        <div className="text-14-500-28 color-48c0 width-25">
                          Users
                        </div>
                        <div className="text-14-500-24 color-black-olive width-75">
                          : {elem?.segment_user}
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div className="text-14-500-28 color-48c0 width-25">
                          Created
                        </div>
                        <div className="text-14-500-24 color-black-olive width-75">
                          :{" "}
                          {elem?.created_at
                            ? moment(elem?.created_at).format("DD-MMM-YYYY")
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default SegmentUsers;
