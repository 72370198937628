import Card from "components/Layout/Card";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage, getUserType } from "utils/helpers";
import "./RBAC.scss";

const userRole = [
  {
    id: "0",
    title: "Admin",
    type: "admin",
    userRole: "admin",
  },
  {
    id: "6.2",
    title: "Conference Co-Ordiator",
    type: "resource",
    resourceRole: "2",
    userRole: "conference-co-ordiator",
  },
  {
    id: "6.1",
    title: "Membership Co-Ordinator",
    type: "resource",
    resourceRole: "1",
    userRole: "membership-co-ordinator",
  },
  {
    id: "7",
    title: "Accounts",
    type: "accounts",
    userRole: "accounts",
  },
];

const RBAC = () => {
  const navigate = useNavigate();
  const { user_type } = getDataFromLocalStorage();
  const memberType = getUserType();

  const filterOptions = userRole?.filter((o) => o?.id !== user_type);

  return (
    <Card className={"cmb-23 cps-20 cpe-20 cpb-36 cpt-20 rbac-container"}>
      <div className="text-16-500-20 color-title-navy cmb-26">
        RBAC - Role Base Access Control
      </div>

      <div className="row">
        {filterOptions?.map((o, index) => {
          return (
            <div className="col-md-4 gy-3" key={index}>
              <div
                className="text-16-600-20 role-base-users"
                onClick={() => {
                  navigate(`/${memberType}/settings/rbac/${o?.userRole}/${o?.id}`);
                }}
              >
                {o?.title}
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default RBAC;
