import Modal from "components/Layout/Modal";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  GenreteResume,
  setIsProfileAIPopupOpen,
  setResumeList,
} from "store/slices";
import "./ProfileCV.scss";
import CVPreview from "./CVPreview";
import CVForm from "./CVForm";
import { getDataFromLocalStorage, objectToFormData } from "utils/helpers";

const ProfileCVGeneraterModal = () => {
  const dispatch = useDispatch();
  const userId = getDataFromLocalStorage("id");
  const [btnGeLoading, setBtnGeLoading] = useState(false);
  const reduxData = useSelector((state) => state.global);

  const { isProfileAIPopupOpen } = reduxData || {};
  const { userDetails, researchProfile, resumeList } = useSelector((state) => ({
    userDetails: state.student.userDetails,
    rProfileData: state.global.rProfileData,
    researchProfile: state.student.researchProfile,
    resumeList: state.global.resumeList,
  }));

  const handelGenreteResume = async () => {
    setBtnGeLoading(true);
    const payload = {
      id: userId,
    };
    const res = await dispatch(GenreteResume(objectToFormData(payload)));
    if (res?.status === 400) {
      setBtnGeLoading(false);
    }
    setBtnGeLoading(false);
  };

  // useEffect(() => {
  //   handelGenreteResume();
  // }, []);

  return (
    <div>
      {isProfileAIPopupOpen && (
        <Modal
          onHide={() => {
            dispatch(setIsProfileAIPopupOpen(false));
          }}
          fullscreen
          isNoPadding
        >
          <Row className="ms-0 me-0 ps-0 pe-0">
            <Col className="ps-0 pe-0">
              <CVForm
                handelCv={handelGenreteResume}
                loadingCv={btnGeLoading}
                list={resumeList}
              />
            </Col>
            <Col className="ps-0 pe-0">
              <CVPreview
                researchProfile={researchProfile}
                userDetails={userDetails}
                list={resumeList}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default ProfileCVGeneraterModal;
