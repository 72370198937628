import SeachInput from "components/form/SeachInput";
import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import Table from "components/Layout/Table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { exportRBACUserList, fetchRBACUserList } from "store/slices";
import { getUserType, objectToFormData, titleCaseString } from "utils/helpers";

const RBACUserList = () => {
  const params = useParams();
  const { type, userType, listType } = params;
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const memberType = getUserType();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    global_filter: "",
    user_type: userType,
  });
  const [searchPayload, setSearchPayload] = useState({
    joining_at: "",
    name: "",
    emp_id: "",
  });
  const [userList, setUserList] = useState([]);

  const getRBACUserList = async (obj) => {
    const response = await dispatch(fetchRBACUserList(obj));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.user_data) {
      resList = response?.data?.user_data || [];
      resResultCount = response?.data?.result_count || 0;
    }
    setUserList(resList);
    setFilterData({ ...obj, total: resResultCount });
    setIsLoading(false);
  };

  const handleSearch = (e) => {
    let value = e?.target?.value;
    setSearchText(value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let newData = { ...filterData, ...searchPayload };
      newData = { ...newData, global_filter: value, offset: 0 };
      setIsLoading(true);
      setFilterData(newData);
      getRBACUserList(newData);
    }, 800);
    setTimer(time);
  };

  const handlePagination = async (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getRBACUserList(newData);
  };

  const handleSearchChanges = async (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getRBACUserList(newData);
  };

  useEffect(() => {
    getRBACUserList({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
console.log('✌️userDetails --->', userDetails);
  const {
    view: viewAccess,
    // create: createAccess,
    // update: updateAccess,
    // delete: deleteAccess,
  } =
    userDetails?.role_data?.find((item) => item?.permission_name === "Settings")
      ?.sub_permission || {};

  const header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "name",
      title: "Name",
    },
    {
      isSearch: true,
      searchInputName: "emp_id",
      title: <div className="text-nowrap">Member ID</div>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "joining_at",
      title: <div className="text-nowrap">Date of Joining</div>,
    },
    {
      isSearch: false,
      searchInputName: "name",
      title: "Action",
    },
  ];
  const rowData = [];
  userList?.forEach((elem, index) => {
    const { id, name, member_id, created_at } = elem;
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span
            className={`color-new-car text-nowrap text-16-500-20`}
            // onClick={() => {
            //   navigate(
            //     `/${memberType}/settings/rbac/${type}/${userType}/${listType}/detail/${id}`
            //   );
            // }}
          >
            {`${name}`}
          </span>
        ),
      },
      {
        value: <span>{member_id}</span>,
      },
      {
        value: (
          <span>
            {created_at ? moment(created_at).format("DD-MMM-YYYY") : "-"}
          </span>
        ),
      },
      {
        value: (
          <span className="action-icon-buttons">
            {viewAccess === "1" && (
              <span
                className="pointer"
                onClick={() => {
                  navigate(
                    `/${memberType}/settings/rbac/${type}/${userType}/${listType}/detail/${id}`
                  );
                }}
              >
                <i className="bi bi-eye color-8a8a text-20" />
              </span>
            )}
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  const userLabel = `${titleCaseString(listType)} ${titleCaseString(
    type
  )?.replace("-", " ")}`;

  return (
    <Card className="rbac-user-type-container cps-24 cpt-16 cpe-24 cpb-16 br-10">
      <div className="d-flex align-items-center gap-3 cmb-6">
        <i
          className="bi bi-chevron-left pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="text-16-500-20 color-title-navy">{userLabel}</span>
      </div>

      <div className="d-flex align-items-center justify-content-end gap-3 cmb-18">
        <div style={{ width: "220px" }}>
          <SeachInput
            placeholder="Name Or Member ID"
            value={searchText}
            onChange={handleSearch}
          />
        </div>
        {viewAccess === "1" && (
          <ExportButton
            exportAPI={exportRBACUserList}
            payload={objectToFormData({
              ...filterData,
              ...searchPayload,
              export_status: 1,
            })}
          />
        )}
      </div>

      <div className="w-100 overflow-auto">
        <Table
          rowData={rowData}
          header={header}
          isLoading={isLoading}
          isOtherPagination
          filterData={filterData}
          changeOffset={handlePagination}
          searchInputChange={handleSearchChanges}
        />
      </div>
    </Card>
  );
};

export default RBACUserList;
